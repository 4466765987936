/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the sae landing page component
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/**
 * Required to make use of JSX functionality
 */
import { Field, FieldGroup, Select, Text, TextArea, CrfCondition, ICrfConditionParameters, KeyboardDatePicker, ToggleButtonGroup, FormOptionsContext, PatientsContext, InstitutionContext, InstitutionsContext, Checkbox, useFormState, IValidationError, Markdown, Password, RouteLoading } from '@ngt/opms';
import { ScreeningLogContext } from '@ngt/opms-screeninglog'
import * as React from 'react';
import MuiToggleButton from '@material-ui/lab/ToggleButton';
import { faTimesCircle } from '@fortawesome/pro-duotone-svg-icons/faTimesCircle';
/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */
import * as Dtos from '../../api/dtos';
import { MenuItem, makeStyles, Theme, Typography, fade } from '@material-ui/core';
import { LookupScreeningLogReasonType, LookupGenderType } from '../../api/dtos';
import { getReasonValue, getGenderValue } from '../../hooks/screeninglogs/useScreenFailsColumns';
import onlinePatientManagement from '../../onlinePatientManagement';
import { Alert, AlertTitle } from '@material-ui/lab';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Permission, usePermissionsByIds } from '@ngt/opms-bctapi';
import { RequestState } from '@ngt/request-utilities';


/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

interface IMenuItem {
    id?: number;
    value?: string;
    label?: string;
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles<Theme>(theme => ({
    disabled: {
        background: '#f2f2f2'
    },
    subHeading: {
        padding: theme.spacing(3, 3, 1, 3)
    },
    button: {
        width: '100%',
        height: 32,

        [theme.breakpoints.down('md')]: {
            height: '100%'
        }
    },
    buttonGroup: {
        width: '100%',
        height: '100%',
        paddingBottom: theme.spacing(1.5)
    },
    text: {
        paddingBottom: theme.spacing(1.5)
    },
    error: {
        display: 'flex',

        [theme.breakpoints.down('sm')]: {
            display: 'block'
        }
    },
    code: {
        whiteSpace: 'nowrap',
        padding: theme.spacing(1, 1, 1, 0),

        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(0)
        }
    },
    codeText: {
        fontWeight: 'bold'
    },
    message: {
        padding: theme.spacing(1, 0, 1, 1),

        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(0)
        }
    }
}));

const useButtonStyles = makeStyles(theme => ({
    root: {
        color: `${theme.palette.common.black} !important`,

        marginRight: (props: any) => props.orientation !== 'vertical' && props.spacing ? theme.spacing(props.spacing) : '',
        marginBottom: (props: any) => props.orientation === 'vertical' && props.spacing ? theme.spacing(props.spacing) : '',

        borderLeftColor: (props: any) => props.orientation === 'vertical' || props.spacing ? `${fade(theme.palette.common.black, 0.12)} !important` : '',
        borderTopColor: (props: any) => props.orientation !== 'vertical' || props.spacing ? `${fade(theme.palette.common.black, 0.12)} !important` : '',

        borderRadius: (props: any) => props.spacing ? `${theme.shape.borderRadius}px !important` : '',

        '&:last-child': {
            marginRight: 0,
            marginBottom: 0
        }
    },
    selected: {
        backgroundColor: `${theme.palette.primary.main} !important`,
        color: `${theme.palette.common.white} !important`
    },
    disabled: {
        '&$selected': {
            backgroundColor: `${fade(theme.palette.primary.main, 0.3)} !important`,
            color: `${fade(theme.palette.common.black, 0.74)} !important`
        },
        '&$disabled': {
            color: `${fade(theme.palette.common.black, 0.5)} !important`
        }
    },
    label: {
        textTransform: 'none'
    }
}));

const useAlertStyles = makeStyles<Theme>(theme => ({
    message: {
        minWidth: '0%'
    }
}));

/*
* ---------------------------------------------------------------------------------
* Functions
* ---------------------------------------------------------------------------------
*/
const isScreenFailOrStatusFailed = ({ formState }: ICrfConditionParameters<Dtos.ScreeningLog>) => formState?.values.outcome === Dtos.ScreeningOutcome.ScreenFail || (formState?.values.status === Dtos.ScreeningStatus.Failed && formState?.values.patientId != null);
const isScreenFailReasonOther = ({ formState }: ICrfConditionParameters<Dtos.ScreeningLog>) => formState?.values.reason === Dtos.LookupScreeningLogReasonType.Other;
const isScreenFailReasonNotApproached = ({ formState }: ICrfConditionParameters<Dtos.ScreeningLog>) => formState?.values.reason === Dtos.LookupScreeningLogReasonType.ParticipantNotApproached;
const isScreenFailReasonIneligible = ({ formState }: ICrfConditionParameters<Dtos.ScreeningLog>) => formState?.values.reason === Dtos.LookupScreeningLogReasonType.ParticipantIneligible;
const isIneligibleReasonOther = ({ formState }: ICrfConditionParameters<Dtos.ScreeningLog>) => formState?.values.other === true;
const isProceedingToRegistration = ({ formState }: ICrfConditionParameters<Dtos.ScreeningLog>) => formState?.values.outcome === Dtos.ScreeningOutcome.ProceedToRegistration;
const emailRequired = ({ formState }: ICrfConditionParameters<Dtos.ScreeningLog>) => formState?.values.proficientInEnglish === Dtos.LookupYesNoType.Yes || formState?.values.patientUsingEconsent === Dtos.LookupYesNoType.Yes;
const hasPatientEmail = ({ formState }: ICrfConditionParameters<Dtos.ScreeningLog>) => formState?.values.patientHasEmail === Dtos.LookupYesNoType.Yes;
const isPatientUsingEconsent = ({ formState }: ICrfConditionParameters<Dtos.ScreeningLog>) => formState?.values.patientUsingEconsent === Dtos.LookupYesNoType.Yes;
const hasPatientId = ({ formState }: ICrfConditionParameters<Dtos.ScreeningLog>) => !!formState?.values.patientId;

export const SHOW_INELIGIBILITY_ERROR_FUNCTION = (touched: Record<string, boolean>, ineligibilityErrors: IValidationError[]) => {
    return !!ineligibilityErrors && ineligibilityErrors.length > 0 && (
        touched['moreThan10NodesInvolved'] ||
        touched['erNegativeOrHer2Positive'] ||
        touched['metastaticDisease'] ||
        touched['previousMalignancy'] ||
        touched['hrtAtTimeOfSurgery'] ||
        touched['presurgicalChemo'] ||
        touched['begunAdjuvantTreatment'] ||
        touched['moreThan8WeerksAfterCompletion'] ||
        touched['plannedFurtherSurgery'] ||
        touched['other']    
    )
}

/*
 * ---------------------------------------------------------------------------------
 * Components
 * ---------------------------------------------------------------------------------
 */
const permissions: Permission[] = [
    Permission.OpmsAdminister
];

const ScreeningLogFieldGroup = () => {
    const classes = useStyles();

    const buttonClasses = useButtonStyles();

    const { patients } = React.useContext(PatientsContext);

    const { readOnly } = React.useContext(FormOptionsContext);

    const { institutions } = React.useContext(InstitutionsContext);

    const { institution: selectedInstitution } = React.useContext(InstitutionContext);

    const { screeningLog } = React.useContext(ScreeningLogContext);

    const institution = React.useMemo(() => {
        return institutions?.find(i => i.id === (screeningLog?.institutionId ?? selectedInstitution?.id))
    }, [institutions, screeningLog, selectedInstitution])

    const client = onlinePatientManagement?.serviceStackClient;

    const [investigators, setInvestigators] = React.useState<IMenuItem[]>([]);

    const [[canAdministerOpms], permissionLoadState] = usePermissionsByIds(permissions, null, null, institution?.id, null, true, false);

    // get the list of investigators based on the institutioncode
    React.useEffect(() => {
        client
            .get(new Dtos.InvitationGetInvestigators({
                institutionCode: institution?.code
            }))
            .then(response => {
                let items: IMenuItem[] = [];

                Object.keys(response.investigators).forEach((key, index) => {

                    let item: IMenuItem = {
                        id: index,
                        value: key,
                        label: response.investigators[key]
                    }

                    items.push(item);
                });

                setInvestigators(items);
            })
            .catch((e) => {
                
            });
    }, [client, institution, setInvestigators]);

    const { errors, touched } = useFormState();

    const reasonForIneligibilityErrors = React.useMemo(() => {
        if (errors && Object.keys(errors).some(key => "moreThan10NodesInvolved")) {
            return errors['moreThan10NodesInvolved'] as IValidationError[];
        }

        return [];
    }, [errors]);

    const showIneligibilityError = React.useMemo(() => {
        return SHOW_INELIGIBILITY_ERROR_FUNCTION(touched, reasonForIneligibilityErrors)
    }, [touched, reasonForIneligibilityErrors]);

    const alertClasses = useAlertStyles();

    if (permissionLoadState.state === RequestState.None || permissionLoadState.state === RequestState.Pending) {
        return (
            <RouteLoading />
        );
    }

    return (
        <>
            <Field
                name="screeningDate"
                component={KeyboardDatePicker}
                sm={12}
                md={6}
                xl={6}
                lg={6}
                simpleLabel
                maxDateMessage="Please review the date and enter a correct date. It should not be before the RGO study approval date and should not be a future date."
                minDateMessage="Please review the date and enter a correct date. It should not be before the RGO study approval date and should not be a future date."
            />
            <Field
                name="initials"
                component={Text}
                sm={12}
                md={6}
                xl={6}
                lg={6}
                simpleLabel
            />
            <FieldGroup
                paddingTop={0}
                paddingBottom={0}
            >
                <Field
                    name="outcome"
                    component={ToggleButtonGroup}
                    sm={12}
                    md={6}
                    xl={6}
                    lg={6}
                    simpleLabel
                    className={classes.buttonGroup}
                >
                    <MuiToggleButton
                        disabled={readOnly}
                        key={Dtos.ScreeningOutcome.ProceedToRegistration}
                        value={Dtos.ScreeningOutcome.ProceedToRegistration}
                        className={classes.button}
                        classes={buttonClasses}
                    >
                        PROCEED TO CONSENT
                    </MuiToggleButton>
                    <MuiToggleButton
                        disabled={readOnly}
                        key={Dtos.ScreeningOutcome.ScreenFail}
                        value={Dtos.ScreeningOutcome.ScreenFail}
                        className={classes.button}
                        classes={buttonClasses}
                    >
                        SCREEN FAIL
                    </MuiToggleButton>
                </Field>
                <CrfCondition
                    type={Dtos.ScreeningLog}
                    condition={hasPatientId}
                    mode="Show"
                    subscription={{ values: true }}
                >
                    <Field
                        name="patientId"
                        component={Text}
                        sm={12}
                        md={6}
                        xl={6}
                        lg={6}
                        label="Study number"
                        convert={(patientId) => patients?.find(p => p.id === patientId)?.studyNumber}
                        simpleLabel
                        paddingBottom={2.5}
                    />
                </CrfCondition>
                <CrfCondition
                    type={Dtos.ScreeningLog}
                    condition={isScreenFailOrStatusFailed}
                    mode="Show"
                    subscription={{ values: true }}
                >
                    <Field
                        name="reason"
                        component={Select}
                        sm={12}
                        md={6}
                        xl={6}
                        lg={6}
                        simpleLabel
                        paddingBottom={2}
                    >
                        <MenuItem
                            key={LookupScreeningLogReasonType.ParticipantIneligible}
                            value={LookupScreeningLogReasonType.ParticipantIneligible}
                        >
                            {getReasonValue(LookupScreeningLogReasonType.ParticipantIneligible)}
                        </MenuItem>
                        <MenuItem
                            key={LookupScreeningLogReasonType.ParticipantNotApproached}
                            value={LookupScreeningLogReasonType.ParticipantNotApproached}
                        >
                            {getReasonValue(LookupScreeningLogReasonType.ParticipantNotApproached)}
                        </MenuItem>
                        <MenuItem
                            key={LookupScreeningLogReasonType.ParticipantDidNotWantChemotherapy}
                            value={LookupScreeningLogReasonType.ParticipantDidNotWantChemotherapy}
                        >
                            {getReasonValue(LookupScreeningLogReasonType.ParticipantDidNotWantChemotherapy)}
                        </MenuItem>
                        <MenuItem
                            key={LookupScreeningLogReasonType.ParticipantWantedChemotherapy}
                            value={LookupScreeningLogReasonType.ParticipantWantedChemotherapy}
                        >
                            {getReasonValue(LookupScreeningLogReasonType.ParticipantWantedChemotherapy)}
                        </MenuItem>
                        <MenuItem
                            key={LookupScreeningLogReasonType.ParticipantWantedToPayMultiparamTest}
                            value={LookupScreeningLogReasonType.ParticipantWantedToPayMultiparamTest}
                        >
                            {getReasonValue(LookupScreeningLogReasonType.ParticipantWantedToPayMultiparamTest)}
                        </MenuItem>
                        <MenuItem
                            key={LookupScreeningLogReasonType.ParticipantDidNotWantToWait}
                            value={LookupScreeningLogReasonType.ParticipantDidNotWantToWait}
                        >
                            {getReasonValue(LookupScreeningLogReasonType.ParticipantDidNotWantToWait)}
                        </MenuItem>
                        <MenuItem
                            key={LookupScreeningLogReasonType.Other}
                            value={LookupScreeningLogReasonType.Other}
                        >
                            {getReasonValue(LookupScreeningLogReasonType.Other)}
                        </MenuItem>
                    </Field>

                    <CrfCondition
                        type={Dtos.ScreeningLog}
                        condition={isScreenFailReasonOther}
                        mode="Show"
                        subscription={{ values: true }}
                    >
                        <Field
                            name="reasonSpecify"
                            component={TextArea}
                            variant="standard"
                            sm={12}
                            md={6}
                            xl={6}
                            lg={6}
                            simpleLabel
                        />
                    </CrfCondition>
                    <CrfCondition
                        type={Dtos.ScreeningLog}
                        condition={isScreenFailReasonNotApproached}
                        mode="Show"
                        subscription={{ values: true }}
                    >
                        <Field
                            name="reasonNotApproached"
                            component={TextArea}
                            variant="standard"
                            sm={12}
                            md={6}
                            xl={6}
                            lg={6}
                            simpleLabel
                        />
                    </CrfCondition>
                    <CrfCondition
                        type={Dtos.ScreeningLog}
                        condition={isScreenFailReasonIneligible}
                        mode="Show"
                        subscription={{ values: true }}
                    >
                        <Typography style={{ fontSize: '1rem', paddingLeft: '1.5rem', paddingTop: '1.5rem' }}>
                            Reason patient is ineligible (check all that apply)
                        </Typography>
                        {
                            showIneligibilityError && (
                                <Alert
                                    icon={<FontAwesomeIcon icon={faTimesCircle} fixedWidth />}
                                    severity="error"
                                    classes={alertClasses}
                                    style={{ marginTop: '1rem' }}
                                >
                                    <AlertTitle>
                                        Error
                                    </AlertTitle>
                                    {
                                        reasonForIneligibilityErrors.map(e => {
                                            return (
                                                <div
                                                    className={classes.error}
                                                    key={e.code}
                                                >
                                                    <div
                                                        className={classes.code}
                                                    >
                                                        <Typography
                                                            variant="button"
                                                            className={classes.codeText}
                                                        >
                                                            {e.code}
                                                        </Typography>
                                                    </div>
                                                    <div
                                                        className={classes.message}
                                                    >
                                                        <Markdown>
                                                            {e.message}
                                                        </Markdown>
                                                    </div>
                                                </div>
                                            );
                                        })
                                    }
                                </Alert>
                            )
                        }
                        <Field
                            label=""
                            name="moreThan10NodesInvolved"
                            component={Checkbox}
                            paddingBottom={0}
                            sm={12}
                            md={9}
                            xl={9}
                            lg={9}
                            noErrors
                            noErrorFlag
                        />
                        <Field
                            label=""
                            name="erNegativeOrHer2Positive"
                            component={Checkbox}
                            paddingBottom={0}
                            sm={12}
                            md={9}
                            xl={9}
                            lg={9}
                        />
                        <Field
                            label=""
                            name="metastaticDisease"
                            component={Checkbox}
                            paddingBottom={0}
                            sm={12}
                            md={9}
                            xl={9}
                            lg={9}
                        />
                        <Field
                            label=""
                            name="previousMalignancy"
                            component={Checkbox}
                            paddingBottom={0}
                            sm={12}
                            md={9}
                            xl={9}
                            lg={9}
                        />
                        <Field
                            label=""
                            name="hrtAtTimeOfSurgery"
                            component={Checkbox}
                            paddingBottom={0}
                            sm={12}
                            md={9}
                            xl={9}
                            lg={9}
                        />
                        <Field
                            label=""
                            name="presurgicalChemo"
                            component={Checkbox}
                            paddingBottom={0}
                            sm={12}
                            md={9}
                            xl={9}
                            lg={9}
                        />
                        <Field
                            label=""
                            name="begunAdjuvantTreatment"
                            component={Checkbox}
                            paddingBottom={0}
                            sm={12}
                            md={9}
                            xl={9}
                            lg={9}
                        />
                        <Field
                            label=""
                            name="moreThan8WeerksAfterCompletion"
                            component={Checkbox}
                            paddingBottom={0}
                            sm={12}
                            md={9}
                            xl={9}
                            lg={9}
                        />
                        <Field
                            label=""
                            name="plannedFurtherSurgery"
                            component={Checkbox}
                            paddingBottom={0}
                            sm={12}
                            md={9}
                            xl={9}
                            lg={9}
                        />
                        <Field
                            label=""
                            name="other"
                            component={Checkbox}
                            paddingBottom={0}
                            sm={12}
                            md={9}
                            xl={9}
                            lg={9}
                        />
                        <CrfCondition
                            type={Dtos.ScreeningLog}
                            condition={isIneligibleReasonOther}
                            mode="Enable"
                            subscription={{ values: true }}
                        >
                            <Field
                                label=""
                                name="otherSpecify"
                                component={TextArea}
                                variant="standard"
                                sm={12}
                                md={9}
                                xl={9}
                                lg={9}
                                simpleLabel
                            />
                        </CrfCondition>
                    </CrfCondition>
                </CrfCondition>
            </FieldGroup>
            <CrfCondition
                type={Dtos.ScreeningLog}
                condition={isProceedingToRegistration}
                mode="Show"
                subscription={{ values: true }}
            >
                <Field
                    name="proficientInEnglish"
                    component={ToggleButtonGroup}
                    sm={12}
                    md={6}
                    xl={6}
                    lg={6}
                    simpleLabel
                >
                    <MuiToggleButton
                        disabled={readOnly}
                        key={Dtos.LookupYesNoType.Yes}
                        value={Dtos.LookupYesNoType.Yes}
                        className={classes.button}
                        classes={buttonClasses}
                    >
                        Yes
                    </MuiToggleButton>
                    <MuiToggleButton
                        disabled={readOnly}
                        key={Dtos.LookupYesNoType.No}
                        value={Dtos.LookupYesNoType.No}
                        className={classes.button}
                        classes={buttonClasses}
                    >
                        No
                    </MuiToggleButton>
                </Field>
            </CrfCondition>
            {
                institution?.eConsentActivationDate && <CrfCondition
                    type={Dtos.ScreeningLog}
                    condition={isProceedingToRegistration}
                    mode="Show"
                    subscription={{ values: true }}
                >
                    <Field
                        name="patientUsingEconsent"
                        component={ToggleButtonGroup}
                        sm={12}
                        md={6}
                        xl={6}
                        lg={6}
                        simpleLabel
                    >
                        <MuiToggleButton
                            disabled={readOnly}
                            key={Dtos.LookupYesNoType.Yes}
                            value={Dtos.LookupYesNoType.Yes}
                            className={classes.button}
                            classes={buttonClasses}
                        >
                            Yes
                        </MuiToggleButton>
                        <MuiToggleButton
                            disabled={readOnly}
                            key={Dtos.LookupYesNoType.No}
                            value={Dtos.LookupYesNoType.No}
                            className={classes.button}
                            classes={buttonClasses}
                        >
                            No
                        </MuiToggleButton>
                    </Field>
                    <CrfCondition
                        type={Dtos.ScreeningLog}
                        condition={isPatientUsingEconsent}
                        mode="Show"
                        subscription={{ values: true }}
                    >
                        <Field
                            name="econsentInvestigator"
                            component={Select}
                            sm={12}
                            md={6}
                            xl={6}
                            lg={6}
                            simpleLabel
                            paddingBottom={3}
                        >
                            {
                                investigators?.map(i => {
                                    return <MenuItem
                                        key={i.value}
                                        value={i.value}
                                    >
                                        {i.label}
                                    </MenuItem>
                                })
                            }
                        </Field>
                    </CrfCondition>
                </CrfCondition>
            }

            <CrfCondition
                type={Dtos.ScreeningLog}
                condition={emailRequired}
                mode="Show"
                subscription={{ values: true }}
            >
                <Field
                    name="patientHasEmail"
                    component={ToggleButtonGroup}
                    sm={12}
                    md={6}
                    xl={6}
                    lg={6}
                    simpleLabel
                >
                    <MuiToggleButton
                        disabled={readOnly}
                        key={Dtos.LookupYesNoType.Yes}
                        value={Dtos.LookupYesNoType.Yes}
                        className={classes.button}
                        classes={buttonClasses}
                    >
                        Yes
                    </MuiToggleButton>
                    <MuiToggleButton
                        disabled={readOnly}
                        key={Dtos.LookupYesNoType.No}
                        value={Dtos.LookupYesNoType.No}
                        className={classes.button}
                        classes={buttonClasses}
                    >
                        No
                    </MuiToggleButton>
                </Field>
            </CrfCondition>
            
            <CrfCondition
                type={Dtos.ScreeningLog}
                condition={hasPatientEmail}
                mode="Show"
                subscription={{ values: true }}
            >
                <Field
                    name="patientEmail"
                    component={(!canAdministerOpms || (screeningLog as Dtos.ScreeningLog)?.patientEmail === null) ? Text : Password}
                    sm={12}
                    md={6}
                    xl={6}
                    lg={6}
                    simpleLabel
                    paddingBottom={3}
                />
            </CrfCondition>
        </>
    );
}


/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */
export default ScreeningLogFieldGroup;