/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * This file contains the component that provides context for the online patient
 * management system.
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */

/**
 * Required to use React components.
 */
import * as React from 'react';


/**
 * Used for the basic page layout.
 */
import {
    CollaboratingGroupContext, CrfCondition, CrfForm,
    Field, FieldGroup, FormBreadcrumbs, GetFieldLookupItem,
    ICrfConditionParameters, InstitutionContext, KeyboardDatePicker, MasterGroupContext, PatientContext,
    RouteLoading, ToggleButtonGroup, useFormActions,
    useAuthenticatedUser,
} from '@ngt/opms';

import { Permission, usePermissionsByIds } from '@ngt/opms-bctapi';

import { RequestState } from '@ngt/request-utilities';


/*
 * ----------------------------------------------------------------------------------
 * Imports - Internal
 * ----------------------------------------------------------------------------------
 */

/*
 * Used to type patient state.
 */
import * as Dtos from '../../api/dtos';
import SourceDocuments from '../../components/SourceDocuments';
import { Button, makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom';


/*
 * ----------------------------------------------------------------------------------
 * Interface
 * ----------------------------------------------------------------------------------
 */

interface IConsentProps {
}

/*
 * ---------------------------------------------------------------------------------
 * Functions
 * ---------------------------------------------------------------------------------
 */

const patientSignedConsentForm = ({ formState }: ICrfConditionParameters<Dtos.Consent>) => formState?.values?.participantSignedAndDatedConsentForm === Dtos.LookupYesNoType.Yes;

const consentedServiceAustraliaConsentLevel = ({ lookups, formState }: ICrfConditionParameters<Dtos.Consent>) => GetFieldLookupItem(lookups, 'serviceAustraliaConsentLevel', formState?.values.serviceAustraliaConsentLevel)?.type === Dtos.LookupConsentType.Consented;

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles(theme => ({
    buttonGroup: {
        padding: theme.spacing(3),
        textAlign: 'center',

        '& > *': {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1)
        },

        [theme.breakpoints.up('sm')]: {
            textAlign: 'right',
            '& > *': {
                marginLeft: theme.spacing(1),
                marginRight: theme.spacing(0)
            }
        }
    }
}));

/*
 * ----------------------------------------------------------------------------------
 * Components
 * ----------------------------------------------------------------------------------
 */

const permissions: Permission[] = [
    Permission.OpmsCrfUpdate
];

const ConsentFields: React.FunctionComponent = () => {
    const { getFieldValue } = useFormActions();

    const showServiceAustralia = React.useMemo(() => {
        return getFieldValue('servicesAustraliaRequired') === true;
    }, [getFieldValue]);

    return <>
        <Field
            name="participantSignedAndDatedConsentForm"
            component={ToggleButtonGroup}
        />
        <CrfCondition
            type={Dtos.Consent}
            mode="Enable"
            condition={patientSignedConsentForm}
            subscription={{ values: true }}
        >
            <Field
                name="dateOfBirth"
                component={KeyboardDatePicker}
            />
            <Field
                name="dateParticipantSignedConsentForm"
                component={KeyboardDatePicker}
            />
            <Field
                name="optionalTissueBankingConsentLevel"
                component={ToggleButtonGroup}
            />
        </CrfCondition>

        {
            showServiceAustralia && <FieldGroup>
                <CrfCondition
                    type={Dtos.Consent}
                    mode="Enable"
                    condition={patientSignedConsentForm}
                    subscription={{ values: true }}
                >
                    <Field
                        name="serviceAustraliaConsentLevel"
                        component={ToggleButtonGroup}
                    />
                </CrfCondition>

                <CrfCondition
                    type={Dtos.Consent}
                    mode="Enable"
                    condition={consentedServiceAustraliaConsentLevel}
                    subscription={{ values: true }}
                >
                    <Field
                        name="participantAgreesToSA"
                        component={ToggleButtonGroup}
                    />
                </CrfCondition>
            </FieldGroup>
        }

        <SourceDocuments
            instructions="If the participant has completed hard copy consent forms, upload all of the applicable consent forms as PDF documents and upload in this section."
        />
    </>
}

const Consent: React.FunctionComponent<IConsentProps> = () => {
    const classes = useStyles();
    const history = useHistory();
    const [user] = useAuthenticatedUser();

    const { masterGroup } = React.useContext(MasterGroupContext);
    const { collaboratingGroup } = React.useContext(CollaboratingGroupContext);
    const { institution } = React.useContext(InstitutionContext);
    const { patient, actions } = React.useContext(PatientContext);

    const [[canUpdateCrf], permissionLoadState] = usePermissionsByIds(permissions, masterGroup?.id, collaboratingGroup?.id, institution?.id, patient?.id, true, false);

    const formEnabled = React.useMemo(() => {
        return canUpdateCrf && (patient as Dtos.Patient)?.consentForm?.consentStatus !== Dtos.ConsentStatusType.Complete
    }, [canUpdateCrf, patient]);

    const onFormCancel = React.useCallback(() => {
        if (user?.type === Dtos.UserType.Patient) {
            history.push(`/`);
        }
        else if (patient) {
            history.push(`/registration/${institution?.code}/${patient?.studyNumber}`)
        }
        else if (institution?.code) {
            history.push(`/registration/${institution?.code}`)
        }
        else {
            history.push(`/registration`);
        }

    }, [history, institution, patient, user]);

    const consentComplete = React.useMemo(() => {
        return (patient as Dtos.Patient)?.consentForm?.consentStatus === Dtos.ConsentStatusType.Complete;
    }, [patient]);
    
    if (permissionLoadState.state === RequestState.None || permissionLoadState.state === RequestState.Pending) {
        return (
            <RouteLoading />
        );
    }

    return (
        <>
            <FormBreadcrumbs />
            <CrfForm
                formType={Dtos.Consent}
                validateOn="onChange"
                canEdit={formEnabled}
                afterFormSave={actions.load}
                hideButtons={consentComplete}
            >
                <ConsentFields />
                {
                    consentComplete && <div
                        className={classes.buttonGroup}
                    >
                        <Button
                            variant="contained"
                            color="primary"
                            href={(window as any).UK_RANDOMISATION_SYSTEM_LINK}
                        >
                            Go to the UK App for randomisation
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={onFormCancel}
                        >
                            Back
                        </Button>
                    </div>
                }
            </CrfForm>
        </>
    );
}


/*
 * ----------------------------------------------------------------------------------
 * Default Export
 * ----------------------------------------------------------------------------------
 */

export default Consent;
