/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * This file contains the component that provides context for the online patient
 * management system.
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */

/**
 * Required to use React components.
 */
import * as React from 'react';

/**
 * Used for the basic page layout.
 */
import {
    OnlinePatientManagementLayout,
    IMenuItem,
    OnlinePatientManagementContext,
    InstitutionsByCodesRoute,
    RouteLoading,
    CrfFormByCodesRoutes,
    PatientSummaryByStudyNumberRoute,
    useIsMobile,
    useAuthenticatedUser,
    ICrfFormMapping,
    UserType
} from '@ngt/opms';

import { usePermissionsByIds, Permission } from '@ngt/opms-bctapi'

import { EConsentPage } from '@ngt/opms-econsent'

import { RequestState } from '@ngt/request-utilities';

/**
 * Used for font styling.
 */
import { Typography, Collapse, makeStyles, Theme } from '@material-ui/core';

/**
 * Used to display icons
 */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/**
 * Used for the copyright icon.
 */
import { faCopyright } from '@fortawesome/pro-regular-svg-icons/faCopyright';

/**
 * Used for the patient icon.
 */
import { faUser } from '@fortawesome/pro-duotone-svg-icons/faUser';

/**
 * Used for the screening log icon.
 */
import { faFileAlt as screeningLogIcon } from '@fortawesome/pro-duotone-svg-icons/faFileAlt';

/**
 * Used for the econsent icon.
 */
import { faFileSignature as eConsentIcon } from '@fortawesome/pro-duotone-svg-icons/faFileSignature';

import { Switch, Redirect, Route } from 'react-router-dom';

/*
 * ----------------------------------------------------------------------------------
 * Imports - Internal
 * ----------------------------------------------------------------------------------
 */


import Registration from './containers/Registration';

import PatientSummary from './containers/PatientSummary';

import Procomida from './containers/form/Procomida';

import Eq5d from './containers/pro/Eq5d';

import Distress from './containers/pro/Distress';

import FactB from './containers/pro/FactB';

import Additional from './containers/pro/Additional';

import ScreeningLogPage from './containers/screeninglog/ScreeningLogPage';


import * as Dtos from './api/dtos';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import Consent from './containers/form/Consent';
import TissueSampleSubmission from './containers/form/TissueSampleSubmission';
import TissueSampleTracking from './containers/form/TissueSampleTracking';
import ProsignaAssayResult from './containers/form/ProsignaAssayResult';
import PatientProsForm from './containers/PatientPros';
import { faFileChartLine } from '@fortawesome/pro-duotone-svg-icons';
import Reports from './containers/Reports';
import { DocumentPdfPage } from './containers/PdfViewer';
import ErrorPage, { ErrorTypeEnum } from './containers/error/ErrorPage';

/*
 * ----------------------------------------------------------------------------------
 * Interface
 * ----------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles<Theme>(theme => ({
    banner: {
        borderRadius: 0,
        width: '100%'
    },
    bannerContainer: {
        position: 'fixed',
        top: '0px',
        width: '100%',
        zIndex: 10
    }
}))

/*
 * ----------------------------------------------------------------------------------
 * constants
 * ----------------------------------------------------------------------------------
 */

const formMapping: Record<string, ICrfFormMapping> = {
    //"general": {
    //    components: <General />
    //},
    "eq-5d": {
        components: <Eq5d />
    },
    "factb": {
        components: <FactB />
    },
    "distress": {
        components: <Distress />
    },
     "additional": {
         components: <Additional />
    },
    "procomida": {
        components: <Procomida />
    },
    "consent": {
        components: <Consent />
    },
    "tissue-sample-submission": {
        components: <TissueSampleSubmission />
    },
    "tissue-sample-tracking": {
        components: <TissueSampleTracking />
    },
    "prosigna-assay-result": {
        components: <ProsignaAssayResult />
    },
}

const eConsentSettings = global.ECONSENT_SETTINGS as Dtos.GetEConsentSettingsResponse;

const permissions: Permission[] = [
    Permission.OpmsAccess,
    Permission.EConsentView,
    Permission.EConsentAdminister,
    Permission.EConsentManage,
    Permission.MedicalReviewPerform,
    Permission.ScreeningLogView,
    Permission.ScreeningLogUpdate
];

/*
 * ----------------------------------------------------------------------------------
 * Components
 * ----------------------------------------------------------------------------------
 */

const App: React.FunctionComponent = () => {
    const classes = useStyles();

    const [user] = useAuthenticatedUser();

    const isPatient = user?.type === UserType.Patient;

    const [[canAccessOpms, canViewEConsent, , , , canViewScreeningLog, ], permissionLoadState] = usePermissionsByIds(permissions, null, null, null, null, true);

    const menuItems: IMenuItem[] = React.useMemo(() => {

        if (isPatient) {
            return [];
        }

        let items: IMenuItem[] = [];

        if (canViewScreeningLog) {
            items.push({
                url: '/screening',
                label: 'Screening',
                external: false,
                newTab: false,
                icon: screeningLogIcon
            });
        }

        if (canViewEConsent && eConsentSettings.eConsentActivated) {
            items.push({
                url: '/econsent',
                label: 'Participant Information Portal',
                external: false,
                newTab: false,
                icon: eConsentIcon
            });
        }

        items.push(
            {
                url: '/registration',
                label: 'Randomisation',
                external: false,
                newTab: false,
                icon: faUser
            }
        );

        items.push({
            url: '/reports',
            label: 'Reports',
            external: false,
            newTab: false,
            icon: faFileChartLine as any
        });

        return items;
    }, [isPatient, canViewScreeningLog, canViewEConsent]);

    const onlinePatientManagement = React.useContext(OnlinePatientManagementContext);

    const isMobile = useIsMobile();

    const [bannerOpen, setBannerOpen] = React.useState(false);

    React.useEffect(() => {
        const ua = window.navigator.userAgent;
        const msie = ua.indexOf('MSIE '); //IE 10 or older
        const trident = ua.indexOf('Trident/'); //IE 11

        if (msie > 0 || trident > 0) {
            // IE 11 or older, show banner
            setBannerOpen(true);
        }
    }, [setBannerOpen]);

    const InvalidPermission = (permissionLoadState.state === RequestState.Cancel || permissionLoadState.state === RequestState.Failure || permissionLoadState.state === RequestState.Success) && 
        !canAccessOpms &&
        !canViewScreeningLog && 
        !canViewEConsent;

    if ( permissionLoadState.state === RequestState.None || permissionLoadState.state === RequestState.Pending) {
        return (
            <RouteLoading />
        );
    }

    return (
        <>
            <Collapse in={bannerOpen} className={classes.bannerContainer}>
                <Alert severity="warning" className={classes.banner} square>
                    <AlertTitle>Your browser is not supported</AlertTitle>
                    Some features of this website may not work correctly with Internet Explorer. Please try using a different browser.
                </Alert>
            </Collapse>

            <OnlinePatientManagementLayout
                menuItems={menuItems}
                footer={
                    isMobile ?
                        undefined :
                        <Typography variant="caption">
                            Copyright <FontAwesomeIcon icon={faCopyright} fixedWidth /> {(new Date().getFullYear())} ANZ Breast Cancer Trials Group Ltd | ABN 64 051 369 496
                        </Typography>
                }
            >
                {
                    InvalidPermission ?
                    <ErrorPage errorType={ErrorTypeEnum.e403} /> :
                    <React.Suspense fallback={<RouteLoading />}>
                        {
                            !isPatient ?
                                <Switch>
                                    <CrfFormByCodesRoutes
                                        path={[
                                            `/registration/:${onlinePatientManagement.routeParameters.institutionCode}/add-patient`
                                        ]}
                                        eventDefinitionCode="baseline"
                                        eventRepeat={1}
                                        formDefinitionCode="consent"
                                        formRepeat={1}
                                        createPatient
                                        resolveBeforeLoad
                                        formMapping={formMapping}
                                    />
                                    <CrfFormByCodesRoutes
                                        path={[
                                            `/registration/:${onlinePatientManagement.routeParameters.institutionCode}/:${onlinePatientManagement.routeParameters.patientStudyNumber}/:${onlinePatientManagement.routeParameters.eventDefinitionCode}/:${onlinePatientManagement.routeParameters.eventRepeat}/:${onlinePatientManagement.routeParameters.formDefinitionCode}/:${onlinePatientManagement.routeParameters.formRepeat}`
                                        ]}
                                        resolveBeforeLoad
                                        formMapping={formMapping}
                                    />
                                    {/*<PatientSummaryRoute*/}
                                    {/*    path={[*/}
                                    {/*        `/registration-V2/:${onlinePatientManagement.routeParameters.institutionCode}/:${onlinePatientManagement.routeParameters.patientStudyNumber}`*/}
                                    {/*    ]}*/}
                                    {/*    resolveBeforeLoad*/}
                                    {/*>*/}
                                    {/*    <PatientSummaryV2 />*/}
                                    {/*</PatientSummaryRoute>*/}
                                    <PatientSummaryByStudyNumberRoute
                                        path={[
                                            `/registration/:${onlinePatientManagement.routeParameters.institutionCode}/:${onlinePatientManagement.routeParameters.patientStudyNumber}`
                                        ]}
                                        resolveBeforeLoad
                                    >
                                        <PatientSummary />
                                    </PatientSummaryByStudyNumberRoute>
                                    <InstitutionsByCodesRoute
                                        path={[
                                            `/registration/:${onlinePatientManagement.routeParameters.institutionCode}`,
                                            '/registration'
                                        ]}
                                    >
                                        <Registration />
                                    </InstitutionsByCodesRoute>
                                    <InstitutionsByCodesRoute
                                        path={[
                                            `/screening/:${onlinePatientManagement.routeParameters.institutionCode}`,
                                            '/screening'
                                        ]}
                                    >
                                        <ScreeningLogPage />
                                    </InstitutionsByCodesRoute>
                                    <InstitutionsByCodesRoute
                                        path={[
                                            `/econsent/:${onlinePatientManagement.routeParameters.institutionCode}`,
                                            '/econsent'
                                        ]}
                                    >
                                        <EConsentPage />
                                    </InstitutionsByCodesRoute>
                                    <Route
                                        path={[
                                            '/document/:documentId',
                                            '/document',
                                        ]}
                                    >
                                        <DocumentPdfPage />
                                    </Route>
                                    <InstitutionsByCodesRoute
                                        path={[
                                            '/reports'
                                        ]}
                                    >
                                        <Reports />
                                    </InstitutionsByCodesRoute>
                                    <Redirect path="*" to="/registration" />
                                    <Redirect path="*" to="/registration/admin" />
                                </Switch> :
                                <Switch>
                                    <CrfFormByCodesRoutes
                                        path={[
                                            `/:${onlinePatientManagement.routeParameters.eventDefinitionCode}/:${onlinePatientManagement.routeParameters.eventRepeat}/:${onlinePatientManagement.routeParameters.formDefinitionCode}/:${onlinePatientManagement.routeParameters.formRepeat}`
                                        ]}
                                        patientStudyNumber={user?.userName}
                                        resolveBeforeLoad
                                        formMapping={formMapping}
                                    />
                                    <PatientSummaryByStudyNumberRoute
                                        path={[
                                            `/`,
                                            `/:next`
                                        ]}
                                        exact
                                        resolveBeforeLoad
                                        patientStudyNumber={user?.userName}
                                    >
                                        <PatientProsForm />
                                    </PatientSummaryByStudyNumberRoute>
                                    <Redirect path="*" to="/" />
                                </Switch>
                        }
                    </React.Suspense>
                }
            </OnlinePatientManagementLayout>

        </>
    );
}


/*
 * ----------------------------------------------------------------------------------
 * Default Export
 * ----------------------------------------------------------------------------------
 */

export default App;
