/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * This file contains the invitation page component
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */

/**
 * Required to use React components.
 */
import * as React from 'react';

import {
    makeStyles,
    CssBaseline,
    Container,
    Paper,
} from '@material-ui/core';

/**
 * Used for the ecrf icon.
 */

import Header from './Header';

/*
 * ----------------------------------------------------------------------------------
 * Imports - Internal
 * ----------------------------------------------------------------------------------
 */


/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

interface IEConsentLayoutProps {
    headerText?: string;
    footer?: React.ReactNode;
    children: React.ReactNode
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles(theme => ({
    container: {
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 0,
            paddingRight: 0
        }
    },
    paper: {
        marginBottom: theme.spacing(3),
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,

        [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(0),
        }
    },
    footer: {
        padding: theme.spacing(1, 3),
        textAlign: 'center',
        //borderTopColor: theme.palette.primary.light,
        borderTopColor: "#f3c4d8",
        borderTopWidth: theme.spacing(1),
        borderTopStyle: 'solid'
    }
}))

/*
 * ---------------------------------------------------------------------------------
 * Constants
 * ---------------------------------------------------------------------------------
 */


/*
 * ---------------------------------------------------------------------------------
 * Components
 * ---------------------------------------------------------------------------------
 */

const EConsentLayout: React.FunctionComponent<IEConsentLayoutProps> = ({
    headerText,
    footer,
    children
}) => {
    const classes = useStyles();

    return (
        <>
            <CssBaseline />
            <Header
                title={headerText ?? "Welcome!"}
            />
            <Paper
                elevation={0}
                square
                className={classes.paper}
            >
                <Container
                    className={classes.container}
                >
                    <>{children}</>
                </Container>
                {
                    !!footer && (
                        <div className={classes.footer}>
                            {footer}
                        </div>
                    )
                }
            </Paper>

        </>  
    );
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */
export default EConsentLayout;