/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the hook to use pre-randomised patient columns.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to create a context.
 */
import * as React from 'react';

/*
 * Used to type columns
 */
import { Column } from 'material-table';

/*
 * Used to type patients and institutions 
 */
import {
    IInstitution
} from '@ngt/opms';

import { Chip, Link, Tooltip, Typography } from '@material-ui/core';

/*
 * Used to format datetimes
 */
import { DateTime } from 'luxon';



/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import * as Dtos from '../api/dtos';

/*
 * ---------------------------------------------------------------------------------
 * Functions
 * ---------------------------------------------------------------------------------
 */



const useAwaitingRandomisationPatientColumns = (
    institutions: IInstitution[] | null,
    showSite: boolean,
    onReviewClick: (patient: Dtos.Patient) => void,
    canConsentAdminister: boolean,
    canUpdatePatient: boolean,
    canPathologyTestAdminister: boolean
): Column<Dtos.Patient>[] => {

    const columns = React.useMemo(() => {
        const c: (Column<Dtos.Patient> | boolean)[] = [
            {
                field: 'initials',
                title: 'Initials',
                width: 210
            },
            showSite && {
                field: 'institutionCode',
                title: 'Site Code',
                render: rowData => (
                    <Chip
                        label={rowData.institutionCode}
                        color="secondary"
                        size="small"
                    />
                ),
                width: 225
            },
            showSite && {
                field: 'institutionId',
                title: 'Institution',
                render: rowData => (
                    institutions?.find(i => i.id === rowData.institutionId)?.name
                )
            },
            {
                field: 'enteredDate',
                title: 'Date Commenced Trial',
                render: rowData => (
                    rowData.enteredDate ? DateTime.fromISO(rowData.enteredDate).toFormat('dd/MM/yyyy') : null
                ),
                width: 210
            },
            (canConsentAdminister || (canUpdatePatient && !canPathologyTestAdminister)) && {
                field: 'consentStatus',
                title: 'Consent Status',
                render: rowData => {
                    switch (rowData.consentForm?.consentStatus) {
                        case Dtos.ConsentStatusType.Complete: {
                            if (rowData.consentForm?.reviewedDate != null && canConsentAdminister) {

                                return <Tooltip
                                    style={{ width: '100%' }}
                                    title={<Typography style={{ fontSize: '0.75rem' }}>Click here to view the consent form history</Typography>}
                                    placement="right"
                                >
                                    <Link color="primary" onClick={(e) => { e.stopPropagation(); onReviewClick(rowData); }}>Complete</Link>
                                </Tooltip>;
                                
                            }

                            return 'Complete';
                        } 
                        case Dtos.ConsentStatusType.ReadyForReview: {
                            if (canConsentAdminister) {
                                return <Tooltip
                                    style={{ width: '100%' }}
                                    title={<Typography style={{ fontSize: '0.75rem' }}>Click here to confirm and delete the consent form</Typography>}
                                    placement="right"
                                >
                                    <Link color="secondary" onClick={(e) => { e.stopPropagation(); onReviewClick(rowData); }}>Ready for Review</Link>
                                </Tooltip>;
                            } else {
                                return 'Ready for Review';
                            }
                        }
                        case Dtos.ConsentStatusType.InProgress: return 'In Progress';
                        case Dtos.ConsentStatusType.DobRequired: return 'DOB Required';
                        case Dtos.ConsentStatusType.NewConsentFormRequired: {
                            if (canConsentAdminister) {
                                return <Tooltip
                                    style={{ width: '100%' }}
                                    title={<Typography style={{ fontSize: '0.75rem' }}>Click here to view the consent form history</Typography>}
                                    placement="right"
                                >
                                    <Link color="primary" onClick={(e) => { e.stopPropagation(); onReviewClick(rowData); }}>New Consent Form Required</Link>
                                </Tooltip>;
                            } else {
                                return 'New Consent Required';
                            }
                        }
                        default: return '';
                    }
                },
                width: 210
            }
        ];

        return c.filter(column => !!column) as Column<Dtos.Patient>[];
    }, [showSite, institutions, onReviewClick, canConsentAdminister, canUpdatePatient, canPathologyTestAdminister]);

    return columns;
};

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default useAwaitingRandomisationPatientColumns;