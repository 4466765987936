/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * This file contains the component that provides context for the online patient
 * management system.
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */

/**
 * Required to use React components.
 */
import * as React from 'react';

/*
 * Used to style components
 */
import { makeStyles, Button, Typography, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Tooltip } from '@material-ui/core';

/**
 * Used for the basic page layout.
 */
import {
    PatientSummaryList,
    PatientContext,
    PatientBreadcrumbs,
    PatientInformation,
    ProgressButton,
    InstitutionContext,
    MasterGroupContext,
    CollaboratingGroupContext,
    RouteLoading,
    ValidationResultType,
    useSnackbar,

    IPatientSummaryActionProps,
    OnlinePatientManagementContext,
    IPatient,
    usePatientSummary
} from '@ngt/opms';

import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import { RequestState } from '@ngt/request-utilities';

/*
 * ----------------------------------------------------------------------------------
 * Imports - Internal
 * ----------------------------------------------------------------------------------
 */
import PatientActiveDirectory from './PatientActiveDirectory';

/*
 * Used to type patient state.
 */
import * as Dtos from '../api/dtos';
import { Permission, usePermissionsByIds } from '@ngt/opms-bctapi';
import { JsonServiceClient } from '@servicestack/client';
import * as classNames from 'classnames';
import { patientCaption, patientStateCaption } from '../utils/patientInformation';
import { useForm } from 'react-hook-form';

/*
 * ----------------------------------------------------------------------------------
 * Interface
 * ----------------------------------------------------------------------------------
 */


interface IPatientSummaryProps {
    showAdminPage?: boolean;
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles(theme => ({
    container: {
        padding: theme.spacing(3)
    },
    title: {
        paddingBottom: theme.spacing(3),

        '&:last-child': {
            textAlign: 'right'
        },
        '&:nth-last-child(2)': {
            textAlign: 'right'
        },
        '&:first-child': {
            textAlign: 'left'
        },
    },
    buttonGroup: {
        display: 'flex',
        gap: 5,
        flexWrap: 'wrap',

        [theme.breakpoints.down('xs')]: {
            width: '100%',

            '& > *': {
                width: '100%'
            },
        }

        //padding: theme.spacing(3, 0, 0, 0),
        //textAlign: 'center',

        //'& > *': {
        //    marginLeft: theme.spacing(1),
        //    marginRight: theme.spacing(1)
        //},

        //[theme.breakpoints.up('sm')]: {
        //    textAlign: 'right',
        //    '& > *': {
        //        marginLeft: theme.spacing(1),
        //        marginRight: theme.spacing(0)
        //    }
        //}
    },
    button: {
        height: "fit-content",
        marginLeft: theme.spacing(3),
        width: '100%',

        '&:first-child': {
            marginLeft: theme.spacing(0)
        }
    },
    header: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        flexWrap: 'wrap'
    },
    buttonContainer: {
        display: "flex",
        justifyContent: "flex-end"
    },
    questionnaireInfo: {
        paddingBottom: theme.spacing(1)
    }
}));

const useEventActionStyles = makeStyles(theme => ({
    button: {
        minWidth: 140,
        borderRadius: 0,
        whiteSpace: 'nowrap',
        '&:first-child': {
            marginLeft: theme.spacing(0)
        }
    },
    tooltip: {
        display: 'flex'
    },
    wrapper: {
        display: 'flex'
    }
}));

/*
 * ----------------------------------------------------------------------------------
 * Components
 * ----------------------------------------------------------------------------------
 */

const permissions: Permission[] = [
    Permission.OpmsPatientImpersonate,
    Permission.OpmsPatientAccountManagement,
    Permission.OpmsAdminister
];

enum DialogState {
    None = 0,
    Create = 1,
    CreateAndNotify = 2,
    Resend = 3
}

const useCreateAndNotifyActions = (
    updateTo: Dtos.PatientStateType,
    hideButton: (patient: IPatient | null) => boolean,
    eventTitle: string,
    eventName: string,
    saving: boolean,
    updatePatientState: (state: Dtos.PatientStateType, skipPatientEmail: boolean, patientStatusReason?: string) => void,
    enqueueSnackbar: ReturnType<typeof useSnackbar>['enqueueSnackbar'],
    classes: ReturnType<typeof useEventActionStyles>,
    client: JsonServiceClient,
    patient: IPatient | null,
    patientValid: boolean
) => {
    const [dialogState, setDialogState] = React.useState(DialogState.None);

    const openCreateDialog = React.useCallback(() => {
        setDialogState(DialogState.Create);
    }, [setDialogState]);

    const openCreateAndNotifyDialog = React.useCallback(() => {
        setDialogState(DialogState.CreateAndNotify);
    }, [setDialogState]);

    const openResendDialog = React.useCallback(() => {
        setDialogState(DialogState.Resend);
    }, [setDialogState]);

    const closeDialog = React.useCallback(() => {
        setDialogState(DialogState.None);
    }, [setDialogState]);

    const onCreateAndNotifyClick = React.useCallback(async () => {
        if (patient) {
            client
                .post(new Dtos.SendPatientReminder({
                    patientId: patient.id
                }))
                .then(async response => {
                    try {
                        await updatePatientState(updateTo, false);

                        enqueueSnackbar(
                            <>
                                <AlertTitle>
                                    {eventTitle} Event Created
                                </AlertTitle>
                                The {eventName} event was successfully created.
                            </>,
                            { variant: 'success' }
                        );

                        closeDialog();
                    }
                    catch (error) {
                        enqueueSnackbar(
                            <>
                                <AlertTitle>
                                    {eventTitle} Event Not Created
                                </AlertTitle>
                                An error occurred while attempting to create the {eventName} event.
                            </>,
                            { variant: 'critical' }
                        );

                        closeDialog();
                    }
                })
                .catch((e) => {
                    enqueueSnackbar(
                        <>
                            <AlertTitle>
                                Patient Notify Email Not Sent
                            </AlertTitle>
                            An error occurred while attempting to send the patient's notification email.
                        </>,
                        { variant: 'critical' }
                    );
                })
        }
    }, [updatePatientState, enqueueSnackbar, updateTo, eventTitle, eventName, closeDialog, client, patient]);

    const onCreateClick = React.useCallback(async () => {
        try {
            await updatePatientState(updateTo, true);

            enqueueSnackbar(
                <>
                    <AlertTitle>
                        {eventTitle} Event Created
                    </AlertTitle>
                    The {eventName} event was successfully created.
                </>,
                { variant: 'success' }
            );

            closeDialog();
        }
        catch (error) {
            enqueueSnackbar(
                <>
                    <AlertTitle>
                        {eventTitle} Event Not Created
                    </AlertTitle>
                    An error occurred while attempting to create the {eventName} event.
                </>,
                { variant: 'critical' }
            );

            closeDialog();
        }
    }, [updatePatientState, enqueueSnackbar, updateTo, eventTitle, eventName, closeDialog]);

    const [sendingEmail, setSendingEmail] = React.useState(false);

    const onResendClick = React.useCallback(async () => {
        setSendingEmail(true);

        try {
            await client.post(new Dtos.SendPatientReminder({ patientId: patient?.id }));

            enqueueSnackbar(
                <>
                    <AlertTitle>
                        {eventTitle} Notification Sent
                    </AlertTitle>
                    The {eventName} notification was successfully sent to the patient.
                </>,
                { variant: 'success' }
            );

            closeDialog();
        }
        catch (error) {
            enqueueSnackbar(
                <>
                    <AlertTitle>
                        {eventTitle} Notification Not Sent
                    </AlertTitle>
                    An error occurred while attempting to create the {eventName} notification to the patient.
                </>,
                { variant: 'critical' }
            );

            closeDialog();
        }

        setSendingEmail(false);
    }, [patient, enqueueSnackbar, client, setSendingEmail, eventName, eventTitle, closeDialog]);

    const action: React.FunctionComponent<IPatientSummaryActionProps> = React.useCallback(({
        patient: actionPatient
    }) => {
        if (hideButton(actionPatient)) {
            return null;
        }

        if (actionPatient.patientStateId === updateTo) {
            return (
                <>
                    <ProgressButton
                        loading={saving || sendingEmail}
                        variant="contained"
                        color="primary"
                        onClick={openResendDialog}
                        spacing={0}
                        className={classes.button}
                    >
                        Resend
                    </ProgressButton>
                    <Dialog
                        open={dialogState === DialogState.Resend}
                        onClose={closeDialog}
                        aria-labelledby="resend-dialog-title"
                        aria-describedby="resend-dialog-description"
                    >
                        <DialogTitle id="resend-dialog-title">Resend {eventTitle} Notification?</DialogTitle>
                        <DialogContent>
                        <DialogContentText id="resend-dialog-description">
                                Resending the {eventName} notification will email the patient.
                                <br /><br />
                                Are you sure you wish to continue?
                        </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={closeDialog} color="secondary">
                                No
                        </Button>
                            <Button onClick={onResendClick} color="primary" variant="contained" autoFocus>
                                Yes
                        </Button>
                        </DialogActions>
                    </Dialog>
                </>
            );
        }

        const createButton = (
            <ProgressButton
                loading={saving}
                variant="contained"
                color="primary"
                onClick={openCreateDialog}
                spacing={0}
                className={classes.button}
                disabled={!patientValid || saving}
            >
                Create
            </ProgressButton>
        );

        const createAndNotifyButton = (
            <ProgressButton
                loading={saving}
                variant="contained"
                color="primary"
                onClick={openCreateAndNotifyDialog}
                spacing={0}
                className={classes.button}
                disabled={!patientValid || saving}
            >
                Create & Notify
            </ProgressButton>
        );

        if (patientValid) {
            return <>
                {createButton}
                {createAndNotifyButton}
                <Dialog
                    open={dialogState === DialogState.Create || dialogState === DialogState.CreateAndNotify}
                    onClose={closeDialog}
                    aria-labelledby="create-dialog-title"
                    aria-describedby="create-dialog-description"
                >
                    <DialogTitle id="create-dialog-title">Create {eventTitle} Event?</DialogTitle>
                    <DialogContent>
                        {
                            dialogState === DialogState.CreateAndNotify ?
                                <DialogContentText id="create-dialog-description">
                                    Creating the {eventName} Event will send an email notification to the patient.
                                    <br /><br />
                                    Are you sure you wish to continue?
                                </DialogContentText> :
                                <DialogContentText id="create-dialog-description">
                                    Are you sure you wish to create the {eventName} event?
                                </DialogContentText>

                        }
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={closeDialog} color="secondary">
                            No
                        </Button>
                        <Button onClick={dialogState === DialogState.CreateAndNotify ? onCreateAndNotifyClick : onCreateClick} color="primary" variant="contained" autoFocus>
                            Yes
                        </Button>
                    </DialogActions>
                </Dialog>
            </>;
        }

        return (

            <Tooltip
                className={classes.tooltip}
                title="Complete the previous event to continue"
            >
                <div
                    className={classes.wrapper}
                >
                    {createButton}
                    {createAndNotifyButton}
                </div>
            </Tooltip>
        );
    }, [onCreateClick, onResendClick, classes, saving, sendingEmail, patientValid, updateTo, hideButton, closeDialog, dialogState, eventName, eventTitle, openCreateAndNotifyDialog, openCreateDialog, openResendDialog, onCreateAndNotifyClick]);

    return action;
};

const PatientSummary: React.FunctionComponent<IPatientSummaryProps> = () => {
    const classes = useStyles();
    const eventActionClasses = useEventActionStyles();

    const { register, handleSubmit, reset } = useForm();

    
    const [updatePatientStatusOpen, setUpdatePatientStatusOpen] = React.useState(false);

    const onlinePatientManagement = React.useContext(OnlinePatientManagementContext);

    const { enqueueSnackbar } = useSnackbar()

    const { masterGroup } = React.useContext(MasterGroupContext);
    const { collaboratingGroup } = React.useContext(CollaboratingGroupContext);
    const { institution } = React.useContext(InstitutionContext);
    const { patient, actions, saveState } = React.useContext(PatientContext);

    const { patientSummary, loadState: summaryLoadState, validationState, load: summaryLoad } = usePatientSummary(patient?.id);

    const patientTreatment = React.useMemo(() => {
        if ((patient?.treatmentId === Dtos.TreatmentType.ChemotherapyFollowedByEndocrineTherapy)) {
            return "Chemotherapy Followed By Endocrine Therapy";
        }
        else if (((patient?.treatmentId === Dtos.TreatmentType.EndocrineTherapyAlone))) {
            return "Endocrine Therapy Alone";
        }
        else {
            return undefined;
        }
    }, [patient]);

    const saving = saveState.state === RequestState.Pending || summaryLoadState?.state === RequestState.Pending;

    const updatePatientState = React.useCallback(async (patientStateId: Dtos.PatientStateType, skipPatientEmail?: boolean, patientStatusReason?: string) => {
        await actions.asyncSave(new Dtos.Patient({ ...patient, patientStateId: patientStateId, patientStatusReason: patientStatusReason }));

        if (summaryLoad) {
            await summaryLoad();
        }
    }, [actions, summaryLoad, patient]);

    const [
        [
            canImpersonatePatient,
            canManagePatientAccount,
            canAdministerOpms
        ],
        permissionLoadState
    ] = usePermissionsByIds(permissions, masterGroup?.id, collaboratingGroup?.id, institution?.id, patient?.id, true);

    const patientValid = patientSummary?.result === ValidationResultType.Valid;

    const hideConsentActions = (actionPatient: IPatient | null) => !actionPatient || (actionPatient.patientStateId !== Dtos.PatientStateType.AwaitingRandomisation);
    const hideBaselineActions = (actionPatient: IPatient | null) => actionPatient != null;
    const hideThreeMonthActions = (actionPatient: IPatient | null) => !actionPatient || (actionPatient.patientStateId !== Dtos.PatientStateType.Randomised && actionPatient.patientStateId !== Dtos.PatientStateType.ThreeMonthFollowUp);
    const hideSixMonthActions = (actionPatient: IPatient | null) => !actionPatient || (actionPatient.patientStateId !== Dtos.PatientStateType.ThreeMonthFollowUp && actionPatient.patientStateId !== Dtos.PatientStateType.SixMonthFollowUp);
    const hideTwelveMonthActions = (actionPatient: IPatient | null) => !actionPatient || (actionPatient.patientStateId !== Dtos.PatientStateType.SixMonthFollowUp && actionPatient.patientStateId !== Dtos.PatientStateType.TwelveMonthFollowUp);
    const hideTwentyFourMonthActions = (actionPatient: IPatient | null) => !actionPatient || actionPatient.patientStateId !== Dtos.PatientStateType.TwentyFourMonthFollowUp;

    const consentActions = useCreateAndNotifyActions(Dtos.PatientStateType.AwaitingRandomisation, hideConsentActions, 'Consent', 'Consent', saving, updatePatientState, enqueueSnackbar, eventActionClasses, onlinePatientManagement.serviceStackClient, patient, patientValid);
    const baselineActions = useCreateAndNotifyActions(Dtos.PatientStateType.Randomised, hideBaselineActions, 'Baseline', 'Baseline', saving, updatePatientState, enqueueSnackbar, eventActionClasses, onlinePatientManagement.serviceStackClient, patient, patientValid);
    const threeMonthFollowUpEventActions = useCreateAndNotifyActions(Dtos.PatientStateType.ThreeMonthFollowUp, hideThreeMonthActions, '3 Month Follow Up', '3 month follow up', saving, updatePatientState, enqueueSnackbar, eventActionClasses, onlinePatientManagement.serviceStackClient, patient, patientValid);
    const sixMonthFollowUpEventActions = useCreateAndNotifyActions(Dtos.PatientStateType.SixMonthFollowUp, hideSixMonthActions, '6 Month Follow Up', '6 month follow up', saving, updatePatientState, enqueueSnackbar, eventActionClasses, onlinePatientManagement.serviceStackClient, patient, patientValid);
    const twelveMonthFollowUpEventActions = useCreateAndNotifyActions(Dtos.PatientStateType.TwelveMonthFollowUp, hideTwelveMonthActions, '12 Month Follow Up', '12 month follow up', saving, updatePatientState, enqueueSnackbar, eventActionClasses, onlinePatientManagement.serviceStackClient, patient, patientValid);
    const twentyFourMonthFollowUpEventActions = useCreateAndNotifyActions(Dtos.PatientStateType.TwentyFourMonthFollowUp, hideTwentyFourMonthActions, '24 Month Follow Up', '24 month follow up', saving, updatePatientState, enqueueSnackbar, eventActionClasses, onlinePatientManagement.serviceStackClient, patient, patientValid);

    const onIneligibleClick = React.useCallback(async () => {
        reset({patientStatusReason: ''});
        setUpdatePatientStatusOpen(true);
    }, [updatePatientState, enqueueSnackbar, reset]);

    const toggleUpdatePatientStatusOpenModal = React.useCallback(() => {
        setUpdatePatientStatusOpen(state => !state);
    }, []);

    const onSubmitPatientStatus = React.useCallback(async (data) => {
        setUpdatePatientStatusOpen(false);

        try {
            await updatePatientState(Dtos.PatientStateType.Ineligible, undefined, data.patientStatusReason);

            enqueueSnackbar(
                <>
                    <AlertTitle>
                        Participant Marked As Ineligible
                    </AlertTitle>
                    The participant was successfully marked ineligible.
                </>,
                { variant: 'success' }
            );
        }
        catch (error) {
            enqueueSnackbar(
                <>
                    <AlertTitle>
                        Participant Not Marked As Ineligible
                    </AlertTitle>
                    An error occurred while attempting to mark the participant as ineligible.
                </>,
                { variant: 'critical' }
            );
        }
    }, [updatePatientState, enqueueSnackbar]);

    const eventActionMapping = React.useMemo(() => {
        return {
            'consent': consentActions,
            'baseline': baselineActions,
            '3-month-follow-up': threeMonthFollowUpEventActions,
            '6-month-follow-up': sixMonthFollowUpEventActions,
            '12-month-follow-up': twelveMonthFollowUpEventActions,
            '24-month-follow-up': twentyFourMonthFollowUpEventActions,
        };
    }, [consentActions, baselineActions, threeMonthFollowUpEventActions, sixMonthFollowUpEventActions, twelveMonthFollowUpEventActions, twentyFourMonthFollowUpEventActions]);

    if (permissionLoadState.state === RequestState.None || permissionLoadState.state === RequestState.Pending) {
        return (
            <RouteLoading />
        );
    }


    return (
        
        <>
            <PatientBreadcrumbs />
            <div
                className={classes.container}
            >

                <PatientInformation
                    patientCaption={patientCaption}
                    patientStateCaption={patientStateCaption}
                    randomisationText={patientTreatment}
                />
                <br />
                <br />

                    <div
                        className={classNames(classes.title, classes.header)}
                    >
                        <div>
                            <Typography
                                variant="h1"
                                color="secondary"
                            >
                                Patient Summary
                            </Typography>
                        </div>
                        <div
                            className={classes.buttonGroup}
                        >
                            <PatientActiveDirectory
                                canImpersonatePatient={canImpersonatePatient}
                                canManagePatientAccount={canManagePatientAccount}
                            />
                        {
                            <Button
                                color="secondary"
                                variant="contained"
                                href={(window as any).UK_RANDOMISATION_SYSTEM_LINK}
                            >
                                Link to the UK Randomisation System
                            </Button>
                        }
                        </div>
                    </div>

                <div
                    className={classes.questionnaireInfo}
                >
                    <Alert
                        severity="info"
                    >
                        If a site staff member identifies a high level symptom or health status problem when reviewing questionnaire response, the site staff should alert the treating clinician immediately. The clinician will decide whether to refer the patient to other health services.
                    </Alert>
                </div>

                {
                    (patient as Dtos.Patient)?.patientStatusReason && patient?.patientStateId === Dtos.PatientStateType.Ineligible && 
                    (
                        <Alert
                            severity="info"
                        >
                            <AlertTitle>
                                <strong>
                                    Patient Ineligible
                                </strong>
                            </AlertTitle>
                            <Typography style={{whiteSpace: "pre-wrap"}}>
                                {(patient as Dtos.Patient)?.patientStatusReason}
                            </Typography>
                        </Alert>
                    )
                }


                <PatientSummaryList 
                    patient={patient ?? undefined}
                    patientSummary={patientSummary}
                    loadState={summaryLoadState}
                    validationState={validationState}
                    eventActions={eventActionMapping} 
                />
                

                <Dialog
                    open={updatePatientStatusOpen}
                    onClose={toggleUpdatePatientStatusOpenModal}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <form onSubmit={handleSubmit(onSubmitPatientStatus)}>
                    <DialogTitle id="alert-dialog-title">Update Patient Status</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Are you sure you want to update patient '{patient?.studyNumber}' to the new status of 'Ineligible'?
                        </DialogContentText>
                        <h3>Reason:</h3>
                        <textarea rows={8} className='patient-status-reason-textarea' style={{width: '100%'}}
                          {...register("patientStatusReason", { required: false })} />
                    </DialogContent>
                    <DialogActions>
                        <Button type="submit" color="secondary">
                            Confirm
                        </Button>
                        <Button onClick={() => setUpdatePatientStatusOpen(false)} color="primary" variant="contained" autoFocus>
                            Close
                        </Button>
                    </DialogActions>
                    </form>
                </Dialog>
                {
                    (patient?.patientStateId === undefined || 
                        patient?.patientStateId === Dtos.PatientStateType.AwaitingRandomisation || 
                        patient?.patientStateId === Dtos.PatientStateType.Randomised) &&
                    canAdministerOpms && (
                        <ProgressButton
                            loading={saving}
                            variant="contained"
                            color="primary"
                            onClick={onIneligibleClick}
                        >
                            Mark As Ineligible
                        </ProgressButton>
                    )
                }
            </div>
        </>
    );
}


/*
 * ----------------------------------------------------------------------------------
 * Default Export
 * ----------------------------------------------------------------------------------
 */

export default PatientSummary;
