import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { Table, useAuthenticatedUser } from '@ngt/opms';
import { useHistory } from 'react-router-dom';
import { IReportParam, createReportUrl, ReportExportFormat } from '../utils/createReportUrl';
import { Column } from 'material-table';
import * as Dtos from '../api/dtos';
import { usePermissionsByIds } from '@ngt/opms-bctapi';
//import { usePermissionsByIds } from '@ngt/opms-bctapi';

interface IReportsProps {
    institutionCode?: string
}

export interface IReportItem {
    reportType: number;
    reportTitle: string;
    reportIdentifier: string;
    reportParams?: IReportParam[];
    reportFormat?: ReportExportFormat;
}

const useStyles = makeStyles(theme => ({
    container: {
        padding: theme.spacing(3),
        display: 'flex',
        gap: 50
    },
    tableWrapper: {
        width: '100%'
    }
}));

const SSRS_REPORT = 1;
const OPMS_REPORT = 2;

const permissions: Dtos.Permission[] = [
    Dtos.Permission.EcrfView,
    Dtos.Permission.OpmsAdminister
];

const Reports: React.FunctionComponent<IReportsProps> = () => {
    const classes = useStyles();

    const history = useHistory();

    //const [user] = useAuthenticatedUser();

    const [[canViewEcrf, canAdministerOpms],] = usePermissionsByIds(permissions, null, null, null, null, true);

    const onOpmsReportClick = React.useCallback((report?: IReportItem | undefined) => {
        if (report) {
            history.push(`/${report.reportIdentifier}`);
        }
    }, [history]);

    const onSsrsReportClick = React.useCallback((report?: IReportItem | undefined) => {
        if (report) {
            const reportUrl: string = createReportUrl(report.reportIdentifier,
                report?.reportParams ?? [],
                report.reportFormat ? report.reportFormat as unknown as ReportExportFormat : undefined,
                true);

            window.open(reportUrl);
        }
    }, []);

    const onRowClick = React.useCallback((event: React.MouseEvent<Element, MouseEvent>, row?: IReportItem | undefined) => {
        if (row?.reportType === SSRS_REPORT) {
            onSsrsReportClick(row);
        } else if (row?.reportType === OPMS_REPORT) {
            onOpmsReportClick(row);
        }
    }, [onSsrsReportClick, onOpmsReportClick]);

    const reportColumns = React.useMemo(() => {
        const columns: Array<Column<IReportItem>> = [
            {
                title: 'Reports',
                field: 'reportTitle',
            }
        ];

        return columns;
    }, []);

    const reports = React.useMemo(() => {
        return [
            !!canAdministerOpms && {
                reportType: SSRS_REPORT,
                reportTitle: 'Prosigna Assay Results',
                reportIdentifier: 'ProsignaAssayResults',
                reportParams: [
                    { paramName: 'Token', paramValue: 'EmailLink' }
                ]
            }
        ] as IReportItem[];
    }, [canAdministerOpms]);

    return (
        <>
            <div
                className={classes.container}
            >
                <div
                    className={classes.tableWrapper}
                >
                    <Table
                        data={reports}
                        columns={reportColumns}
                        onRowClick={onRowClick}
                        title="Reports"
                        components={{
                            Header: props => <></>
                        }}
                        options={{
                            paging: reports.length > 10,
                            pageSize: 10,
                        }}
                    />
                </div>
            </div>
        </>
    );
};

export default Reports;
