/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the header component
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/**
 * Required to make use of JSX functionality
 */
import * as React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

/**
 * Used to create a style hook to utilise JSS
 */
import { Theme, makeStyles } from '@material-ui/core/styles';


/**
 * Used to style buttons.
 */
import Button from '@material-ui/core/Button';

import Dialog from '@material-ui/core/Dialog';

import DialogActions from '@material-ui/core/DialogActions';

import DialogContent from '@material-ui/core/DialogContent';

import DialogContentText from '@material-ui/core/DialogContentText';

import DialogTitle from '@material-ui/core/DialogTitle';

import {
    PatientContext,
    OnlinePatientManagementContext,

    IPatient,
    useSnackbar
} from '@ngt/opms'


import { JsonServiceClient } from '@servicestack/client'


/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

/**
 * Used to get the BCT Website URL.
 */

import * as Dtos from '../api/dtos';

import { faUserShield } from '@fortawesome/pro-duotone-svg-icons/faUserShield';

import TextField from '@material-ui/core/TextField';

import { Tabs, Tab, AppBar } from '@material-ui/core';

import AlertTitle from '@material-ui/lab/AlertTitle';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

interface IPatientActiveDirectoryProps {
    canImpersonatePatient: boolean;
    canManagePatientAccount: boolean;
}

interface IPatientActiveDirectoryViewProps {
    onClose: () => void;
    patient?: IPatient | null;
    client: JsonServiceClient;
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles<Theme>(theme => ({
    container: {
        padding: theme.spacing(3)
    },
    heading: {
        marginTop: theme.spacing(4)
    },
    patientSummary: {
        marginTop: theme.spacing(3)
    },
    buttonSet: {
        textAlign: 'right',
        paddingTop: theme.spacing(3)
    },
    button: {
        marginLeft: theme.spacing(3),

        '&:first-child': {
            marginLeft: theme.spacing(0)
        }
    }
}));


/*
 * ---------------------------------------------------------------------------------
 * Constants
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Components
 * ---------------------------------------------------------------------------------
 */

const CreateAccount: React.FunctionComponent<IPatientActiveDirectoryViewProps> = ({
    onClose,
    patient,
    client
}) => {
    const [value, setValue] = React.useState('');

    const handleChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.value);
    }, [setValue]);

    const [password, setPassword] = React.useState('');

    const handlePasswordChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(event.target.value);
    }, [setPassword]);

    const { enqueueSnackbar } = useSnackbar();

    const [errorMessage, setErrorMessage] = React.useState('');
    const [updating, setUpdating] = React.useState(false);
    const [error, setError] = React.useState(false);

    const { actions } = React.useContext(PatientContext);

    const onClick = React.useCallback(() => {
        if (patient) {
            setError(false);
            setUpdating(true);
            client
                .post(new Dtos.PatientAccountCreate({
                    patientId: patient.id,
                    email: value,
                    password: password
                }))
                .then(response => {
                    setErrorMessage('');
                    setError(false);
                    setValue('');
                    setPassword('');
                    setUpdating(false);
                    onClose();
                    actions.load();
                    enqueueSnackbar(
                        <>
                            <AlertTitle>
                                Patient Account Create
                            </AlertTitle>
                            The patient's account was created successfully.
                        </>,
                        { variant: 'success' }
                    );
                })
                .catch((e) => {
                    setErrorMessage(e.responseStatus.message);
                    setError(true);
                    setUpdating(false);
                    enqueueSnackbar(
                        <>
                            <AlertTitle>
                                Patient Account Not Updated
                            </AlertTitle>
                            An error occurred while attempting to create the patient's account.
                        </>,
                        { variant: 'critical' }
                    );
                })
        }
    }, [setUpdating, setError, setErrorMessage, client, setValue, patient, value, onClose, actions, setPassword, password, enqueueSnackbar]);


    return (
        <>
            <DialogTitle id="form-dialog-title">Patient Account</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Create an account for the patient to gain access to electronic PRO forms.
                </DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    value={value}
                    onChange={handleChange}
                    label="Enter an email address"
                    type="email"
                    fullWidth
                />
                <TextField
                    margin="dense"
                    id="name"
                    value={password}
                    onChange={handlePasswordChange}
                    label="Enter a password"
                    type="password"
                    fullWidth
                />
                {error && (
                    <DialogContentText style={{ color: '#f44336' }}>
                        {errorMessage}
                    </DialogContentText>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="secondary">
                    Cancel
                </Button>
                <Button onClick={onClick} color="primary" disabled={updating}>
                    Create Account
                </Button>
            </DialogActions>
        </>
    );
};

const SetPassword: React.FunctionComponent<IPatientActiveDirectoryViewProps> = ({
    onClose,
    patient,
    client
}) => {
    const [value, setValue] = React.useState('');

    const handleChange = React.useCallback((event: React.ChangeEvent <HTMLInputElement>) => {
        setValue(event.target.value);
    }, [setValue]);

    const { enqueueSnackbar } = useSnackbar();

    const [errorMessage, setErrorMessage] = React.useState('');
    const [updating, setUpdating] = React.useState(false);
    const [error, setError] = React.useState(false);

    const onClick = React.useCallback(() => {
        if (patient) {
            setError(false);
            setUpdating(true);
            client
                .post(new Dtos.PatientAccountSetPassword({
                    patientId: patient.id,
                    password: value
                }))
                .then(response => {
                    setError(false);
                    setValue('');
                    setUpdating(false);
                    onClose();
                    enqueueSnackbar(
                        <>
                            <AlertTitle>
                                Patient Account Updated
                            </AlertTitle>
                            The patient's password was updated successfully.
                        </>,
                        { variant: 'success' }
                    );
                })
                .catch((e) => {
                    setErrorMessage(e.responseStatus.message);
                    setError(true);
                    setUpdating(false);
                    enqueueSnackbar(
                        <>
                            <AlertTitle>
                                Patient Account Not Updated
                            </AlertTitle>
                            An error occurred while attempting to update the patient's password.
                        </>,
                        { variant: 'critical' }
                    );
                })
        }
    }, [setUpdating, setError, setErrorMessage, client, setValue, patient, value, onClose, enqueueSnackbar]);


    return (
        <>
            <DialogTitle id="form-dialog-title">Set Password</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Set a new password for the patient. The password must be at least 8 characters long.
                </DialogContentText>
                <DialogContentText>
                    The password must:
                    <ul>
                        <li>
                            Be at least 8 characters long
                        </li>
                        <li>
                            Contain at least one upper case letter.
                        </li>
                        <li>
                            Contain at least one lower case letter.
                        </li>
                        <li>
                            Contain at least one number.
                        </li>
                        <li>
                            Container at least one special character (!, @, #, etc).
                        </li>
                    </ul>
                </DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    value={value}
                    onChange={handleChange}
                    label="Enter a new password"
                    type="password"
                    fullWidth
                />
                {error && (
                    <DialogContentText style={{ color: '#f44336' }}>
                        {errorMessage}
                    </DialogContentText>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="secondary">
                    Cancel
                </Button>
                <Button onClick={onClick} color="primary" disabled={updating}>
                    Set Password
                </Button>
            </DialogActions>
        </>
    );
};

const SetEmail: React.FunctionComponent<IPatientActiveDirectoryViewProps> = ({
    onClose,
    patient,
    client
}) => {
    const [value, setValue] = React.useState('');

    const handleChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.value);
    }, [setValue]);

    const { enqueueSnackbar } = useSnackbar();

    const [errorMessage, setErrorMessage] = React.useState('');
    const [updating, setUpdating] = React.useState(false);
    const [error, setError] = React.useState(false);

    const onClick = React.useCallback(() => {
        if (patient) {
            setError(false);
            setUpdating(true);
            client
                .post(new Dtos.PatientAccountUpdateEmail({
                    patientId: patient.id,
                    email: value
                }))
                .then(response => {
                    setError(false);
                    setValue('');
                    setUpdating(false);
                    onClose();
                    enqueueSnackbar(
                        <>
                            <AlertTitle>
                                Patient Account Updated
                            </AlertTitle>
                            The patient's email was updated successfully.
                        </>,
                        { variant: 'success' }
                    );
                })
                .catch((e) => {
                    setErrorMessage(e.responseStatus.message);
                    setError(true);
                    setUpdating(false);
                    enqueueSnackbar(
                        <>
                            <AlertTitle>
                                Patient Account Not Updated
                            </AlertTitle>
                            An error occurred while attempting to update the patient's email.
                        </>,
                        { variant: 'critical' }
                    );
                })
        }
    }, [setUpdating, setError, setErrorMessage, client, setValue, patient, value, onClose, enqueueSnackbar]);


    return (
        <>
            <DialogTitle id="form-dialog-title">Update Email</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Update the email address for the patient.
                </DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    value={value}
                    onChange={handleChange}
                    label="Enter a new email address"
                    type="email"
                    fullWidth
                />
                {error && (
                    <DialogContentText style={{ color: '#f44336' }}>
                        {errorMessage}
                    </DialogContentText>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="secondary">
                    Cancel
                </Button>
                <Button onClick={onClick} color="primary" disabled={updating}>
                    Update Email
                </Button>
            </DialogActions>
        </>
    );
};

const ResendEmail: React.FunctionComponent<IPatientActiveDirectoryViewProps> = ({
    onClose,
    patient,
    client
}) => {
    const { enqueueSnackbar } = useSnackbar();

    const [errorMessage, setErrorMessage] = React.useState('');
    const [updating, setUpdating] = React.useState(false);
    const [error, setError] = React.useState(false);

    const onClick = React.useCallback(() => {
        if (patient) {
            setError(false);
            setUpdating(true);
            client
                .post(new Dtos.PatientAccountResendWelcome({
                    patientId: patient.id
                }))
                .then(response => {
                    setError(false);
                    setUpdating(false);
                    onClose();
                    enqueueSnackbar(
                        <>
                            <AlertTitle>
                                Patient Welcome Sent
                            </AlertTitle>
                            The patient's welcome email was successfully sent.
                        </>,
                        { variant: 'success' }
                    );
                })
                .catch((e) => {
                    setErrorMessage(e.responseStatus.message);
                    setError(true);
                    setUpdating(false);
                    enqueueSnackbar(
                        <>
                            <AlertTitle>
                                Patient Welcome Not Sent
                            </AlertTitle>
                            An error occurred while attempting to send the patient's welcome email.
                        </>,
                        { variant: 'critical' }
                    );
                })
        }
    }, [setUpdating, setError, setErrorMessage, client, patient, onClose, enqueueSnackbar]);


    return (
        <>
            <DialogTitle id="form-dialog-title">Resend Email</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Resend the participant welcome email.
                </DialogContentText>
                <DialogContentText>
                    This email contains a website link to provide the participant with access to the baseline ePROMs questionnaires.
                </DialogContentText>
                <DialogContentText>
                    Resending this email will provide the participant with a new link and will require the participant to update their password.
                </DialogContentText>
                {error && (
                    <DialogContentText style={{ color: '#f44336' }}>
                        {errorMessage}
                    </DialogContentText>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="secondary">
                    Cancel
                </Button>
                <Button onClick={onClick} color="primary" disabled={updating}>
                    Resend Email
                </Button>
            </DialogActions>
        </>
    );
};


const Impersonate: React.FunctionComponent<IPatientActiveDirectoryViewProps> = ({
    onClose,
    patient,
    client
}) => {
    const onClick = React.useCallback(() => {
        window.location.href = '/auth/openiddict?impersonate=' + patient?.studyNumber;
    }, [patient]);


    return (
        <>
            <DialogTitle id="form-dialog-title">Impersonate</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Impersonate the patient. Impersonating the patient will require reentry of your login details.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="secondary">
                    Cancel
                </Button>
                <Button onClick={onClick} color="primary">
                    Impersonate
                </Button>
            </DialogActions>
        </>
    );
};


const PatientActiveDirectory: React.FunctionComponent<IPatientActiveDirectoryProps> = (props) => {
    const classes = useStyles();

    const { patient } = React.useContext(PatientContext);

    const onlinePatientManagement = React.useContext(OnlinePatientManagementContext);

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const [selectedTab, setSelectedTab] = React.useState(props.canImpersonatePatient ? 0 : 1);

    const handleTabChange = React.useCallback((event: React.ChangeEvent, newValue: any) => {
        setSelectedTab(newValue);
    }, [setSelectedTab]);

    if (patient?.patientStateId !== Dtos.PatientStateType.Randomised &&
        patient?.patientStateId !== Dtos.PatientStateType.ThreeMonthFollowUp &&
        patient?.patientStateId !== Dtos.PatientStateType.SixMonthFollowUp &&
        patient?.patientStateId !== Dtos.PatientStateType.TwelveMonthFollowUp &&
        patient?.patientStateId !== Dtos.PatientStateType.TwentyFourMonthFollowUp) {
        return null;
    }

    return (
        <>
            {
                (props.canImpersonatePatient || props.canManagePatientAccount) && (
                    <Button
                        variant="contained"
                        color="default"
                        onClick={handleClickOpen}
                        className={classes.button}
                    >
                        <FontAwesomeIcon icon={faUserShield} fixedWidth />&nbsp;Patient Account
                    </Button>
                )
            }
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" maxWidth="md">
                {
                    !(patient as Dtos.Patient).email ?
                        <>
                            <CreateAccount
                                client={onlinePatientManagement.serviceStackClient}
                                onClose={handleClose}
                                patient={patient}
                            />
                        </> :
                        <>
                            <AppBar
                                position="static"
                                color="primary"
                            >
                                <Tabs
                                    value={selectedTab}
                                    onChange={handleTabChange}
                                    variant="scrollable"
                                >
                                    {
                                        !!props.canImpersonatePatient && (
                                            <Tab
                                                label="Impersonate"
                                                value={0}
                                            />
                                        )
                                    }
                                    {
                                        !!props.canManagePatientAccount && (
                                            <Tab
                                                label="Resend Email"
                                                value={1}
                                            />
                                        )
                                    }
                                    {
                                        !!props.canManagePatientAccount && (
                                            <Tab
                                                label="Update Email"
                                                value={2}
                                            />
                                        )
                                    }
                                    {
                                        !!props.canManagePatientAccount && (
                                            <Tab
                                                label="Set Password"
                                                value={3}
                                            />
                                        )
                                    }
                                </Tabs>
                            </AppBar>
                            {
                                selectedTab === 0 && (
                                    <Impersonate
                                        client={onlinePatientManagement.serviceStackClient}
                                        onClose={handleClose}
                                        patient={patient}
                                    />
                                )
                            }
                            {
                                selectedTab === 1 && (
                                    <ResendEmail
                                        client={onlinePatientManagement.serviceStackClient}
                                        onClose={handleClose}
                                        patient={patient}
                                    />
                                )
                            }
                            {
                                selectedTab === 2 && (
                                    <SetEmail
                                        client={onlinePatientManagement.serviceStackClient}
                                        onClose={handleClose}
                                        patient={patient}
                                    />
                                )
                            }
                            {
                                selectedTab === 3 && (
                                    <SetPassword
                                        client={onlinePatientManagement.serviceStackClient}
                                        onClose={handleClose}
                                        patient={patient}
                                    />
                                )
                            }
                        </>
                }
            </Dialog>
        </>
    );
};

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */
export default PatientActiveDirectory;