/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * This file contains the component that provides context for the online patient
 * management system.
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */

/**
 * Required to use React components.
 */
import * as React from 'react';

import { Redirect, Route, Switch, useHistory } from 'react-router-dom';

/**
 * Used for the basic page layout.
 */
import {
    RouteLoading
} from '@ngt/opms';

/**
 * Used for font styling.
 */
import { Typography } from '@material-ui/core';

/**
 * Used to display icons
 */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/**
 * Used for the copyright icon.
 */
import { faCopyright } from '@fortawesome/pro-regular-svg-icons/faCopyright';

/*
 * ----------------------------------------------------------------------------------
 * Imports - Internal
 * ----------------------------------------------------------------------------------
 */

import EConsentLayout from './components/econsent/EConsentLayout';
import InvitationPage from './containers/econsent/InvitationPage';
import TrialConsentPage from './containers/econsent/TrialConsentPage';



/*
 * ----------------------------------------------------------------------------------
 * Interface
 * ----------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * constants
 * ----------------------------------------------------------------------------------
 */


/*
 * ----------------------------------------------------------------------------------
 * Components
 * ----------------------------------------------------------------------------------
 */

const EConsentApp: React.FunctionComponent = () => {
    const history = useHistory();

    const headerText = React.useMemo(() => {
        if (history?.location?.pathname?.startsWith("/public/invitation/")) {
            return undefined;
        }

        return "OPTIMA Trial";
    }, [history.location.pathname]);

    return (
        <>
            <EConsentLayout
                headerText={headerText}
                footer={
                    <Typography variant="caption">
                        Copyright <FontAwesomeIcon icon={faCopyright} fixedWidth /> 2022 ANZ Breast Cancer Trials Group Ltd | ABN 64 051 369 496
                    </Typography>
                }
            >
                <React.Suspense fallback={<RouteLoading />}>
                    {
                        <Switch>
                            <Route path={[
                                "/public/trial-consent/:guid",
                                "/public/trial-consent-signed/:guid"
                            ]}>
                                <TrialConsentPage />
                            </Route>
                            <Route path={[
                                "/public/invitation/:guid",
                                "/public"
                            ]}>
                                <InvitationPage />
                            </Route>
                            <Redirect path="*" to="/public" />
                        </Switch>
                    }
                </React.Suspense>
            </EConsentLayout>
        </>
    );
}


/*
 * ----------------------------------------------------------------------------------
 * Default Export
 * ----------------------------------------------------------------------------------
 */

export default EConsentApp;