import { makeStyles } from "@material-ui/core";
import { DialogButtonOrder, IProStepsProps } from "@ngt/opms";
import React from "react";

/*
 * ---------------------------------------------------------------------------------
 * Functions
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles(() => ({
    dialogActions: {
        alignItems: 'stretch'
    },
    button: {
        flexGrow: 1,
        flexBasis: 0
    },

}));

export const useDefaultStepsProps = () => {
    const classes = useStyles();

    const defaultStepsProps = React.useMemo(() => {
        return {
            cancelButtonText: 'Go back to the form',
            skipButtonText: 'Proceed to the next page',
            dialogButtonOrder: DialogButtonOrder.CancelFirst,
            dialogActionsProps: {
                className: classes.dialogActions
            },
            cancelButtonProps: {
                variant: 'contained',
                color: 'default',
                className: classes.button
            },
            skipButtonProps: {
                variant: 'contained',
                color: 'primary',
                className: classes.button
            }
        } as IProStepsProps;
    }, [classes]);

    return defaultStepsProps;
}