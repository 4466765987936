/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * This file contains the component that provides context for the online patient
 * management system.
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */

/**
 * Required to use React components.
 */
import * as React from 'react';

/**
 * Used for the basic page layout.
 */
import {
    PatientValidationContext,

    EventDefinitionsContext,
    FormDefinitionsContext,
    FormType,
    IEventDefinition,
    IFormDefinition,
    usePatientValidationByStudyNumber,
    PatientContext,
    RouteLoading
} from '@ngt/opms';

import { Switch, Redirect, Link, useParams } from 'react-router-dom';

import Alert from '@material-ui/lab/Alert';

import AlertTitle from '@material-ui/lab/AlertTitle';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faCheckCircle } from '@fortawesome/pro-regular-svg-icons/faCheckCircle';

import { faSignOutAlt } from '@fortawesome/pro-duotone-svg-icons/faSignOutAlt';

import { faChevronRight } from '@fortawesome/pro-duotone-svg-icons/faChevronRight';

import { Button, makeStyles, Typography, Paper, ListItem, ListItemText, lighten, darken, ListItemIcon } from '@material-ui/core';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons/faInfoCircle';
import * as classNames from 'classnames';
import { RequestState } from '@ngt/request-utilities';


/*
 * ----------------------------------------------------------------------------------
 * Interface
 * ----------------------------------------------------------------------------------
 */

interface IProStatus {
    eventDefinition: IEventDefinition | undefined;
    formDefinition: IFormDefinition | undefined;
    eventRepeat: number | undefined;
    formRepeat: number | undefined;
    completed: boolean | undefined;
}

interface IProProgressProps {
    proStatuses: IProStatus[];
}

interface IPatientProsProps {
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles(theme => ({
    paper: {
        padding: theme.spacing(3, 3, 0, 3),

        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(0),
            border: 'none',
            boxShadow: 'none'
        }
    },
    paragraph: {
        paddingBottom: theme.spacing(1.5),
    },
    paragraphBold: {
        paddingBottom: theme.spacing(1.5),
        textAlign: 'center'
    },
    questionnaireInfo: {
        paddingBottom: theme.spacing(1.5)
    },
    infoPanel: {
        paddingTop: theme.spacing(1.5)
    },
    wrapper: {
        [theme.breakpoints.up('md')]: {
            background: theme.palette.grey[100]
        }
    },
    welcome: {
        padding: theme.spacing(3),

        [theme.breakpoints.up('md')]: {
            maxWidth: 800,
            margin: 'auto'
        }
    },
    continue: {
        padding: theme.spacing(0, 3, 3, 3),
        textAlign: 'center',
    },
    button: {
        padding: theme.spacing(3),
        textAlign: 'center',

        [theme.breakpoints.up('md')]: {
            textAlign: 'right'
        }
    }
}));

const useProgressStyles = makeStyles(theme => {

    const getBackgroundColor = theme.palette.type === 'light' ? lighten : darken;

    return {
        container: {
            paddingBottom: theme.spacing(1.5)
        },
        heading: {
            fontWeight: 'bold',
            paddingBottom: theme.spacing(1)
        },
        item: {
            border: `1px solid ${theme.palette.grey[300]}`,
        },
        complete: {
            backgroundColor: getBackgroundColor(theme.palette.success.main, 0.9)
        },
        incomplete: {
            backgroundColor: getBackgroundColor(theme.palette.warning.main, 0.9)
        },
        icon: {
            marginLeft: 'auto',
            minWidth: 0,
            color: theme.palette.success.main
        }
    }
});

/*
 * ----------------------------------------------------------------------------------
 * Components
 * ----------------------------------------------------------------------------------
 */

const ProProgress: React.FunctionComponent<IProProgressProps> = ({
    proStatuses
}) => {
    const classes = useProgressStyles();

    const items = proStatuses.map((ps, index) => (
        <ListItem
            key={`ps-${index}`}
            className={classNames(
                classes.item,
                { [classes.complete]: ps.completed === true },
                { [classes.incomplete]: !ps.completed })
                }
        >
            <ListItemText
                primary={ps.formDefinition?.name}
            />
            {
                ps.completed === true && (
                    <ListItemIcon
                        className={classes.icon}
                    >
                        <FontAwesomeIcon fixedWidth icon={faCheckCircle} size="2x" />
                    </ListItemIcon>
                )
            }
        </ListItem>
    ))

    return (
        <div
            className={classes.container}
        >
            <Typography
                className={classes.heading}
            >
                Forms for this visit
            </Typography>
        {
            items
        }
        </div>
    );
}

const PatientProsForm: React.FunctionComponent = () => {
    const { patient } = React.useContext(PatientContext);

    const [patientValidation, patientValidationLoadState, patientValidationActions] = usePatientValidationByStudyNumber(patient!.studyNumber, true);

     const patientValidationContext = React.useMemo(() => {
         return {
             validation: patientValidation,
             loadState: patientValidationLoadState,
             actions: patientValidationActions
         };
     }, [patientValidation, patientValidationLoadState, patientValidationActions]);

    const notLoading = patientValidationContext.loadState.state !== RequestState.None &&
        patientValidationContext.loadState.state !== RequestState.Pending;

    return (
        <>
            <PatientValidationContext.Provider value={patientValidationContext}>
                {
                    notLoading ?
                        <PatientPros /> :
                        <RouteLoading />
                }
            </PatientValidationContext.Provider>
        </>
    );
}

const PatientPros: React.FunctionComponent<IPatientProsProps> = () => {
    const { validation } = React.useContext(PatientValidationContext);
    const { eventDefinitions } = React.useContext(EventDefinitionsContext);
    const { formDefinitions } = React.useContext(FormDefinitionsContext);

    const classes = useStyles();
    const params = useParams<{ next: string; }>();

    const next = params.next === 'next';

    const latestPros = React.useMemo(() => {
        if (!validation || !validation.eventResults) {
            return [];
        }

        const proEvents = validation.eventResults.filter(er => {
            if (!er.formResults) {
                return false;
            }

            if (!er.write) {
                return false;
            }
            return er.formResults.some(fr => {
                const formDefinition = formDefinitions?.find(fd => fd.id === fr.formDefinitionId);
                return formDefinition?.type === FormType.Pro;
            })
        });

        if (proEvents.length === 0) {
            return [];
        }

        const latestProEvent = proEvents[proEvents.length - 1];

        const eventDefinition = eventDefinitions?.find(ed => ed.id === latestProEvent.eventDefinitionId);

        return latestProEvent.formResults
            .map(fr => {
                const formDefinition = formDefinitions?.find(fd => fd.id === fr.formDefinitionId)

                return {
                    eventDefinition,
                    formDefinition,
                    eventRepeat: latestProEvent.eventRepeat,
                    formRepeat: fr.formRepeat,
                    completed: fr.completed
                }
            })
            .filter(forms => forms.formDefinition?.type === FormType.Pro);
    }, [validation, eventDefinitions, formDefinitions]);

    const completed = React.useMemo(() => {
        return !latestPros.some(lp => !lp.completed);
    }, [latestPros]);

    const nextPro = React.useMemo(() => {
        if (!latestPros) {
            return null;
        }
        const nextPro = latestPros.find(lp => !lp.completed);
        
        if (!nextPro) {
            return null;
        }

        return nextPro;
    }, [latestPros])

    return (
        <>
            {
                !completed && !!next && nextPro && (
                    <Switch>
                        <Redirect to={`/${nextPro?.eventDefinition?.code}/${nextPro?.eventRepeat}/${nextPro?.formDefinition?.code}/${nextPro?.formRepeat}`} />
                    </Switch>
                )
            }
            {
                !completed && !next && (
                    <div className={classes.wrapper}>
                        <div
                            className={classes.welcome}
                        >
                            <Paper elevation={3} className={classes.paper}>
                                <Typography
                                    className={classes.paragraphBold}
                                >
                                    <strong>Welcome to the BCT 2202 &ndash; Optima Patient questionnaire platform.</strong>
                                </Typography>
                                <Typography
                                    className={classes.paragraph}
                                >
                                    On the following screens will be a series of topics with related questions which you have agreed to complete as part of your participation in the <strong>Optima</strong> research study. 
                                </Typography>
                                <Typography
                                    className={classes.paragraph}
                                >
                                    As you complete each series of questions the platform will automatically progress you through the required topics until you reach the end.
                                </Typography>
                                <Typography
                                    className={classes.paragraph}
                                >
                                    You can monitor your progress through the questions via the progress bar at the bottom of the screen.  You will be presented with a completion screen once all questions have been answered.
                                </Typography>
                                <Typography
                                    className={classes.paragraphBold}
                                >
                                    <strong>Thank you for taking the time to complete this series of questions.</strong>
                                </Typography>
                                <div
                                    className={classes.questionnaireInfo}
                                >
                                    <Alert
                                        severity="info"
                                        icon={<FontAwesomeIcon fixedWidth icon={faInfoCircle} />}
                                    >
                                        Individual questionnaire response will not be viewed by a member of the treatment team.
                                        If you have any concerns please report it directly to your treatment team.
                                    </Alert>
                                </div>
                                <ProProgress
                                    proStatuses={latestPros}
                                />
                                <div
                                    className={classes.continue}
                                >
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        component={Link}
                                        to={`/${nextPro?.eventDefinition?.code}/${nextPro?.eventRepeat}/${nextPro?.formDefinition?.code}/${nextPro?.formRepeat}`}
                                    >
                                        Continue <FontAwesomeIcon fixedWidth icon={faChevronRight} />
                                    </Button>
                                </div>
                            </Paper>
                        </div>
                    </div>
                )
            }
            {
                !!completed && (
                    <>
                        <Alert severity="success" icon={<FontAwesomeIcon fixedWidth icon={faCheckCircle} />} >
                            <AlertTitle>
                                PROs Complete
                            </AlertTitle>
                            Thank you for taking the time to complete the PRO forms.
                        </Alert>
                        <div
                            className={classes.infoPanel}
                        >
                            <Alert severity="info" icon={<FontAwesomeIcon fixedWidth icon={faInfoCircle} />} >
                                Individual questionnaire response will not be viewed by a member of the treatment team.
                                If you have any concerns please report it directly to your treatment team.
                            </Alert>
                        </div>
                        <div
                            className={classes.button}
                        >
                            <Button
                                color="primary"
                                variant="contained"
                                component="a"
                                href="/auth/logout"
                            >
                                Logout <FontAwesomeIcon fixedWidth icon={faSignOutAlt} />
                            </Button>
                        </div>
                    </>
                )
            }
        </>
    );
}

/*
 * ----------------------------------------------------------------------------------
 * Default Export
 * ----------------------------------------------------------------------------------
 */

export default PatientProsForm;
