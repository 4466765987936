/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * This file contains the component that provides context for the online patient
 * management system.
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */

/**
 * Required to use React components.
 */
import * as React from 'react';


/**
 * Used for the basic page layout.
 */
import {
    CollaboratingGroupContext, CrfForm,
    Field, FieldProvider, FormBreadcrumbs, FormGrid, FormLookupContext, FormOptionsContext, GetFieldLookupItem, IFormGridCell,
    Input, InstitutionContext, KeyboardDatePicker, MasterGroupContext, Numeric, OnlinePatientManagementContext, PatientContext, ProgressButton, RouteLoading, Select, Text, useFormActions, useSnackbar
} from '@ngt/opms';

import { Permission, usePermissionsByIds } from '@ngt/opms-bctapi';

import { RequestState } from '@ngt/request-utilities';

import { Button, Dialog, DialogContent, DialogTitle, makeStyles, Typography } from '@material-ui/core';

import { cloneDeep } from 'lodash-es';


/*
 * ----------------------------------------------------------------------------------
 * Imports - Internal
 * ----------------------------------------------------------------------------------
 */

/*
 * Used to type patient state.
 */
import { AlertTitle } from '@material-ui/lab';
import { DateTime } from 'luxon';
import * as Dtos from '../../api/dtos';
import InputDisplay from '../../components/InputDisplay';

/*
 * ----------------------------------------------------------------------------------
 * Interface
 * ----------------------------------------------------------------------------------
 */

interface ITissueSampleTrackingProps {
}

interface IShipmentContext {
    index: number | undefined;
    setIndex: (index: number | undefined) => void;
    openShipmentDialog: boolean;
    setOpenShipmentDialog: (open: boolean) => void;
    tissueSampleIdLookup: Dtos.Lookup | undefined;
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles(theme => ({
    container: {
        padding: theme.spacing(3)
    },
    subHeading: {
        padding: theme.spacing(3, 3, 1, 2)
    },
    dialogFormTitle: {
        color: theme.palette.secondary.main,
        borderBottom: 'solid rgb(224, 224, 224) 1px',

        '& *': {
            fontSize: '1.5rem !important'
        }
    },
    dialogButton: {
        textAlign: 'right',
        padding: theme.spacing(2),

        '& > :first-child': {
            marginRight: theme.spacing(1),
        }
    },
}));

/*
 * ---------------------------------------------------------------------------------
 * Functions
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Components
 * ----------------------------------------------------------------------------------
 */

const permissions: Permission[] = [
    Permission.OpmsCrfUpdate
];

const ShipmentContext = React.createContext<IShipmentContext>({
    setIndex: () => { },
    index: undefined,
    openShipmentDialog: false,
    setOpenShipmentDialog: () => { },
    tissueSampleIdLookup: undefined
});

const ShipmentSampleGrid: React.FunctionComponent = () => {
    const classes = useStyles();

    const { tissueSampleIdLookup } = React.useContext(ShipmentContext);

    const shipmentSampleColumns = React.useMemo(() => {
        const columns = [
            {
                name: 'shipmentSampleNumber',
                content: (
                    <InputDisplay />
                )
            },
            {
                name: 'tissueSampleId',
                content: (
                    <FormLookupContext.Provider value={tissueSampleIdLookup}>
                        <Input component={Select} />
                    </FormLookupContext.Provider>
                )
            },
        ];

        return columns as IFormGridCell[];
    }, [tissueSampleIdLookup]);

    return (
        <>
            <Typography
                variant="h2"
                color="primary"
                className={classes.subHeading}
            >
               Shipment Samples
            </Typography>
            <FormGrid
                name="shipmentSamples"
                columns={shipmentSampleColumns}
                type={Dtos.ShipmentSample}
                onAdd={(values) => {
                    const gridSize = values?.length ?? 0;

                    let value = new Dtos.ShipmentSample({ shipmentSampleNumber: (gridSize + 1) });

                    if (values) {
                        return [...values, value];
                    }

                    return [value];

                }}
                onDelete={(values, index) => {
                    if (values === undefined) {
                        return;
                    }

                    if (index >= values?.length) {
                        return;
                    }

                    values = values?.filter((v, i) => index !== i);

                    values.forEach((value, index) => {
                        return value.shipmentSampleNumber = (index + 1);
                    });

                    return values;
                }}
                rowLabel="Shipment Sample"
            />
        </>
    );
}

const TissueSampleShipmentGrid: React.FunctionComponent = () => {
    const classes = useStyles();

    const { getFieldValue, setFieldValue } = useFormActions();

    const [previousValue, setPreviousValue] = React.useState<Dtos.TissueSampleShipment | undefined>(undefined);

    const { index, setIndex, openShipmentDialog, setOpenShipmentDialog, tissueSampleIdLookup } = React.useContext(ShipmentContext);

    React.useEffect(() => {
        const v = getFieldValue(`tissueSampleShipments[${index}]`);

        setPreviousValue(!v ? undefined : cloneDeep(v));
    }, [index, setPreviousValue, getFieldValue]);

    const onRowClick = React.useCallback((index: number) => {
        setPreviousValue(undefined);
        setIndex(index);
        setOpenShipmentDialog(true);
    }, [setIndex, setPreviousValue, setOpenShipmentDialog]);

    const onCancel = React.useCallback(() => {
        setFieldValue(`tissueSampleShipments[${index}]`, previousValue);
        setPreviousValue(undefined);
        setIndex(undefined);
        setOpenShipmentDialog(false);
    }, [previousValue, setFieldValue, index, setIndex, setPreviousValue, setOpenShipmentDialog]);

    const onOk = React.useCallback(() => {
        setOpenShipmentDialog(false);
        setPreviousValue(undefined);
        setIndex(undefined);
    }, [setIndex, setPreviousValue, setOpenShipmentDialog]);

    const [sending, setSending] = React.useState(false);

    const { serviceStackClient: client } = React.useContext(OnlinePatientManagementContext);

    const { patient } = React.useContext(PatientContext);

    const { enqueueSnackbar } = useSnackbar();

    const onSendClick = React.useCallback((shipmentId) => {
        setSending(true);

        client
            .post(new Dtos.SendPathologyRequisitionFormEmail({
                patientId: patient?.id,
                tissueSampleShipmentId: shipmentId
            }))
            .then(response => {
                setSending(false);

                enqueueSnackbar(
                    <>
                        <AlertTitle>
                            Pathology Requisition Form Sent
                        </AlertTitle>
                        The pathology requisition form was successfully sent.
                    </>,
                    { variant: 'success' }
                );
            })
            .catch((e) => {
                setSending(false);

                enqueueSnackbar(
                    <>
                        <AlertTitle>
                            Pathology Requisition Form Not Sent
                        </AlertTitle>
                        Please make sure that the patient is randomised and the samples are finalised.
                    </>,
                    { variant: 'critical' }
                );
            })
    }, [setSending, client, patient, enqueueSnackbar]);

    const tissueSampleShipmentColumns = React.useMemo(() => {
        const columns = [
            {
                name: 'shipmentNumber',
                content: (
                    <InputDisplay />
                )
            },
            {
                name: 'shipmentStatus',
                content: (
                    <InputDisplay
                        convert={(fieldValue: any, lookups) => GetFieldLookupItem(lookups, `tissueSampleShipments.shipmentStatus`, fieldValue)?.value}
                    />
                )
            },
            {
                name: 'trackingId',
                content: (
                    <InputDisplay />
                )
            },
            {
                name: 'dateShipped',
                content: (
                    <InputDisplay
                        convert={(fieldValue: any) => fieldValue ? DateTime.fromISO(fieldValue).toFormat('dd/MM/yyyy') : null}
                    />
                )
            },
            {
                name: 'shipmentSamples',
                header: <Typography>Samples</Typography>,
                content: (
                    <FormLookupContext.Provider value={tissueSampleIdLookup}>
                        <InputDisplay
                            convert={(fieldValue: any, lookups) => <>
                                {
                                    //(fieldValue as Dtos.ShipmentSample[])?.map(sample => GetFieldLookupItem(lookups, `tissueSampleShipments.tissueSampleId`, sample.tissueSampleId)?.value)
                                }
                                {fieldValue?.length ?? 0} sample(s)
                            </>
                            }
                        />
                    </FormLookupContext.Provider>


                )
            },
            //{
            //    name: 'id',
            //    header: 'Requisition Form',
            //    content: (
            //        <InputDisplay
            //            convert={
            //                (fieldValue: any) => <ProgressButton
            //                        size="small"
            //                        variant="contained"
            //                        color="secondary"
            //                        loading={sending}
            //                        onClick={(event) => { event.stopPropagation(); onSendClick(fieldValue); }}
            //                        disabled={fieldValue == null}
            //                    >
            //                        Send
            //                    </ProgressButton>
            //            }
            //        />
            //    )
            //},
        ];

        return columns as IFormGridCell[];

    }, [tissueSampleIdLookup, sending, onSendClick]);

    const { readOnly } = React.useContext(FormOptionsContext);

    return (
        <>
            <Typography
                variant="h2"
                color="primary"
                className={classes.subHeading}
            >
                Shipments
            </Typography>
            <FormGrid
                type={Dtos.TissueSampleShipment}
                name="tissueSampleShipments"
                columns={tissueSampleShipmentColumns}
                onRowClick={onRowClick}
                onAddClick={onRowClick}
                onAdd={(values) => {
                    const gridSize = values?.length ?? 0;

                    let value = new Dtos.TissueSampleShipment({ shipmentNumber: (gridSize + 1) });

                    if (values) {
                        return [...values, value];
                    }

                    return [value];

                }}
                onDelete={(values, index) => {
                    if (values === undefined) {
                        return;
                    }

                    if (index >= values?.length) {
                        return;
                    }

                    values = values?.filter((v, i) => index !== i);

                    values.forEach((value, index) => {
                        return value.shipmentNumber = (index + 1);
                    });

                    return values;
                }}
                rowLabel="Shipment"
                getRowValue={(getFieldValue: (path: string) => string, lookups, parentName: string, index?: number) => {
                    if (parentName && index !== undefined && lookups) {
                        var rowValue = getFieldValue(`${parentName}[${index}].shipmentNumber`);

                        return `Shipment Number ${rowValue}`;
                    }

                    return undefined;
                }}
            />

            <Dialog
                open={openShipmentDialog && index !== null}
                onClose={() => setOpenShipmentDialog(false)}
                aria-labelledby="form-dialog"
                maxWidth="md"
                scroll="body"
                fullWidth
                disableBackdropClick
                disableEscapeKeyDown
            >
                <DialogTitle className={classes.dialogFormTitle}>Shipment</DialogTitle>
                <DialogContent style={{ padding: '0' }}>
                    <FieldProvider name={`tissueSampleShipments[${index}]`}>
                        <Field
                            name="shipmentNumber"
                            component={Numeric}
                            disabled
                        />
                        <Field
                            name="shipmentStatus"
                            component={Select}
                        />
                        <Field
                            name="trackingId"
                            component={Text}
                        />
                        <Field
                            name="dateShipped"
                            component={KeyboardDatePicker}
                        />
                        <ShipmentSampleGrid />
                        <div className={classes.dialogButton}>
                            <Button
                                variant="text"
                                type="button"
                                color="secondary"
                                onClick={onCancel}
                            >
                                {!readOnly ? 'Cancel' : 'Back'}
                            </Button>
                            {
                                !readOnly && (
                                    <Button
                                        variant="text"
                                        type="button"
                                        color="primary"
                                        onClick={onOk}
                                    >
                                        Ok
                                    </Button>
                                )
                            }
                        </div>
                    </FieldProvider>
                </DialogContent>
            </Dialog>
        </>
    );
}

const TissueSampleTracking: React.FunctionComponent<ITissueSampleTrackingProps> = () => {
    const { masterGroup } = React.useContext(MasterGroupContext);
    const { collaboratingGroup } = React.useContext(CollaboratingGroupContext);
    const { institution } = React.useContext(InstitutionContext);
    const { patient } = React.useContext(PatientContext);

    const [[canUpdateCrf], permissionLoadState] = usePermissionsByIds(permissions, masterGroup?.id, collaboratingGroup?.id, institution?.id, patient?.id, true, false);

    const [index, setIndex] = React.useState<number | undefined>(undefined);

    const [openShipmentDialog, setOpenShipmentDialog] = React.useState<boolean>(false);

    const [tissueSampleSubmission, setTissueSampleSubmission] = React.useState<Dtos.TissueSampleSubmission | undefined>(undefined);

    const { serviceStackClient: client } = React.useContext(OnlinePatientManagementContext);

    React.useEffect(() => {
        client
            .get(
                new Dtos.TissueSampleSubmissionGetSingleByIds({
                    patientId: patient?.id,
                    eventDefinitionId: Dtos.EventDefinitionType.TissueSample,
                    eventRepeat: 1,
                    formRepeat: 1,
                    institutionId: institution?.id
                })
            )
            .then(response => {
                setTissueSampleSubmission(response.form);
            })
            .catch((e) => {
                setTissueSampleSubmission(undefined);
            })
    }, [setTissueSampleSubmission, client, patient, institution]);

    const tissueSampleIdLookup = React.useMemo(() => {
        var lookupItems = tissueSampleSubmission?.tissueSamples?.filter(sample => (sample.tumourSampleId ?? '') !== '')?.map(sample => new Dtos.LookupItem({
            id: sample.id,
            value: sample.tumourSampleId,
            order: sample.sampleNumber,
            type: sample.id
        })) ?? [];

        return new Dtos.Lookup({
            propertyName: 'tissueSampleShipments.tissueSampleId',
            items: lookupItems
        })
    }, [tissueSampleSubmission]);

    const shipmentContext: IShipmentContext = React.useMemo(() => {
        return {
            index,
            setIndex: (i) => setIndex(i),
            openShipmentDialog: openShipmentDialog,
            setOpenShipmentDialog: (open) => setOpenShipmentDialog(open),
            tissueSampleIdLookup: tissueSampleIdLookup
        }
    }, [index, setIndex, openShipmentDialog, setOpenShipmentDialog, tissueSampleIdLookup]);

    if (permissionLoadState.state === RequestState.None || permissionLoadState.state === RequestState.Pending) {
        return (
            <RouteLoading />
        );
    }

    return (
        <>
            <FormBreadcrumbs />
            {
                //<CrfForm
                //    formType={Dtos.TissueSampleTracking}
                //    validateOn="onChange"
                //    canEdit={canUpdateCrf}
                //>
                //    <ShipmentContext.Provider value={shipmentContext}>
                //        <TissueSampleShipmentGrid />
                //    </ShipmentContext.Provider>
                //</CrfForm>
            }
        </>
    );
}


/*
 * ----------------------------------------------------------------------------------
 * Default Export
 * ----------------------------------------------------------------------------------
 */

export default TissueSampleTracking;
