/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * This file contains the component that provides context for the online patient
 * management system.
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */

/**
 * Required to use React components.
 */
import * as React from 'react';

/*
 * Used to style components
 */
import { useTheme, useMediaQuery } from '@material-ui/core';

/**
 * Used to display icons
 */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons/faInfoCircle';

/**
 * Used for the basic page layout.
 */
import {
    Field,
    ToggleButtonGroup,
    RadioGroup,
    FieldGroup,
    ProForm,
    useAuthenticatedUser,
    UserType,
    MasterGroupContext,
    CollaboratingGroupContext,
    InstitutionContext,
    PatientContext,
    RouteLoading,
    FormBreadcrumbs,
    FormContext,
    KeyboardDatePicker,
    Checkbox
} from '@ngt/opms';

import { usePermissionsByIds, Permission } from '@ngt/opms-bctapi';

import { RequestState } from '@ngt/request-utilities';

/*
 * ----------------------------------------------------------------------------------
 * Imports - Internal
 * ----------------------------------------------------------------------------------
 */

/*
 * Used to type patient state.
 */
import * as Dtos from '../../api/dtos';

import Alert from '@material-ui/lab/Alert';
import { useHistory } from 'react-router-dom';
import Footer from './Footer';
import { DefaultFooterPosition } from '../../constants/proForms';
import { useDefaultStepsProps } from '../../hooks/useDefaultStepsProps';
import SourceDocuments from '../../components/SourceDocuments';

/*
 * ----------------------------------------------------------------------------------
 * Interface
 * ----------------------------------------------------------------------------------
 */

interface IEq5dProps {
}


/*
 * ----------------------------------------------------------------------------------
 * Components
 * ----------------------------------------------------------------------------------
 */

const permissions: Permission[] = [
    Permission.OpmsProUpdate,
    Permission.OpmsProAdminister
];


const Eq5d: React.FunctionComponent<IEq5dProps> = () => {
    const theme = useTheme();

    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const isExtraSmall = useMediaQuery(theme.breakpoints.down('xs'));

    const [user] = useAuthenticatedUser();

    const isPatient = React.useMemo(() => {
        return user?.type === UserType.Patient;
    }, [user]);

    const showSteps = isPatient || isMobile;

    const { masterGroup } = React.useContext(MasterGroupContext);
    const { collaboratingGroup } = React.useContext(CollaboratingGroupContext);
    const { institution } = React.useContext(InstitutionContext);
    const { patient } = React.useContext(PatientContext);
    const defaultStepsProps = useDefaultStepsProps();

    const [[canUpdatePro, canAdministerPro], permissionLoadState] = usePermissionsByIds(permissions, masterGroup?.id, collaboratingGroup?.id, institution?.id, patient?.id, true);

    const history = useHistory();
    const { form } = React.useContext(FormContext);

    if (form && (form as Dtos.IProForm).completed && isPatient) {
        history.push(`/`);
    }

    if (permissionLoadState.state === RequestState.None || permissionLoadState.state === RequestState.Pending) {
        return (
            <RouteLoading />
        );
    }

    console.log(canUpdatePro);
    console.log(canAdministerPro);

    const canEdit = canUpdatePro || canAdministerPro;

    return (
        <>
            {
                !isPatient && (
                    <FormBreadcrumbs />
                )
            }
            <ProForm
                formType={Dtos.Eq5d}
                validateOn="onChange"
                useSteps={showSteps}
                footer={<Footer showEq5dBlurb={true} isPatient={isPatient} />}
                footerPosition={DefaultFooterPosition}
                stepsProps={defaultStepsProps}
                canEdit={canEdit}
                canAdministerProForm={canAdministerPro}
                showEventInHeader={true}
            >
                {
                    !isPatient && (
                        <Field
                            hideLabel
                            name="ptDidNotComplete"
                            component={Checkbox}
                            xs={12}
                            sm={12}
                            md={showSteps ? 12 : 6}
                            lg={showSteps ? 12 : 5}
                            xl={showSteps ? 12 : 5}
                        />
                    )
                }
                {
                    showSteps ?
                        <FieldGroup>
                            <Alert severity="info" icon={<FontAwesomeIcon fixedWidth icon={faInfoCircle} />} >
                                Please select the option that best describes your health TODAY.
                            </Alert>
                            {
                                isExtraSmall ?
                                    <Field
                                        name="mobilityId"
                                        component={ToggleButtonGroup}
                                        orientation="vertical"
                                        spacing={3}
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={12}
                                        xl={12}
                                    /> :
                                    <Field
                                        name="mobilityId"
                                        component={RadioGroup}
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={12}
                                        xl={12}
                                    />
                            }
                        </FieldGroup> :
                        <Field
                            name="mobilityId"
                            component={RadioGroup}
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                        />
                }

                {
                    showSteps ?
                        <FieldGroup>
                            <Alert severity="info" icon={<FontAwesomeIcon fixedWidth icon={faInfoCircle} />} >
                                Please select the option that best describes your health TODAY.
                            </Alert>
                            {
                                isExtraSmall ?
                                    <Field
                                        name="selfCareId"
                                        component={ToggleButtonGroup}
                                        orientation="vertical"
                                        spacing={3}
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={12}
                                        xl={12}
                                    /> :
                                    <Field
                                        name="selfCareId"
                                        component={RadioGroup}
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={12}
                                        xl={12}
                                    />
                            }
                        </FieldGroup> :
                        <Field
                            name="selfCareId"
                            component={RadioGroup}
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                        />
                }

                {
                    showSteps ?
                        <FieldGroup>
                            <Alert severity="info" icon={<FontAwesomeIcon fixedWidth icon={faInfoCircle} />} >
                                Please select the option that best describes your health TODAY.
                            </Alert>
                            {
                                isExtraSmall ?
                                    <Field
                                        name="usualActivitiesId"
                                        component={ToggleButtonGroup}
                                        orientation="vertical"
                                        spacing={3}
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={12}
                                        xl={12}
                                    /> :
                                    <Field
                                        name="usualActivitiesId"
                                        component={RadioGroup}
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={12}
                                        xl={12}
                                    />
                            }
                        </FieldGroup> :
                        <Field
                            name="usualActivitiesId"
                            component={RadioGroup}
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                        />
                }

                {
                    showSteps ?
                        <FieldGroup>
                            <Alert severity="info" icon={<FontAwesomeIcon fixedWidth icon={faInfoCircle} />} >
                                Please select the option that best describes your health TODAY.
                            </Alert>
                            {
                                isExtraSmall ?
                                    <Field
                                        name="painAndDiscomfortId"
                                        component={ToggleButtonGroup}
                                        orientation="vertical"
                                        spacing={3}
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={12}
                                        xl={12}
                                    /> :
                                    <Field
                                        name="painAndDiscomfortId"
                                        component={RadioGroup}
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={12}
                                        xl={12}
                                    />
                            }
                        </FieldGroup> :
                        <Field
                            name="painAndDiscomfortId"
                            component={RadioGroup}
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                        />
                }

                {
                    showSteps ?
                        <FieldGroup>
                            <Alert severity="info" icon={<FontAwesomeIcon fixedWidth icon={faInfoCircle} />} >
                                Please select the option that best describes your health TODAY.
                            </Alert>
                            {
                                isExtraSmall ?
                                    <Field
                                        name="anxietyAndDepressionId"
                                        component={ToggleButtonGroup}
                                        orientation="vertical"
                                        spacing={3}
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={12}
                                        xl={12}
                                    /> :
                                    <Field
                                        name="anxietyAndDepressionId"
                                        component={RadioGroup}
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={12}
                                        xl={12}
                                    />
                            }
                        </FieldGroup> :
                        <Field
                            name="anxietyAndDepressionId"
                            component={RadioGroup}
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                        />
                }
                {
                    !isPatient && (
                        <FieldGroup>
                            <Field
                                name="dateCompleted"
                                component={KeyboardDatePicker}
                                xs={12}
                                sm={12}
                                md={showSteps ? 12 : 6}
                                lg={showSteps ? 12 : 5}
                                xl={showSteps ? 12 : 5}
                            />
                        </FieldGroup>
                    )
                }
                {
                    canAdministerPro && (
                        <SourceDocuments />
                    )
                }
            </ProForm>
        </>
    );
}


/*
 * ----------------------------------------------------------------------------------
 * Default Export
 * ----------------------------------------------------------------------------------
 */

export default Eq5d;
