/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * This file contains the component that provides context for the online patient
 * management system.
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */

/**
 * Required to use React components.
 */
import { Button, CssBaseline, Icon, MenuItem, Paper, Select, Typography } from '@material-ui/core';
import * as React from 'react';
import { FC, useCallback, useMemo, useRef, useState } from 'react';

import { Document, Page, pdfjs } from 'react-pdf';
import { DocumentCallback, PageCallback } from 'react-pdf/dist/cjs/shared/types';
import { useLocation, useParams } from "react-router-dom"
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import { grey } from '@material-ui/core/colors';
import { validate } from 'uuid';
import { Waypoint } from 'react-waypoint';
import { faChevronDown } from '@fortawesome/pro-duotone-svg-icons';
import { faExpandAlt } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

type PDFFile = string | File | null;



interface IPdfViewer {
    url?: string;
    file?: File;
}


const PdfViewer: FC<IPdfViewer> = ({ url, file }) => {

    // const params = new URLSearchParams(window.location.pathname);
    // const id = params.get("docid");

    const location = useLocation()
    const id = new URLSearchParams(location.search)

    //const [file, setFile] = useState<PDFFile>(`/opms/file/download/${id.get("docId")}`);
    
    const [numPages, setNumPages] = useState(0);
    const [pages, setPages] = useState<number[]>([]);

    const onDocumentLoadSuccess = useCallback(({ numPages }: DocumentCallback): void => {
        setNumPages(numPages);

        setPages(Array.from({length: numPages}, (_, i) => i + 1))

    }, [setNumPages])

    const [pageWidths, setPageWidths] = useState<Record<number, number>>({})

    const onPageLoadSuccess = useCallback(({ width, height, originalHeight, originalWidth, pageNumber }: PageCallback): void => {
        setPageWidths(c => {
            return {
                ...c,
                [pageNumber]: originalWidth
            }
        })
    }, [setPageWidths])

    const [page, setPage] = useState(1)
    const [pageFit, setPageFit] = useState(false);
    const [scale, setScale] = React.useState(1.0);
    const containerRef = useRef<HTMLDivElement>(null);

    
    const scales = useMemo(() => {
        const s = new Set([
            0.5,
            1,
            1.5,
            2,
            2.5,
            3
        ])

        s.add(scale);

        return Array.from(s).sort((a,b) => {
            return a < b ? -1 : 1;
        })
    }, [scale])

    console.log('NUMBER OF PAGES:', numPages)

    return (
        <div 
            style={{
                display: 'flex',
                width: '100%',
                flexDirection: 'column',
                alignItems: 'stretch',
                height: '100vh'
            }}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                    height: 64,
                    minHeight: 64,
                    maxHeight: 64,
                    color: '#FFFFFF',
                    background: '#38383d',
                    flex: '0 0 auto'
                }}
            >
                <div
                    style={{
                        display: "flex",
                        flex: '1 1 auto',
                        alignItems: "center",
                        marginLeft: "8px"
                    }}
                >
                    <Typography>
                        Page {page} of {numPages}
                    </Typography>
                </div>

                <Button
                    style={{
                        color: '#FFFFFF',
                        fontSize: 30
                    }}
                    disabled={scale === 0.1}
                    onClick={() => {
                        setPageFit(false);
                        setScale((s) => {
                            const value: number = +(s-(Math.ceil(s) / 10)).toFixed(2);

                            if (value < 0.1) {
                                return 0.1;
                            }
                            return value;
                        })
                    }}
                >
                    -
                </Button>

                <Button
                    style={{
                        color: '#FFFFFF',
                        fontSize: 20
                    }}
                    disabled={scale === 10}
                    onClick={() => {
                        setPageFit(false);
                        setScale((s) => {
                            const value: number = +(s+(Math.ceil(s) / 10)).toFixed(2);

                            if (value > 10) {
                                return 10;
                            }
                            return value;
                        })
                    }}
                >
                    +
                </Button>

                <Select
                    style={{
                        flex: '0 0 auto',
                        color: '#FFFFFF',
                        marginRight: '8px'
                    }}
                    IconComponent={() => (
                        <FontAwesomeIcon
                            icon={faChevronDown}
                        />
                    )}
                    value={pageFit ? "Fit" : scale.toString()}
                    label="Scale" 
                    onChange={(e, value) => {
                        const v = e.target.value as string;
                        if (v === "Fit")
                        {
                            const pageFitWidth = (containerRef.current?.offsetWidth ?? 850) - 48;
                            const originalWidth = pageWidths[page] ?? 850;


                            setPageFit(true);
                            setScale(+((pageFitWidth / originalWidth) * 10).toFixed(0) / 10)
                        }
                        else
                        {
                            setPageFit(false);
                            setScale(+(e.target.value as any))
                        }
                    }}
                >
                    <MenuItem value="Fit">Fit To Page</MenuItem>
                    {
                        scales.map((s) => {
                            return (
                                <MenuItem value={s.toString()}>{(s*100).toFixed(0)}%</MenuItem>
                            );

                        })
                    }
                </Select>


            </div>


            <div 
                style={{
                    background: '#2a2a2e',
                    overflow: 'auto',
                    flex: '1 1 auto',
                            
                    scrollbarColor: '#88888b #38383d'
                }}
                ref={containerRef}
            >
                <Document
                    file={file ?? url}
                    onLoadSuccess={onDocumentLoadSuccess}
                    onLoadError={(error) => alert('Error while retrieving the outline! ' + error.message)}
                >
                    <div
                        style={{
                            display: 'flex',
                            width: '100%',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        {pages.map(x => (
                            <Waypoint
                                key={x}
                                topOffset={"49%"}
                                bottomOffset={"49%"}
                                onEnter={({ previousPosition, currentPosition, event }) => {
                                    if (event != null) // on build all waypoints are hit with a null event... no sir sit down
                                    {
                                        setPage(x);
                                    }
                                }}
                            >
                                <Paper
                                    key={x}
                                    style={{marginBottom: 8, marginTop: 8, overflow: 'hidden'}}
                                >
                                    <Page
                                        pageNumber={x}
                                        onLoadSuccess={onPageLoadSuccess}
                                        scale={scale}
                                    />
                                </Paper>
                            </ Waypoint>
                        ))
                        }
                    </div>
                </Document>
            </div>
            
        </div>
        
    );
}

interface IDocumentPdfViewer {
    documentId: number;
}

export const DocumentPdfViewer: FC<IDocumentPdfViewer> = ({ documentId }) => {
    return (
        <PdfViewer url={`/opms/file/download/${documentId}`} />
    )
}

export const DocumentPdfPage: FC = () => {
    const {documentId} = useParams<{documentId: string}>();

    return (
        <>
            <CssBaseline />
            <DocumentPdfViewer documentId={+documentId} />
        </>
    )
}

/*
 * ----------------------------------------------------------------------------------
 * Default Export
 * ----------------------------------------------------------------------------------
 */

export default PdfViewer;
