import 'core-js';

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';

import { enableES5 } from 'immer';

import { OnlinePatientManagementProvider } from '@ngt/opms';
import onlinePatientManagement from './onlinePatientManagement';
import { Switch, Route } from 'react-router-dom';
import App from './App';
import EConsentApp from './EConsentApp';
import { DocumentPdfPage } from './containers/PdfViewer';


enableES5();

declare let global: any;

if (typeof global === 'undefined') {
    (window as any).global = window;
}

ReactDOM.render(
    <OnlinePatientManagementProvider value={onlinePatientManagement}>
        <Switch>
            <Route path="/public">
                <EConsentApp />
            </Route>
            <Route
                path={[
                    '/document/:documentId',
                    '/document',
                ]}
            >
                <DocumentPdfPage />
            </Route>
            <Route path="/">
                <App />
            </Route>
        </Switch>
    </OnlinePatientManagementProvider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
