/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * This file contains the component that provides context for the online patient
 * management system.
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */

/**
 * Required to use React components.
 */
import * as React from 'react';


/**
 * Used for the basic page layout.
 */
import {
    Checkbox, CollaboratingGroupContext, CrfCondition, CrfForm,
    Field, FieldGroup, FieldProvider, FormBreadcrumbs, FormGrid, FormOptionsContext, GetFieldLookupItem, ICrfConditionParameters, IFormGridCell, IFormState, InstitutionContext, KeyboardDatePicker, MasterGroupContext, Numeric, PatientContext,
    PatientInformation,
    RouteLoading, Select, SubmitButton, Text, TextArea, useFormActions,
    useFormState,
    useSnackbar,
    
} from '@ngt/opms';

import { Permission, usePermissionsByIds } from '@ngt/opms-bctapi';

import { RequestState } from '@ngt/request-utilities';

import { Button, Dialog, DialogContent, DialogTitle, makeStyles, Typography } from '@material-ui/core';

import { cloneDeep, get } from 'lodash-es';


/*
 * ----------------------------------------------------------------------------------
 * Imports - Internal
 * ----------------------------------------------------------------------------------
 */

/*
 * Used to type patient state.
 */
import { DateTime } from 'luxon';
import * as Dtos from '../../api/dtos';
import InputDisplay from '../../components/InputDisplay';
import SourceDocuments from '../../components/SourceDocuments';
import { Alert, AlertTitle } from '@material-ui/lab';
import parseAssayResultPdfError from '../../utils/AssayResultPdfErrorParser';
import { IOptimaError, isIOptimaError } from '../../utils/error';
import { useHistory } from 'react-router-dom';
import { RemoveDialog } from './TissueSampleSubmission';
import { patientCaption, patientStateCaption } from '../../utils/patientInformation';

/*
 * ----------------------------------------------------------------------------------
 * Interface
 * ----------------------------------------------------------------------------------
 */

interface IProsignaAssayResultProps {
}

interface IProsignaAssayResultContext {
    index: number | undefined;
    setIndex: (index: number | undefined) => void;
    openResultDialog: boolean;
    setOpenResultDialog: (open: boolean) => void;
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles(theme => ({
    container: {
        padding: theme.spacing(3)
    },
    subHeading: {
        padding: theme.spacing(3, 3, 0, 2)
    },
    dialogFormTitle: {
        color: theme.palette.secondary.main,
        borderBottom: 'solid rgb(224, 224, 224) 1px',

        '& *': {
            fontSize: '1.5rem !important'
        }
    },
    dialogButton: {
        textAlign: 'right',
        padding: theme.spacing(2),

        '& > :first-child': {
            marginRight: theme.spacing(1),
        }
    },
    buttonGroup: {
        padding: theme.spacing(0, 3, 3),
        textAlign: 'center',

        '& > *': {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1)
        },

        [theme.breakpoints.up('sm')]: {
            textAlign: 'right',
            '& > *': {
                marginLeft: theme.spacing(1),
                marginRight: theme.spacing(0)
            }
        }
    }
}));

/*
 * ---------------------------------------------------------------------------------
 * Functions
 * ---------------------------------------------------------------------------------
 */

const isNewExpectedDateSpecified = ({ lookups, formState, parentFieldName }: ICrfConditionParameters<Dtos.ProsignaAssayResult>) => {
    if (!parentFieldName) {
        return false;
    }

    const row: Dtos.ProsignaResult = get(formState?.values, parentFieldName);

    if (!row) {
        return false;
    }

    return row.specifyNewExpectedDate === true;
};

const isTumourSampleNotSuitable = ({ lookups, formState, parentFieldName }: ICrfConditionParameters<Dtos.ProsignaAssayResult>) => {
    if (!parentFieldName) {
        return false;
    }

    const row: Dtos.ProsignaResult = get(formState?.values, parentFieldName);

    if (!row) {
        return false;
    }

    return row.tumourSampleSuitable === Dtos.LookupYesNoType.No;
};

const isTumourSampleNotSuitableReasonOther = ({ lookups, formState, parentFieldName }: ICrfConditionParameters<Dtos.ProsignaAssayResult>) => {
    if (!parentFieldName) {
        return false;
    }

    const row: Dtos.ProsignaResult = get(formState?.values, parentFieldName);

    if (!row) {
        return false;
    }

    return row.tumourSampleNotSuitableReason === Dtos.LookupTumourSampleNotSuitableReasonType.Other;
};

const isTumourSampleSuitable = ({ lookups, formState, parentFieldName }: ICrfConditionParameters<Dtos.ProsignaAssayResult>) => {
    if (!parentFieldName) {
        return false;
    }

    const row: Dtos.ProsignaResult = get(formState?.values, parentFieldName);

    if (!row) {
        return false;
    }

    return row.tumourSampleSuitable === Dtos.LookupYesNoType.Yes;
};

const isProsignaNotSuccessful = ({ lookups, formState, parentFieldName }: ICrfConditionParameters<Dtos.ProsignaAssayResult>) => {
    if (!parentFieldName) {
        return false;
    }

    const row: Dtos.ProsignaResult = get(formState?.values, parentFieldName);

    if (!row) {
        return false;
    }

    return row.prosignaAssaySuccessful === Dtos.LookupYesNoType.No;
};

const isProsignaSuccessful = ({ lookups, formState, parentFieldName }: ICrfConditionParameters<Dtos.ProsignaAssayResult>) => {
    if (!parentFieldName) {
        return false;
    }

    const row: Dtos.ProsignaResult = get(formState?.values, parentFieldName);

    if (!row) {
        return false;
    }

    return row.prosignaAssaySuccessful === Dtos.LookupYesNoType.Yes;
};

const isSampleSuitableForBanking = ({ lookups, formState, parentFieldName }: ICrfConditionParameters<Dtos.ProsignaAssayResult>) => {
    if (!parentFieldName) {
        return false;
    }

    const row: Dtos.ProsignaResult = get(formState?.values, parentFieldName);

    if (!row) {
        return false;
    }

    return row.suitableForBankingAndFutureResearch === Dtos.LookupYesNoType.Yes;
};


/*
 * ----------------------------------------------------------------------------------
 * Components
 * ----------------------------------------------------------------------------------
 */

const permissions: Permission[] = [
    Permission.OpmsAdminister
];

const resultsColumn: Array<IFormGridCell<Dtos.ProsignaResult>> = [
    {
        name: 'tissueSample.tumourSampleId',
        header: <Typography>Tumour Sample ID</Typography>,
        minWidth: 110,
        maxWidth: 110,
        width: 110,
        content: (
            <InputDisplay convert={(value, lookups) => !value ? 'No associated sample found' : value } />
        )
    },
    {
        name: 'centralLabSampleId',
        hidden: true
    },
    {
        name: 'dateLabReceived',
        header: <Typography>Date Lab Received</Typography>,
        minWidth: 110,
        maxWidth: 110,
        width: 110,
        content: (
            <InputDisplay
                convert={(fieldValue: any) => fieldValue ? DateTime.fromISO(fieldValue).toFormat('dd/MM/yyyy') : null}
            />
        )
    },
    {
        name: 'dateResultExpected',
        hidden: true
    },
    {
        name: 'specifyNewExpectedDate',
        hidden: true
    },
    {
        name: 'newExpectedDateReason',
        hidden: true
    },
    {
        name: 'newExpectedDate',
        hidden: true
    },
    {
        name: 'numberOfHAndEAssessments',
        hidden: true
    },
    {
        name: 'numberOfRnaExtractions',
        hidden: true
    },
    {
        name: 'tumourSampleSuitable',
        header: <Typography>Tumour sample suitable for prosigna assay</Typography>,
        minWidth: 110,
        maxWidth: 110,
        width: 110,
        content: (
            <InputDisplay
                convert={(fieldValue: any, lookups) => GetFieldLookupItem(lookups, `results.tumourSampleSuitable`, fieldValue)?.value}
            />
        )
    },
    {
        name: 'numberOfProsignaAssays',
        hidden: true
    },
    {
        name: 'prosignaAssaySuccessful',
        header: <Typography>Prosigna assay successful and result interpretable</Typography>,
        minWidth: 110,
        maxWidth: 110,
        width: 110,
        content: (
            <InputDisplay
                convert={(fieldValue: any, lookups) => GetFieldLookupItem(lookups, `results.prosignaAssaySuccessful`, fieldValue)?.value}
            />
        )
    },
    {
        name: 'dateProsignaCompleted',
        header: <Typography>Date prosigna completed</Typography>,
        minWidth: 110,
        maxWidth: 110,
        width: 110,
        content: (
            <InputDisplay
                convert={(fieldValue: any) => fieldValue ? DateTime.fromISO(fieldValue).toFormat('dd/MM/yyyy') : null}
            />
        )
    },
    {
        name: 'prosignaAssayResultsSubtype',
        header: <Typography>Subtype</Typography>,
        minWidth: 110,
        maxWidth: 110,
        width: 110,
        content: (
            <InputDisplay
                convert={(fieldValue: any, lookups) => GetFieldLookupItem(lookups, `results.prosignaAssayResultsSubtype`, fieldValue)?.value}
            />
        )
    },
    {
        name: 'prosignaAssayResultsRorScore',
        header: <Typography>ROR Score</Typography>,
        minWidth: 110,
        maxWidth: 110,
        width: 110,
        content: (
            <InputDisplay />
        )
    },
    {
        name: 'prosignaAssayResultsRiskCategory',
        header: <Typography>Risk Category</Typography>,
        minWidth: 110,
        maxWidth: 110,
        width: 110,
        content: (
            <InputDisplay
                convert={(fieldValue: any, lookups) => GetFieldLookupItem(lookups, `results.prosignaAssayResultsRiskCategory`, fieldValue)?.value}
            />
        )
    },
    {
        name: 'suitableForBankingAndFutureResearch',
        hidden: true
    },
    {
        name: 'numberOfRemainingHAndEAssessments',
        hidden: true
    },
    {
        name: 'numberOfRemainingRnaExtractions',
        hidden: true
    },
    {
        name: 'sourceDocuments',
        hidden: true
    },
    //{
    //    name: 'status',
    //    header: <Typography>Status</Typography>,
    //    minWidth: 110,
    //    maxWidth: 110,
    //    width: 110,
    //    content: (
    //        <InputDisplay
    //            convert={(fieldValue: any, lookups) => GetFieldLookupItem(lookups, `results.status`, fieldValue)?.value}
    //        />
    //    )
    //},
];

const ProsignaAssayResultContext = React.createContext<IProsignaAssayResultContext>({
    setIndex: () => { },
    index: undefined,
    openResultDialog: false,
    setOpenResultDialog: () => { }
});

//const ProsignaAssayConfirmationDialog: React.FunctionComponent = () => {
//    const classes = useStyles();

//    const [dialogOpen, setDialogOpen] = React.useState<boolean>(false);

//    const { value, name } = useFieldState({ value: true });

//    const { patient } = React.useContext(PatientContext);

//    const { getFieldValue } = useFormActions();

//    const parentName = getParentPropertyPath(name) ?? '';

//    const resultId = getFieldValue(`${parentName}.id`);

//    const onlinePatientManagement = React.useContext(OnlinePatientManagementContext);

//    const client = onlinePatientManagement.serviceStackClient;

//    const { enqueueSnackbar } = useSnackbar();

//    const onConfirmClick = React.useCallback(() => {

//        client
//            .post(new Dtos.ConfirmProsignaResult({
//                prosignaResultId: resultId
//            }))
//            .then(async response => {
//                try {

//                    enqueueSnackbar(
//                        <>
//                            <AlertTitle>
//                                Prosigna Assay Result Confirmed
//                            </AlertTitle>
//                            The prosigna assay result event was successfully confirmed.
//                        </>,
//                        { variant: 'success' }
//                    );

//                    setDialogOpen(false);
//                }
//                catch (error) {
//                    enqueueSnackbar(
//                        <>
//                            <AlertTitle>
//                                Prosigna Assay Result Not Confirmed
//                            </AlertTitle>
//                            An error occurred while attempting to confirm the prosigna assay result.
//                        </>,
//                        { variant: 'critical' }
//                    );
//                }
//            })
//            .catch((e) => {
//                enqueueSnackbar(
//                    <>
//                        <AlertTitle>
//                            Patient Notify Email Not Sent
//                        </AlertTitle>
//                        An error occurred while attempting to send the patient's notification email.
//                    </>,
//                    { variant: 'critical' }
//                );
//            })

//    }, [resultId, client, enqueueSnackbar, setDialogOpen]); 

//    return (
//        <>
//            <Link color="secondary" onClick={(e) => { e.stopPropagation(); setDialogOpen(true); }}>Ready for verification</Link>

//            <Dialog
//                open={dialogOpen}
//                onClose={() => setDialogOpen(false)}
//                onClick={(e => e.stopPropagation())}
//                aria-labelledby="confirm-dialog"
//                maxWidth="sm"
//                scroll="body"
//                fullWidth
//                disableBackdropClick
//                disableEscapeKeyDown
//            >
//                <DialogTitle className={classes.dialogFormTitle}>Confirm Prosigna Assay (PAM 50) Result</DialogTitle>
//                <DialogContent>
//                    <Typography>
//                        Confirm that the subtype and ROR score of tumour block <strong>{value}</strong> for patient <strong>{patient?.studyNumber}</strong> matches the uploaded IUO Report? {resultId}
//                    </Typography>
//                </DialogContent>
//                <DialogActions>
//                    <Button
//                        variant="text"
//                        type="button"
//                        color="secondary"
//                        onClick={(e) => { e.stopPropagation(); setDialogOpen(false); }}
//                    >
//                        Cancel
//                    </Button>
//                    <SubmitButton color="primary" onClick={onConfirmClick} disabled={false}>
//                        Confirm Result
//                    </SubmitButton>
//                </DialogActions>
//            </Dialog>
//        </>
//    );
//};



const ProsignaAssayResultGrid: React.FunctionComponent = () => {
    const classes = useStyles();

    const { getFieldValue, setFieldValue, submit } = useFormActions();

    const { errors } = useFormState();

    const [previousValue, setPreviousValue] = React.useState<Dtos.ProsignaResult | undefined>(undefined);

    const { index, setIndex, openResultDialog, setOpenResultDialog } = React.useContext(ProsignaAssayResultContext);

    React.useEffect(() => {
        const v = getFieldValue(`results[${index}]`);

        setPreviousValue(!v ? undefined : cloneDeep(v));
    }, [index, setPreviousValue, getFieldValue]);

    const { values: formValues } = useFormState<Dtos.ProsignaAssayResult, any>({ values: true });

    React.useEffect(() => {
        if (index != null) {
            const dateReceived = cloneDeep(formValues?.results[index]?.dateLabReceived);

            if (dateReceived) {
                const dateExpected = DateTime.fromISO(dateReceived).plus({ days: 14 })?.toISO({ includeOffset: false });

                setFieldValue(`results[${index}].dateResultExpected`, dateExpected);
            }
        }
    }, [formValues, index, setFieldValue]);

    const onRowClick = React.useCallback((index: number) => {
        setPreviousValue(undefined);
        setIndex(index);
        setOpenResultDialog(true);
    }, [setIndex, setPreviousValue, setOpenResultDialog]);

    const onCancel = React.useCallback(() => {
        if (previousValue?.id == null) {
            let results = getFieldValue(`results`) as Dtos.ProsignaResult[];

            results.splice(index!!, 1);

            setFieldValue(`results`, results);
        }
        else {
            setFieldValue(`results[${index}]`, previousValue);
        }

        setPreviousValue(undefined);
        setIndex(undefined);
        setOpenResultDialog(false);
    }, [previousValue, getFieldValue, setFieldValue, index, setIndex, setPreviousValue, setOpenResultDialog]);

    const onSave = React.useCallback(() => {
        setFieldValue('submitType', 'save');

        submit()
            .then(response => {
                var criticalError = false;

                Object.keys(errors).forEach(key => {
                    if (!criticalError) {
                        criticalError = errors[key].find((e: { type: Dtos.ValidationErrorType; }) => e.type > Dtos.ValidationErrorType.Normal) != null
                    }
                });

                if (!criticalError) {
                    setOpenResultDialog(false);
                    setPreviousValue(undefined);
                    setIndex(undefined);
                }
            })
            .catch((e) => {

            });
    }, [errors, setIndex, setFieldValue, setPreviousValue, setOpenResultDialog, submit]);

    const { readOnly } = React.useContext(FormOptionsContext);

    //const columns = React.useMemo(() => {
    //    return [
    //        ...resultsColumn,
    //        {
    //            name: 'tumourSampleId',
    //            header: <Typography>Status</Typography>,
    //            minWidth: 110,
    //            maxWidth: 110,
    //            width: 110,
    //            content: (
    //                <ProsignaAssayConfirmationDialog />
    //            )
    //        }
    //    ] as Array<IFormGridCell<Dtos.ProsignaResult>>
    //}, [resultsColumn]);

    //const onAddClick = React.useCallback(() => {
    //    let results = getFieldValue<Dtos.TissueSample[]>(`results`);

    //    if (results) {
    //        setFieldValue(`results`, [...results, new Dtos.ProsignaResult({})]);
    //    } else {
    //        setFieldValue(`results`, [new Dtos.ProsignaResult({})]);
    //    }

    //    if (onRowClick) {
    //        onRowClick(results?.length ?? 0);
    //    }

    //}, [onRowClick, getFieldValue, setFieldValue]);

    const history = useHistory();
    const { institution } = React.useContext(InstitutionContext);
    const { patient } = React.useContext(PatientContext);

    const trialArmText = React.useMemo(() => {
        const typedPatient = patient as Dtos.Patient;

        if (typedPatient.trialArm === Dtos.TrialArm.Control) {
            return 'Group 1 - Control';
        }
        else if (typedPatient.trialArm === Dtos.TrialArm.TestDirected) {
            return 'Group 2 - Test Directed';
        }
        return 'No Trial Arm Assigned';
    }, [patient]);

    const onFormCancel = React.useCallback(() => {
        if (patient) {
            history.push(`/registration/${institution?.code}/${patient?.studyNumber}`)
        }
        else if (institution?.code) {
            history.push(`/registration/${institution?.code}`)
        }
        else {
            history.push(`/registration`);
        }
    }, [history, institution, patient]);

    return <>
        <PatientInformation
            patientCaption={patientCaption}
            patientStateCaption={patientStateCaption}
            randomisationText={trialArmText}
        />
        <Typography
            variant="h2"
            color="primary"
            className={classes.subHeading}
        >
            Results
        </Typography>
        <FormGrid
            type={Dtos.ProsignaResult}
            name="results"
            columns={resultsColumn}
            onRowClick={onRowClick}
            onAddClick={onRowClick}
            allowAdd={false}
            rowLabel="Result"
            getRowValue={(getFieldValue: (path: string) => string, lookups, parentName: string, index?: number) => {
                if (parentName && index !== undefined) {
                    var rowValue = getFieldValue(`${parentName}[${index}].tumourSampleId`);

                    if (rowValue) {
                        return `the result for tumour sample ${rowValue}`;
                    }

                    return "this result";
                }

                return undefined;
            }}
            RemoveDialogComponent={RemoveDialog}
            hideRemoveColumn
        />

        <div
            className={classes.buttonGroup}
        >
            <Button
                variant="contained"
                color="primary"
                onClick={onFormCancel}
            >
                Back
            </Button>
            {/*<Button*/}
            {/*    variant="contained"*/}
            {/*    type="button"*/}
            {/*    color="primary"*/}
            {/*    onClick={onAddClick}*/}
            {/*>*/}
            {/*    Add Result*/}
            {/*</Button>*/}
        </div>
       
        <Dialog
            open={openResultDialog && index != null}
            onClose={() => setOpenResultDialog(false)}
            aria-labelledby="form-dialog"
            maxWidth="md"
            scroll="body"
            fullWidth
            disableBackdropClick
            disableEscapeKeyDown
        >
            <DialogTitle className={classes.dialogFormTitle}>Prosigna Assay (PAM 50) Result</DialogTitle>
            <DialogContent style={{ padding: '0' }}>
                <FieldProvider name={`results[${index}]`}>
                    <Field
                        label = "Tumour Sample ID"
                        name="tissueSample.tumourSampleId"
                        component={Text}
                        disabled
                    />
                    <Field
                        name="centralLabSampleId"
                        component={Text}
                    />
                    <Field
                        name="dateLabReceived"
                        component={KeyboardDatePicker}
                    />
                    <FieldGroup>
                        <Field
                            name="dateResultExpected"
                            component={KeyboardDatePicker}
                            disabled
                        />
                        <Field
                            name="specifyNewExpectedDate"
                            component={Checkbox}
                            label=""
                        />
                        <CrfCondition
                            type={Dtos.ProsignaAssayResult}
                            condition={isNewExpectedDateSpecified}
                            mode="Show"
                            subscription={{ values: true }}
                        >
                            <Field
                                name="newExpectedDateReason"
                                component={TextArea}
                            />
                            <Field
                                name="newExpectedDate"
                                component={KeyboardDatePicker}
                            />
                        </CrfCondition>
                    </FieldGroup>
                    <Field
                        name="numberOfHAndEAssessments"
                        component={Numeric}
                    />
                    <Field
                        name="numberOfRnaExtractions"
                        component={Numeric}
                    />
                    <FieldGroup>
                        <Field
                            name="tumourSampleSuitable"
                            component={Select}
                        />
                        <CrfCondition
                            type={Dtos.ProsignaAssayResult}
                            condition={isTumourSampleNotSuitable}
                            mode="Show"
                            subscription={{ values: true }}
                        >
                            <Field
                                name="tumourSampleNotSuitableReason"
                                component={Select}
                            />
                        </CrfCondition>
                        <CrfCondition
                            type={Dtos.ProsignaAssayResult}
                            condition={isTumourSampleNotSuitableReasonOther}
                            mode="Enable"
                            subscription={{ values: true }}
                        >
                            <Field
                                name="tumourSampleNotSuitableReasonSpecify"
                                component={TextArea}
                            />
                        </CrfCondition>
                        <CrfCondition
                            type={Dtos.ProsignaAssayResult}
                            condition={isTumourSampleSuitable}
                            mode="Show"
                            subscription={{ values: true }}
                        >
                            <Field
                                name="numberOfProsignaAssays"
                                component={Numeric}
                            />
                        </CrfCondition>
                        <CrfCondition
                            type={Dtos.ProsignaAssayResult}
                            condition={isTumourSampleSuitable}
                            mode="Show"
                            subscription={{ values: true }}
                        >
                            <Field
                                name="prosignaAssaySuccessful"
                                component={Select}
                            />
                        </CrfCondition>
                        <CrfCondition
                            type={Dtos.ProsignaAssayResult}
                            condition={isProsignaNotSuccessful}
                            mode="Show"
                            subscription={{ values: true }}
                        >
                            <Field
                                name="prosignaAssayNotSuccessfulReason"
                                component={TextArea}
                            />
                        </CrfCondition>
                        <CrfCondition
                            type={Dtos.ProsignaAssayResult}
                            condition={isProsignaSuccessful}
                            mode="Show"
                            subscription={{ values: true }}
                        >
                            <Field
                                name="dateProsignaCompleted"
                                component={KeyboardDatePicker}
                            />
                            <Field
                                name="prosignaAssayResultsRorScore"
                                component={Numeric}
                            />
                            <Field
                                name="prosignaAssayResultsRiskCategory"
                                component={Select}
                            />
                            <Field
                                name="prosignaAssayResultsSubtype"
                                component={Select}
                            />
                        </CrfCondition>
                        <CrfCondition
                            type={Dtos.ProsignaAssayResult}
                            condition={isProsignaSuccessful}
                            mode="Show"
                            subscription={{ values: true }}
                        >
                            <Field
                                name="suitableForBankingAndFutureResearch"
                                component={Select}
                            />
                        </CrfCondition>
                        <CrfCondition
                            type={Dtos.ProsignaAssayResult}
                            condition={isSampleSuitableForBanking}
                            mode="Show"
                            subscription={{ values: true }}
                        >
                            <Field
                                name="numberOfRemainingHAndEAssessments"
                                component={Numeric}
                            />
                            <Field
                                name="numberOfRemainingRnaExtractions"
                                component={Numeric}
                            />
                        </CrfCondition>
                    </FieldGroup>

                    <SourceDocuments
                        instructions="Please upload the Prosigna (PAM50) Investigational Use Only Report"
                    />
                    
                    <div className={classes.dialogButton}>
                        <SubmitButton
                            variant="text"
                            color="secondary"
                            onClick={onCancel}
                        >
                            {!readOnly ? 'Cancel' : 'Back'}
                        </SubmitButton>
                        {
                            !readOnly && (
                                <SubmitButton
                                    variant="text"
                                    color="primary"
                                    onClick={onSave}
                                >
                                    Save
                                </SubmitButton>
                            )
                        }
                    </div>
                </FieldProvider>
            </DialogContent>
        </Dialog>
    </>
}

const ProsignaAssayResultWarningMessage: React.FunctionComponent = () => {
    const { values: formValues } = useFormState<Dtos.ProsignaAssayResult, any>({ values: true });

    const errors = React.useMemo(() => {
        return formValues?.results?.filter(r => r.assayResultPdfError)
            .map((e, i) => {

                const error = parseAssayResultPdfError(e.assayResultPdfError!);

                return <div
                    key={`warning-${i}`}
                >
                    <b>
                        {
                            `Tumour sample id ${e.tumourSampleId ?? 'missing'}: `
                        }
                    </b>
                    {
                        error
                    }
                </div>;
            });
    }, [formValues]);

    if (!errors ||
        errors.length === 0) {
        return null;
    }

    return (
        <Alert severity="warning">
            The assay results uploaded contained the following errors...
            {
                errors
            }
        </Alert>
    );
}

const ProsignaAssayResult: React.FunctionComponent<IProsignaAssayResultProps> = () => {
    const { masterGroup } = React.useContext(MasterGroupContext);
    const { collaboratingGroup } = React.useContext(CollaboratingGroupContext);
    const { institution } = React.useContext(InstitutionContext);
    const { patient } = React.useContext(PatientContext);

    const [[canAdministerOpms], permissionLoadState] = usePermissionsByIds(permissions, masterGroup?.id, collaboratingGroup?.id, institution?.id, patient?.id, true, false);

    const [index, setIndex] = React.useState<number | undefined>(undefined);

    const [openResultDialog, setOpenResultDialog] = React.useState<boolean>(false);
    const { enqueueSnackbar } = useSnackbar();

    const onFormSubmitFailure = React.useCallback(async (formState: IFormState<Dtos.ProsignaAssayResult, Dtos.IValidationError>, error?: unknown) => {

        let message: string = 'An error occurred while attempting to save the form.';

        if (error instanceof Array &&
            error.some((e) => isIOptimaError(e))) {
            message = error.filter((e) => isIOptimaError(e)).map(e => (e as IOptimaError).message).join(', ');
        }

        enqueueSnackbar(
            <>
                <AlertTitle>
                    Form Not Saved
                </AlertTitle>
                {
                    message
                }
            </>,
            { variant: 'critical' }
        );
    }, [enqueueSnackbar]);

    const prosignaAssayResultContext: IProsignaAssayResultContext = React.useMemo(() => {
        return {
            index,
            setIndex: (i) => setIndex(i),
            openResultDialog: openResultDialog,
            setOpenResultDialog: (open) => setOpenResultDialog(open),
        }
    }, [index, setIndex, openResultDialog, setOpenResultDialog]);

    if (permissionLoadState.state === RequestState.None || permissionLoadState.state === RequestState.Pending) {
        return (
            <RouteLoading />
        );
    }


    
    return (
        <>
            <FormBreadcrumbs />
            <CrfForm
                formType={Dtos.ProsignaAssayResult}
                validateOn="onChange"
                canEdit={canAdministerOpms}
                onFormSubmitFailure={onFormSubmitFailure}
                hideButtons
            >
                {/*<Field*/}
                {/*    name="prosignaAssayResultStatus"*/}
                {/*    component={Select}*/}
                {/*/>*/}

                <ProsignaAssayResultContext.Provider value={prosignaAssayResultContext}>
                    <ProsignaAssayResultWarningMessage />
                    <ProsignaAssayResultGrid />
                </ProsignaAssayResultContext.Provider>
            </CrfForm>
        </>
    );
}


/*
 * ----------------------------------------------------------------------------------
 * Default Export
 * ----------------------------------------------------------------------------------
 */

export default ProsignaAssayResult;
