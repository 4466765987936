export interface IOptimaError {
    errorCode: string;
    message: string;
    stackTrace: string;
}

export const isIOptimaError = (value: unknown): value is IOptimaError => {
    return (typeof value === 'object' &&
        value !== null &&
        value !== undefined &&
        'message' in value &&
        'errorCode' in value &&
        'stackTrace' in value);
};