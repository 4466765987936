/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the context for the currently selected patient.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to create a context.
 */
import * as React from 'react';

/*
 * Used to type request states.
 */
import { RequestState } from '@ngt/request-utilities';
import { PatientsContext, RouteLoading, usePatientsByCodes } from '@ngt/opms';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */
import * as Dtos from '../../api/dtos';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

export interface IPatientsByCodesAndFilterResolverProps {
    masterGroupCode?: string | null;
    collaboratingGroupCode?: string | null;
    countryCode?: string | null;
    institutionCode?: string | null;
    patientStateIds?: number[] | null;
    filterFx?: (patients: Dtos.IPatient[]) => Dtos.IPatient[];

    resolveBeforeLoad?: boolean | null;
}

/*
 * ---------------------------------------------------------------------------------
 * Constants
 * ---------------------------------------------------------------------------------
 */

const PatientsByCodesAndFilterResolver: React.FunctionComponent<IPatientsByCodesAndFilterResolverProps> = ({
    masterGroupCode,
    collaboratingGroupCode,
    countryCode,
    institutionCode,
    patientStateIds,
    filterFx,
    resolveBeforeLoad,
    children
}) => {
    const [patients, loadState, actions] = usePatientsByCodes(masterGroupCode, collaboratingGroupCode, countryCode, institutionCode, patientStateIds, true);

    const context = React.useMemo(() => {
        var filteredPatients = patients;

        if (filterFx) {
            filteredPatients = filterFx(patients ?? []);
        }

        return {
            patients: filteredPatients, 
            loadState,
            actions
        };
    }, [patients, loadState, actions, filterFx]);

    return (
        <PatientsContext.Provider value={context}>
            {
                !resolveBeforeLoad || (loadState.state !== RequestState.None && loadState.state !== RequestState.Pending) ?
                    children :
                    <RouteLoading />
            }
        </PatientsContext.Provider>
    );
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default PatientsByCodesAndFilterResolver;