/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the pro completion guidelines component
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/**
 * Required to make use of JSX functionality
 */
import * as React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/**
 * Used to create a style hook to utilise JSS
 */
import { Theme, makeStyles } from '@material-ui/core/styles';


/**
 * Used to style buttons.
 */
import Button from '@material-ui/core/Button';

import { faBook } from '@fortawesome/pro-duotone-svg-icons/faBook';

import Dialog from '@material-ui/core/Dialog';


import DialogContent from '@material-ui/core/DialogContent';


import DialogTitle from '@material-ui/core/DialogTitle';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

interface IProCompletionGuidelinesProps {
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles<Theme>(theme => ({
    container: {
        padding: theme.spacing(3)
    },
    heading: {
        marginTop: theme.spacing(4)
    },
    patientSummary: {
        marginTop: theme.spacing(3)
    },
    buttonSet: {
        textAlign: 'right',
        paddingTop: theme.spacing(3)
    },
    button: {
        marginLeft: theme.spacing(3),

        width: '100%',

        '&:first-child': {
            marginLeft: theme.spacing(0)
        }
    }
}));

const ProCompletionGuidelines: React.FunctionComponent<IProCompletionGuidelinesProps> = (props) => {
    const classes = useStyles();

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <Button
                variant="contained"
                color="default"
                onClick={handleClickOpen}
                className={classes.button}
            >
                <FontAwesomeIcon icon={faBook} fixedWidth />&nbsp;PRO Forms Completion Guidelines
            </Button>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" maxWidth="xl" fullWidth={true}>
                <DialogTitle>PRO Forms Completion Guidelines</DialogTitle>
                <DialogContent>
                    <p>
                        Baseline PROs will be completed prior to treatment allocation. On-study PROs will be complete prior to the scheduled clinic visit,
                        i.e. before the patient is reviewed by the treating clinician. Patients should be directed to come into clinic approximately 30 minutes early to
                        allow time to complete the PRO forms.
                    </p>
                    <p>
                        <b>Note:</b> when in clinic consultation are not feasible, PRO forms could be completed remotely. Study coordinator can call the participant and complete
                        the <u>paper</u> PRO forms according to the participant's answers. Ensure that all applicable PRO forms for the visit are printed as one document. If the
                        participant is with you in the clinic but has difficulties completing the online PRO forms, you can "Impersonate" the participant and complete the PRO
                        forms on their behalf, according to the participant's answers. Please refer to the Data Completion Guideline for details.
                    </p>
                    <p>
                        <b><u>IMPORTANT:</u></b><br />
                        If the patient feels that a particular item is not applicable to her at that particular timepoint, please advise the patient to leave the item blank.<br />
                        <b>Please reiterate to the participant that individual questionnaire response will not be viewed by a member of the treatment team. If a site staff member
                        identifies a high level symptom or health status problem when reviewing PRO form response/completion, the site staff should alert the treating clinician
                        immediately.</b><br />
                        <b>Some people may become distressed by the process of completing a PRO form. Patients who become upset or distressed as a result of participating
                        in this research may contact the study doctor or be referred to counselling services.</b>
                    </p>
                    <p>
                        <b>Verbal communication with the participant about the PRO forms</b><br />
                        Ensure that the following issues are covered in your verbal communication with the participant:
                    </p>
                    <ul>
                        <li>
                            Why PRO forms are being assessed: PRO forms are included in this study to evaluate how participants are feeling, and how the treatment
                            affects participant use of healthcare services;
                        </li>
                        <li>
                            The patient is automatically registered onto the PRO study as part of the trial registration, if she is proficient in the English language;
                        </li>
                        <li>
                            The patient should complete the questionnaires prior to seeing her clinician;
                        </li>
                        <li>
                            Expected time it takes to complete the PRO forms (approximately 30 minutes);
                        </li>
                        <li>
                            Read questionnaire instructions to the patient and ensure that they understand;<br />
                            NOTE that the recall period is specified in the individual PRO form (e.g. today, or within the last week etc)
                        </li>
                        <li>
                            The patient should complete the questionnaire independently, in a quiet/private location within the clinic;
                        </li>
                        <li>
                            Schedule of PRO assessments (Refer to OPTIMA Protocol Section 11.1 "Schedule of Events" Table 4 "Schedule of Events");
                        </li>
                        <li>
                            Responses should be honest and the patient's own;
                        </li>
                        <li>
                            Apart from the study coordinators who will complete the PRO Compliance and Missing Data forms, only the central research team
                            will view their PRO responses.
                        </li>
                    </ul>
                    <p>
                        <b>Assistance that can be provided to the participant</b><br />
                        <b>- Assistance from Study Coordinators:</b>
                    </p>
                    <p>
                        The site coordinator can read questions to the participant and/or record participant's response on the questionnaire if the patient:
                    </p>
                    <ul>
                        <li>
                            Left reading glasses at home or is visually impaired;
                        </li>
                        <li>
                            Has severe arthritis or medical conditions preventing them from being able to write.
                        </li>
                        <li>
                            The assisting staff member should:
                            <ul>
                                <li>
                                    Only read questions and record patient's responses;
                                </li>
                                <li>
                                    Always allow the participant to choose responses;
                                </li>
                                <li>
                                    Maintain an objective voice, without making any comments on the patient's responses;
                                </li>
                                <li>
                                    Avoid rephrasing questions if patient asks for clarification;
                                </li>
                                <li>
                                    Record the type and level of assistance given on the PROCoMiDa form.
                                </li>
                            </ul>
                        </li>
                    </ul>
                    <p>
                        <b>- Assistance from Family or Friend:</b><br />
                    </p>
                    <ul>
                        <li>
                            Patient's family or friends cannot complete the PROs for the patient.
                        </li>
                        <li>
                            Patient's family or friends should not comment on the items while the patient completes the PROMs.
                        </li>
                    </ul>
                    <p>
                        <b>- English</b><br />
                    </p>
                    <ul>
                        <li>
                            Non-English-speaking patients: Verbal translators should not be used as they can introduce bias. Therefore, non-English-speaking patients
                            cannot participate in the PRO study.
                        </li>
                        <li>
                            English illiterate patients can complete the PROMs by someone reading the items out for them. PROCoMiDa form "Assisted Forms" section has an
                            option of "patient could not read well enough", which should be used to document English illiteracy.
                        </li>
                    </ul>
                    <p>
                        <b>Reviewing the PRO forms for completion</b>
                    </p>
                    <ul>
                        <li>
                            Site coordinators should review questionnaires for completeness while the patient is present, focusing on completeness of response only.
                            Site staff should remain neutral when checking questionnaires. Do not express opinion about responses.
                            <ul>
                                <li>
                                    Missed question? Politely inform the patient they have missed the question and ask if they would like to answer it. If not,
                                    that is acceptable.
                                </li>
                                <li>
                                    Missed page? Pages may be stuck together or only one side of the double-sided questionnaire completed. Politely inform the patient
                                    they have missed some of the questionnaire and ask if they would like to complete the remainder. If necessary, ask if they
                                    would like some assistance. If the patient does not wish to complete the questionnaire, leave the questionnaire as is.
                                </li>
                                <li>
                                    Two responses for one question? Ask patient to check & choose the response that best describes the health status.
                                </li>
                                <li>
                                    Please show appreciation when questionnaires are completed and ask the participant if they have any concerns.
                                </li>
                            </ul>
                        </li>
                        <li>
                            If the participant's PRO response is obviously incorrect, do not request the participant to amend the data. Ensure that the participant is
                            informed of the form layout etc prior to completing the PRO forms next time.
                        </li>
                        <li>
                            Some people may become distressed by the process of completing a PRO questionnaire. As specified in the patient information sheet, patients who
                            become upset or distressed as a result of participation in this research may contact the study team/as per your institution's procedures.
                            <ul>
                                <li>
                                    Keep a record of any referrals the patient receives.
                                </li>
                            </ul>
                        </li>
                    </ul>
                    <p>
                        Please show appreciation when questionnaires are completed and ask the participant if they have any concerns.<br />
                        Note: PRO forms are required to be submitted at each timepoint even if not completed by the participant. Please indicate the incomplete data
                        on the PROCoMiDa form. PROCoMida form needs to be completed for any form that is not completed at all. If the participant answered some questions on a form, PROCoMiDa form does not need to be reported for a partially completed form.
                    </p>
                </DialogContent>
            </Dialog>
        </>
    );
};

/*
* ---------------------------------------------------------------------------------
* Default Export
* ---------------------------------------------------------------------------------
*/
export default ProCompletionGuidelines;