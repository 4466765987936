/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * This file contains the component that provides context for the online patient
 * management system.
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */

/**
 * Required to use React components.
 */
import * as React from 'react';

/*
 * Used to style components
 */
import { useTheme, useMediaQuery, Typography, makeStyles } from '@material-ui/core';

/**
 * Used for the basic page layout.
 */
import {
    Field,
    ToggleButtonGroup,
    ProForm,
    RadioGroup,
    UserType,
    useAuthenticatedUser,
    MasterGroupContext,
    CollaboratingGroupContext,
    InstitutionContext,
    PatientContext,
    RouteLoading,
    FormBreadcrumbs,

    FormContext,
    RatingRow,
    RatingTable,
    Checkbox,
    CrfCondition,
    ICrfConditionParameters,
    FieldGroup,
    KeyboardDatePicker
} from '@ngt/opms';

import { usePermissionsByIds, Permission } from '@ngt/opms-bctapi';

import { RequestState } from '@ngt/request-utilities';

import Alert from '@material-ui/lab/Alert';

import { useHistory } from 'react-router-dom';

/**
 * Used to display icons
 */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons/faInfoCircle';

/*
 * ----------------------------------------------------------------------------------
 * Imports - Internal
 * ----------------------------------------------------------------------------------
 */

/*
 * Used to type patient state.
 */
import * as Dtos from '../../api/dtos';
import Footer from './Footer';
import { DefaultFooterPosition } from '../../constants/proForms';
import { useDefaultStepsProps } from '../../hooks/useDefaultStepsProps';
import SourceDocuments from '../../components/SourceDocuments';



/*
 * ----------------------------------------------------------------------------------
 * Interface
 * ----------------------------------------------------------------------------------
 */

interface IFactBProps {
}

/*
 * ---------------------------------------------------------------------------------
 * Functions
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles(theme => ({
    subHeading: {
        paddingTop: 24,
        paddingLeft: 24,
        fontSize: '1.8rem',
        textDecoration: 'underline'
    }
}));

/*
 * ----------------------------------------------------------------------------------
 * Components
 * ----------------------------------------------------------------------------------
 */

const declinedChecked = ({ formState }: ICrfConditionParameters<Dtos.FactB>) => formState?.values.satisfiedWithSexLifeDeclined === false || formState?.values.satisfiedWithSexLifeDeclined == null;

const permissions: Permission[] = [
    Permission.OpmsProUpdate,
    Permission.OpmsProAdminister
];

const FactB: React.FunctionComponent<IFactBProps> = () => {
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isExtraSmall = useMediaQuery(theme.breakpoints.down('xs'));

    const [user] = useAuthenticatedUser();

    const isPatient = React.useMemo(() => {
        return user?.type === UserType.Patient;
    }, [user]);

    const showSteps = isPatient || isMobile;

    const { masterGroup } = React.useContext(MasterGroupContext);
    const { collaboratingGroup } = React.useContext(CollaboratingGroupContext);
    const { institution } = React.useContext(InstitutionContext);
    const { patient } = React.useContext(PatientContext);

    const [[canUpdatePro, canAdministerPro], permissionLoadState] = usePermissionsByIds(permissions, masterGroup?.id, collaboratingGroup?.id, institution?.id, patient?.id, true);

    const history = useHistory();
    const { form } = React.useContext(FormContext);
    const defaultStepsProps = useDefaultStepsProps();

    if (form && (form as Dtos.IProForm).completed && isPatient) {
        history.push(`/`);
    }

    if (permissionLoadState.state === RequestState.None || permissionLoadState.state === RequestState.Pending) {
        return (
            <RouteLoading />
        );
    }

    const canEdit = canUpdatePro || canAdministerPro;
    
    return (
        <>
            {
                !isPatient && (
                    <FormBreadcrumbs />
                )
            }
            <ProForm
                formType={Dtos.FactB}
                validateOn="onChange"
                useSteps={showSteps}
                canEdit={canEdit}
                canAdministerProForm={canAdministerPro}
                footer={<Footer isPatient={isPatient} />}
                footerPosition={DefaultFooterPosition}
                stepsProps={defaultStepsProps}
                showEventInHeader={true}
            >
                {
                    !isPatient && (
                        <Field
                            hideLabel
                            name="ptDidNotComplete"
                            component={Checkbox}
                            xs={12}
                            sm={12}
                            md={showSteps ? 12 : 6}
                            lg={showSteps ? 12 : 5}
                            xl={showSteps ? 12 : 5}
                        />
                    )
                }
                <div>
                    <Alert severity="info" icon={<FontAwesomeIcon fixedWidth icon={faInfoCircle} />} >
                        The following is a list of statements that other people with your illness have said are important. Please select an answer to indicate your response as it applies to the <u>past 7 days.</u>
                    </Alert>
                </div>
                {
                    showSteps && (
                        isExtraSmall ?
                            <Field
                                name= "lackOfEnergy"
                                component = { ToggleButtonGroup }
                                orientation = "vertical"
                                spacing = { 3 }
                                xs = { 12 }
                                sm = { 12 }
                                md = { 12 }
                                lg = { 12 }
                                xl = { 12 }
                            /> :
                            <Field
                                name="lackOfEnergy"
                                component={RadioGroup}
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                            />
                    )
                }
                {
                    showSteps && (
                        isExtraSmall ?
                            <Field
                                name="nausea"
                                component={ToggleButtonGroup}
                                orientation="vertical"
                                spacing={3}
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                            /> :
                            <Field
                                name="nausea"
                                component={RadioGroup}
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                            />
                    )
                }
                {
                    showSteps && (
                        isExtraSmall ?
                            <Field
                                name="troubleMeetingFamilyNeeds"
                                component={ToggleButtonGroup}
                                orientation="vertical"
                                spacing={3}
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                            /> :
                            <Field
                                name="troubleMeetingFamilyNeeds"
                                component={RadioGroup}
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                            />
                    )
                }
                {
                    showSteps && (
                        isExtraSmall ?
                            <Field
                                name="pain"
                                component={ToggleButtonGroup}
                                orientation="vertical"
                                spacing={3}
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                            /> :
                            <Field
                                name="pain"
                                component={RadioGroup}
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                            />
                    )
                }
                {
                    showSteps && (
                        isExtraSmall ?
                            <Field
                                name="negativeSideEffects"
                                component={ToggleButtonGroup}
                                orientation="vertical"
                                spacing={3}
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                            /> :
                            <Field
                                name="negativeSideEffects"
                                component={RadioGroup}
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                            />
                    )
                }
                {
                    showSteps && (
                        isExtraSmall ?
                            <Field
                                name="feelsIll"
                                component={ToggleButtonGroup}
                                orientation="vertical"
                                spacing={3}
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                            /> :
                            <Field
                                name="feelsIll"
                                component={RadioGroup}
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                            />
                    )
                }
                {
                    showSteps && (
                        isExtraSmall ?
                            <Field
                                name="spendsTimeInBed"
                                component={ToggleButtonGroup}
                                orientation="vertical"
                                spacing={3}
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                            /> :
                            <Field
                                name="spendsTimeInBed"
                                component={RadioGroup}
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                            />
                    )
                }
                {
                    showSteps && (
                        isExtraSmall ?
                            <Field
                                name="closeToFriends"
                                component={ToggleButtonGroup}
                                orientation="vertical"
                                spacing={3}
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                            /> :
                            <Field
                                name="closeToFriends"
                                component={RadioGroup}
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                            />
                    )
                }
                {
                    showSteps && (
                        isExtraSmall ?
                            <Field
                                name="emotionalSupportFromFamily"
                                component={ToggleButtonGroup}
                                orientation="vertical"
                                spacing={3}
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                            /> :
                            <Field
                                name="emotionalSupportFromFamily"
                                component={RadioGroup}
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                            />
                    )
                }
                {
                    showSteps && (
                        isExtraSmall ?
                            <Field
                                name="supportFromFriends"
                                component={ToggleButtonGroup}
                                orientation="vertical"
                                spacing={3}
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                            /> :
                            <Field
                                name="supportFromFriends"
                                component={RadioGroup}
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                            />
                    )
                }
                {
                    showSteps && (
                        isExtraSmall ?
                            <Field
                                name="familyAcceptsIllness"
                                component={ToggleButtonGroup}
                                orientation="vertical"
                                spacing={3}
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                            /> :
                            <Field
                                name="familyAcceptsIllness"
                                component={RadioGroup}
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                            />
                    )
                }
                {
                    showSteps && (
                        isExtraSmall ?
                            <Field
                                name="satisfiedWithFamilyCommunication"
                                component={ToggleButtonGroup}
                                orientation="vertical"
                                spacing={3}
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                            /> :
                            <Field
                                name="satisfiedWithFamilyCommunication"
                                component={RadioGroup}
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                            />
                    )
                }
                {
                    showSteps && (
                        isExtraSmall ?
                            <Field
                                name="feelCloseToPartner"
                                component={ToggleButtonGroup}
                                orientation="vertical"
                                spacing={3}
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                            /> :
                            <Field
                                name="feelCloseToPartner"
                                component={RadioGroup}
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                            />
                    )
                }
                {
                    showSteps && (
                        isExtraSmall ?
                            <>
                                <Field
                                    name="satisfiedWithSexLifeDeclined"
                                    component={Checkbox}
                                    label="Regardless of your current level of sexual activity,
                                    please answer the following question. If you prefer not to answer it,
                                    please mark this box and proceed to the next question"
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    xl={12}
                                />
                                <CrfCondition
                                    type={Dtos.FactB}
                                    condition={declinedChecked}
                                    subscription={{ values: true }}
                                >
                                    <Field
                                        name="satisfiedWithSexLife"
                                        component={ToggleButtonGroup}
                                        orientation="vertical"
                                        spacing={3}
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={12}
                                        xl={12}
                                    />
                                </CrfCondition>
                            </>
                            :
                            <>
                                <Field
                                    name="satisfiedWithSexLifeDeclined"
                                    component={Checkbox}
                                    label="Regardless of your current level of sexual activity,
                                    please answer the following question. If you prefer not to answer it,
                                    please mark this box and proceed to the next question"
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    xl={12}
                                />
                                <CrfCondition
                                    type={Dtos.FactB}
                                    condition={declinedChecked}
                                    subscription={{ values: true }}
                                >
                                    <Field
                                        name="satisfiedWithSexLife"
                                        component={RadioGroup}
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={12}
                                        xl={12}
                                    />
                                </CrfCondition>
                            </>
                    )
                }
                {
                    showSteps && (
                        isExtraSmall ?
                            <Field
                                name="sad"
                                component={ToggleButtonGroup}
                                orientation="vertical"
                                spacing={3}
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                            /> :
                            <Field
                                name="sad"
                                component={RadioGroup}
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                            />
                    )
                }
                {
                    showSteps && (
                        isExtraSmall ?
                            <Field
                                name="copingIsSatisfactory"
                                component={ToggleButtonGroup}
                                orientation="vertical"
                                spacing={3}
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                            /> :
                            <Field
                                name="copingIsSatisfactory"
                                component={RadioGroup}
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                            />
                    )
                }
                {
                    showSteps && (
                        isExtraSmall ?
                            <Field
                                name="losingHope"
                                component={ToggleButtonGroup}
                                orientation="vertical"
                                spacing={3}
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                            /> :
                            <Field
                                name="losingHope"
                                component={RadioGroup}
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                            />
                    )
                }
                {
                    showSteps && (
                        isExtraSmall ?
                            <Field
                                name="nervous"
                                component={ToggleButtonGroup}
                                orientation="vertical"
                                spacing={3}
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                            /> :
                            <Field
                                name="nervous"
                                component={RadioGroup}
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                            />
                    )
                }
                {
                    showSteps && (
                        isExtraSmall ?
                            <Field
                                name="worriedAboutDeath"
                                component={ToggleButtonGroup}
                                orientation="vertical"
                                spacing={3}
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                            /> :
                            <Field
                                name="worriedAboutDeath"
                                component={RadioGroup}
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                            />
                    )
                }
                {
                    showSteps && (
                        isExtraSmall ?
                            <Field
                                name="worriedConditionWillGetWorse"
                                component={ToggleButtonGroup}
                                orientation="vertical"
                                spacing={3}
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                            /> :
                            <Field
                                name="worriedConditionWillGetWorse"
                                component={RadioGroup}
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                            />
                    )
                }
                {
                    showSteps && (
                        isExtraSmall ?
                            <Field
                                name="ableToWork"
                                component={ToggleButtonGroup}
                                orientation="vertical"
                                spacing={3}
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                            /> :
                            <Field
                                name="ableToWork"
                                component={RadioGroup}
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                            />
                    )
                }
                {
                    showSteps && (
                        isExtraSmall ?
                            <Field
                                name="fulfillingWork"
                                component={ToggleButtonGroup}
                                orientation="vertical"
                                spacing={3}
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                            /> :
                            <Field
                                name="fulfillingWork"
                                component={RadioGroup}
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                            />
                    )
                }
                {
                    showSteps && (
                        isExtraSmall ?
                            <Field
                                name="ableToEnjoyLife"
                                component={ToggleButtonGroup}
                                orientation="vertical"
                                spacing={3}
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                            /> :
                            <Field
                                name="ableToEnjoyLife"
                                component={RadioGroup}
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                            />
                    )
                }
                {
                    showSteps && (
                        isExtraSmall ?
                            <Field
                                name="acceptedIllness"
                                component={ToggleButtonGroup}
                                orientation="vertical"
                                spacing={3}
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                            /> :
                            <Field
                                name="acceptedIllness"
                                component={RadioGroup}
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                            />
                    )
                }
                {
                    showSteps && (
                        isExtraSmall ?
                            <Field
                                name="sleepingWell"
                                component={ToggleButtonGroup}
                                orientation="vertical"
                                spacing={3}
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                            /> :
                            <Field
                                name="sleepingWell"
                                component={RadioGroup}
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                            />
                    )
                }
                {
                    showSteps && (
                        isExtraSmall ?
                            <Field
                                name="enjoyingUsualActivities"
                                component={ToggleButtonGroup}
                                orientation="vertical"
                                spacing={3}
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                            /> :
                            <Field
                                name="enjoyingUsualActivities"
                                component={RadioGroup}
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                            />
                    )
                }
                {
                    showSteps && (
                        isExtraSmall ?
                            <Field
                                name="contentWithLife"
                                component={ToggleButtonGroup}
                                orientation="vertical"
                                spacing={3}
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                            /> :
                            <Field
                                name="contentWithLife"
                                component={RadioGroup}
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                            />
                    )
                }
                {
                    showSteps && (
                        isExtraSmall ?
                            <Field
                                name="shortOfBreath"
                                component={ToggleButtonGroup}
                                orientation="vertical"
                                spacing={3}
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                            /> :
                            <Field
                                name="shortOfBreath"
                                component={RadioGroup}
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                            />
                    )
                }
                {
                    showSteps && (
                        isExtraSmall ?
                            <Field
                                name="selfconsciousAppearance"
                                component={ToggleButtonGroup}
                                orientation="vertical"
                                spacing={3}
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                            /> :
                            <Field
                                name="selfconsciousAppearance"
                                component={RadioGroup}
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                            />
                    )
                }
                {
                    showSteps && (
                        isExtraSmall ?
                            <Field
                                name="armsSwollenOrTender"
                                component={ToggleButtonGroup}
                                orientation="vertical"
                                spacing={3}
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                            /> :
                            <Field
                                name="armsSwollenOrTender"
                                component={RadioGroup}
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                            />
                    )
                }
                {
                    showSteps && (
                        isExtraSmall ?
                            <Field
                                name="feelsSexuallyAttractive"
                                component={ToggleButtonGroup}
                                orientation="vertical"
                                spacing={3}
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                            /> :
                            <Field
                                name="feelsSexuallyAttractive"
                                component={RadioGroup}
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                            />
                    )
                }
                {
                    showSteps && (
                        isExtraSmall ?
                            <Field
                                name="botheredByHairLoss"
                                component={ToggleButtonGroup}
                                orientation="vertical"
                                spacing={3}
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                            /> :
                            <Field
                                name="botheredByHairLoss"
                                component={RadioGroup}
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                            />
                    )
                }
                {
                    showSteps && (
                        isExtraSmall ?
                            <Field
                                name="worriedFamilyWillGetSameIllness"
                                component={ToggleButtonGroup}
                                orientation="vertical"
                                spacing={3}
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                            /> :
                            <Field
                                name="worriedFamilyWillGetSameIllness"
                                component={RadioGroup}
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                            />
                    )
                }
                {
                    showSteps && (
                        isExtraSmall ?
                            <Field
                                name="worryStressImpactsIllness"
                                component={ToggleButtonGroup}
                                orientation="vertical"
                                spacing={3}
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                            /> :
                            <Field
                                name="worryStressImpactsIllness"
                                component={RadioGroup}
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                            />
                    )
                }
                {
                    showSteps && (
                        isExtraSmall ?
                            <Field
                                name="botheredByWeightChange"
                                component={ToggleButtonGroup}
                                orientation="vertical"
                                spacing={3}
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                            /> :
                            <Field
                                name="botheredByWeightChange"
                                component={RadioGroup}
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                            />
                    )
                }
                {
                    showSteps && (
                        isExtraSmall ?
                            <Field
                                name="feelLikeAWoman"
                                component={ToggleButtonGroup}
                                orientation="vertical"
                                spacing={3}
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                            /> :
                            <Field
                                name="feelLikeAWoman"
                                component={RadioGroup}
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                            />
                    )
                }
                {
                    showSteps && (
                        isExtraSmall ?
                            <Field
                                name="experiencePainInPartsOfBody"
                                component={ToggleButtonGroup}
                                orientation="vertical"
                                spacing={3}
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                            /> :
                            <Field
                                name="experiencePainInPartsOfBody"
                                component={RadioGroup}
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                            />
                    )
                }
                {
                    !showSteps && (
                        <div>
                            <Typography className={classes.subHeading}>Physical Well-Being</Typography>
                            <RatingTable>
                                <RatingRow name='lackOfEnergy' />
                                <RatingRow name='nausea' />
                                <RatingRow name='troubleMeetingFamilyNeeds' />
                                <RatingRow name='pain' />
                                <RatingRow name='negativeSideEffects' />
                                <RatingRow name='feelsIll' />
                                <RatingRow name='spendsTimeInBed' />
                            </RatingTable>
                            <Typography className={classes.subHeading}>Social/Family Well-Being</Typography>
                            <RatingTable>
                                <RatingRow name='closeToFriends' />
                                <RatingRow name='emotionalSupportFromFamily' />
                                <RatingRow name='supportFromFriends' />
                                <RatingRow name='familyAcceptsIllness' />
                                <RatingRow name='satisfiedWithFamilyCommunication' />
                                <RatingRow name='feelCloseToPartner' />
                            </RatingTable>
                            <Field name='satisfiedWithSexLifeDeclined'
                                component={Checkbox}
                                label="Regardless of your current level of sexual activity,
                                please answer the following question. If you prefer not to answer it,
                                please mark this box and proceed to the next question"
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                            />
                            <CrfCondition
                                type={Dtos.FactB}
                                condition={declinedChecked}
                                subscription={{ values: true }}
                            >
                                <RatingTable>
                                    <RatingRow name='satisfiedWithSexLife' />
                                </RatingTable>
                            </CrfCondition>
                            <Typography className={classes.subHeading}>Emotional Well-Being</Typography>
                            <RatingTable>
                                <RatingRow name='sad' />
                                <RatingRow name='copingIsSatisfactory' />
                                <RatingRow name='losingHope' />
                                <RatingRow name='nervous' />
                                <RatingRow name='worriedAboutDeath' />
                                <RatingRow name='worriedConditionWillGetWorse' />
                            </RatingTable>
                            <Typography className={classes.subHeading}>Functional Well-Being</Typography>
                            <RatingTable>
                                <RatingRow name='ableToWork' />
                                <RatingRow name='fulfillingWork' />
                                <RatingRow name='ableToEnjoyLife' />
                                <RatingRow name='acceptedIllness' />
                                <RatingRow name='sleepingWell' />
                                <RatingRow name='enjoyingUsualActivities' />
                                <RatingRow name='contentWithLife' />
                            </RatingTable>
                            <Typography className={classes.subHeading}>Additional Concerns</Typography>
                            <RatingTable>
                                <RatingRow name='shortOfBreath' />
                                <RatingRow name='selfconsciousAppearance' />
                                <RatingRow name='armsSwollenOrTender' />
                                <RatingRow name='feelsSexuallyAttractive' />
                                <RatingRow name='botheredByHairLoss' />
                                <RatingRow name='worriedFamilyWillGetSameIllness' />
                                <RatingRow name='worryStressImpactsIllness' />
                                <RatingRow name='botheredByWeightChange' />
                                <RatingRow name='feelLikeAWoman' />
                                <RatingRow name='experiencePainInPartsOfBody' />
                            </RatingTable>
                        </div>
                    )
                }
                {
                    !isPatient && (
                        <FieldGroup>
                            <Field
                                name="dateCompleted"
                                component={KeyboardDatePicker}
                                xs={12}
                                sm={12}
                                md={showSteps ? 12 : 6}
                                lg={showSteps ? 12 : 5}
                                xl={showSteps ? 12 : 5}
                            />
                        </FieldGroup>
                    )
                }
                {
                    canAdministerPro && (
                        <SourceDocuments />
                    )
                }
            </ProForm>
        </>
    );
}


/*
 * ----------------------------------------------------------------------------------
 * Default Export
 * ----------------------------------------------------------------------------------
 */

export default FactB;
