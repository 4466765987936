/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * This file contains the component that provides context for the online patient
 * management system.
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */

/**
 * Required to use React components.
 */
import * as React from 'react';

/*
 * Used to style components
 */
import { useTheme, useMediaQuery, Grid, Paper, Typography, makeStyles, fade  } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/**
 * Used for the basic page layout.
 */
import {
    Field,
    FieldGroup,
    Checkbox,
    StaticGrid,
    ProForm,
    useAuthenticatedUser,
    UserType,
    MasterGroupContext,
    CollaboratingGroupContext,
    InstitutionContext,
    IStaticFieldComponentProps,
    PatientContext,
    RouteLoading,
    FormBreadcrumbs,
    FormLabel,
    StaticField,
    Slider,
    FormContext,
    IStaticGridHeaderProps,
    IStaticGridCellProps,
    Text,
    Input,
    StaticGridRow,
    TextArea,
    KeyboardDatePicker,
    useFormActions
} from '@ngt/opms';

import { usePermissionsByIds, Permission } from '@ngt/opms-bctapi';

import { RequestState } from '@ngt/request-utilities';

/*
 * ----------------------------------------------------------------------------------
 * Imports - Internal
 * ----------------------------------------------------------------------------------
 */

/*
 * Used to type patient state.
 */
import * as Dtos from '../../api/dtos';

import { useHistory } from 'react-router-dom';
import { faInfoCircle } from '@fortawesome/pro-duotone-svg-icons/faInfoCircle';
import Footer from './Footer';
import { DefaultFooterPosition } from '../../constants/proForms';
import { useDefaultStepsProps } from '../../hooks/useDefaultStepsProps';
import SourceDocuments from '../../components/SourceDocuments';

/*
 * ----------------------------------------------------------------------------------
 * Interface
 * ----------------------------------------------------------------------------------
 */

interface IDistressProps {
}

/*
 * ---------------------------------------------------------------------------------
 * Functions
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles(theme => ({
    container: {
        padding: theme.spacing(3, 3, 0, 3),

        '& $sliderExtra + div': {
            paddingLeft: 10
        }
    },
    footer: {
        padding: theme.spacing(1),
        textAlign: 'center',
        background: theme.palette.grey[100],
        borderBottom: `1px solid ${theme.palette.grey[300]}`
    },
    sliderExtra: {
        textAlign: 'center',
        fontWeight: 'bold',

        '&:first-child': {
            paddingBottom: theme.spacing(2),
            marginTop: '-12px'
        },

        '&:last-child': {
            paddingTop: theme.spacing(3)
        }
    },
    flexityBoxMcGee: {
        display: 'flex',
        justifyContent: 'center'
    }
}));

const useSliderStyles = makeStyles(theme => ({
    root: {
        width: 2,
        padding: '0 15px',
    },
    valueLabel: {
        top: 'calc(-50% + 12px) !important',
        left: '-22px !important',
        '& *': {
            background: 'transparent',
            color: '#000',
        },
    },
    rail: {
        width: '10px !important'
    },
    track: {
        width: '10px !important',
    },
    mark: {
        backgroundColor: fade(theme.palette.primary.main, 0.3),
        width: 20,
        height: 1,
        marginLeft: '-6px !important',
        '&:nth-child(4)': {
            width: 20,
            height: 20,
            borderRadius: 12,
            marginBottom: '-12px !important',
            marginLeft: '-6px !important',
            backgroundColor: theme.palette.primary.main
        },
        '&:nth-child(4n + 8)': {
            width: 28,
            marginLeft: '-10px !important',
        }
    },
    markLabel: {
        left: '40px !important'
    },
    markActive: {
        opacity: 1,
        backgroundColor: 'currentColor',
    },
    thumb: {
        marginLeft: '0px !important',
        marginBottom: '-9px !important',
        width: '10px',
        height: '10px',
        borderRadius: 0
    }
}));

const useYourHealthTodayStyles = makeStyles(theme => ({
    paper: {
        padding: theme.spacing(1),
        background: theme.palette.primary.main,
        color: theme.palette.common.white,
        width: 100,
        height: 100,
        textAlign: 'center'
    }
}));

/*
 * ----------------------------------------------------------------------------------
 * Components
 * ----------------------------------------------------------------------------------
 */

const permissions: Permission[] = [
    Permission.OpmsProUpdate,
    Permission.OpmsProAdminister
];

const checkHeader: IStaticGridHeaderProps[] = [
    {
        columnName: 'ranking',
        content: 'Ranking',
        width: '20%',
        minWidth: 0
    },
    {
        columnName: 'physicalProblems',
        content: 'Physical Problems',
        width: '80%',
        minWidth: 0
    }
];

const pracHeader: IStaticGridHeaderProps[] = [
    {
        columnName: 'ranking',
        content: '',
        width: '20%',
        minWidth: 0
    },
    {
        columnName: 'physicalProblems',
        content: 'Practical Problems',
        width: '80%',
        minWidth: 0
    }
];

const famHeader: IStaticGridHeaderProps[] = [
    {
        columnName: 'ranking',
        content: '',
        width: '20%',
        minWidth: 0
    },
    {
        columnName: 'physicalProblems',
        content: 'Family Problems',
        width: '80%',
        minWidth: 0
    }
];

const emoHeader: IStaticGridHeaderProps[] = [
    {
        columnName: 'ranking',
        content: '',
        width: '20%',
        minWidth: 0
    },
    {
        columnName: 'physicalProblems',
        content: 'Emotional Problems',
        width: '80%',
        minWidth: 0
    }
];

const spiHeader: IStaticGridHeaderProps[] = [
    {
        columnName: 'ranking',
        content: '',
        width: '20%',
        minWidth: 0
    },
    {
        columnName: 'physicalProblems',
        content: 'Spiritual/Religious Concerns',
        width: '80%',
        minWidth: 0
    }
];


const marks = [
    {
        value: -1,
        label: ''
    },
    {
        value: 0,
        label: '0'
    },
    {
        value: 1,
        label: '1'
    },
    {
        value: 2,
        label: '2'
    },
    {
        value: 3,
        label: '3'
    },
    {
        value: 4,
        label: '4'
    },
    {
        value: 5,
        label: '5'
    },
    {
        value: 6,
        label: '6'
    },
    {
        value: 7,
        label: '7'
    },
    {
        value: 8,
        label: '8'
    },
    {
        value: 9,
        label: '9'
    },
    {
        value: 10,
        label: '10'
    }
]

const appearanceRow: IStaticGridCellProps[] = [
    {
        columnName: 'ranking',
        content: (
            <Input
                name='appearanceRanking'
                component={Text}
                InputProps={{ type: "number", inputProps: {min: 1, max: 4}}}
            />
        ),
        fieldName: 'appearanceRanking'
    },
    {
        columnName: 'physicalProblems',
        content: (
            <Input
                name='appearance'
                component={Checkbox}
            />
        ),
        fieldName: 'appearance'
    },
];
const bathingOrDressingRow: IStaticGridCellProps[] = [
    {
        columnName: 'ranking',
        content: (
            <Input
                name='bathingOrDressingRanking'
                component={Text}
                InputProps={{ type: "number", inputProps: {min: 1, max: 4}}}

            />
        ),
        fieldName: 'bathingOrDressingRanking'
    },
    {
        columnName: 'physicalProblems',
        content: (
            <Input
                name='bathingOrDressing'
                component={Checkbox}

            />
        ),
        fieldName: 'bathingOrDressing'
    },
];
const breathingDifficultiesRow: IStaticGridCellProps[] = [
    {
        columnName: 'ranking',
        content: (
            <Input
                name='breathingDifficultiesRanking'
                component={Text}
                InputProps={{ type: "number", inputProps: {min: 1, max: 4}}}

            />
        ),
        fieldName: 'breathingDifficultiesRanking'
    },
    {
        columnName: 'physicalProblems',
        content: (
            <Input
                name='breathingDifficulties'
                component={Checkbox}

            />
        ),
        fieldName: 'breathingDifficulties'
    },
];
const passingUrineRow: IStaticGridCellProps[] = [
    {
        columnName: 'ranking',
        content: (
            <Input
                name='passingUrineRanking'
                component={Text}
                InputProps={{ type: "number", inputProps: {min: 1, max: 4}}}

            />
        ),
        fieldName: 'passingUrineRanking'
    },
    {
        columnName: 'physicalProblems',
        content: (
            <Input
                name='passingUrine'
                component={Checkbox}

            />
        ),
        fieldName: 'passingUrine'
    },
];
const constipationRow: IStaticGridCellProps[] = [
    {
        columnName: 'ranking',
        content: (
            <Input
                name='constipationRanking'
                component={Text}
                InputProps={{ type: "number", inputProps: {min: 1, max: 4}}}

            />
        ),
        fieldName: 'constipationRanking'
    },
    {
        columnName: 'physicalProblems',
        content: (
            <Input
                name='constipation'
                component={Checkbox}

            />
        ),
        fieldName: 'constipation'
    },
];
const diarrhoeaRow: IStaticGridCellProps[] = [
    {
        columnName: 'ranking',
        content: (
            <Input
                name='diarrhoeaRanking'
                component={Text}
                InputProps={{ type: "number", inputProps: {min: 1, max: 4}}}

            />
        ),
        fieldName: 'diarrhoeaRanking'
    },
    {
        columnName: 'physicalProblems',
        content: (
            <Input
                name='diarrhoea'
                component={Checkbox}

            />
        ),
        fieldName: 'diarrhoea'
    },
];
const eatingRow: IStaticGridCellProps[] = [
    {
        columnName: 'ranking',
        content: (
            <Input
                name='eatingRanking'
                component={Text}
                InputProps={{ type: "number", inputProps: {min: 1, max: 4}}}

            />
        ),
        fieldName: 'eatingRanking'
    },
    {
        columnName: 'physicalProblems',
        content: (
            <Input
                name='eating'
                component={Checkbox}

            />
        ),
        fieldName: 'eating'
    },
];
const fatigueRow: IStaticGridCellProps[] = [
    {
        columnName: 'ranking',
        content: (
            <Input
                name='fatigueRanking'
                component={Text}
                InputProps={{ type: "number", inputProps: {min: 1, max: 4}}}

            />
        ),
        fieldName: 'fatigueRanking'
    },
    {
        columnName: 'physicalProblems',
        content: (
            <Input
                name='fatigue'
                component={Checkbox}

            />
        ),
        fieldName: 'fatigue'
    },
];
const swollenRow: IStaticGridCellProps[] = [
    {
        columnName: 'ranking',
        content: (
            <Input
                name='swollenRanking'
                component={Text}
                InputProps={{ type: "number", inputProps: {min: 1, max: 4}}}
            />
        ),
        fieldName: 'swollenRanking'
    },
    {
        columnName: 'physicalProblems',
        content: (
            <Input
                name='swollen'
                component={Checkbox}

            />
        ),
        fieldName: 'swollen'
    },
];

const gettingAroundRow: IStaticGridCellProps[] = [
    {
        columnName: 'ranking',
        content: (
            <Input
                name='gettingAroundRanking'
                component={Text}
                InputProps={{ type: "number", inputProps: { min: 1, max: 4 } }}
            />
        ),
        fieldName: 'gettingAroundRanking'
    },
    {
        columnName: 'physicalProblems',
        content: (
            <Input
                name='gettingAround'
                component={Checkbox}

            />
        ),
        fieldName: 'gettingAround'
    },
];

const highTemperatureRow: IStaticGridCellProps[] = [
    {
        columnName: 'ranking',
        content: (
            <Input
                name='highTemperatureRanking'
                component={Text}
                InputProps={{ type: "number", inputProps: {min: 1, max: 4}}}

            />
        ),
        fieldName: 'highTemperatureRanking'
    },
    {
        columnName: 'physicalProblems',
        content: (
            <Input
                name='highTemperature'
                component={Checkbox}

            />
        ),
        fieldName: 'highTemperature'
    },
];
const indigestionsRow: IStaticGridCellProps[] = [
    {
        columnName: 'ranking',
        content: (
            <Input
                name='indigestionsRanking'
                component={Text}
                InputProps={{ type: "number", inputProps: {min: 1, max: 4}}}

            />
        ),
        fieldName: 'indigestionsRanking'
    },
    {
        columnName: 'physicalProblems',
        content: (
            <Input
                name='indigestions'
                component={Checkbox}

            />
        ),
        fieldName: 'indigestions'
    },
];
const soreOrDryMouthRow: IStaticGridCellProps[] = [
    {
        columnName: 'ranking',
        content: (
            <Input
                name='soreOrDryMouthRanking'
                component={Text}
                InputProps={{ type: "number", inputProps: {min: 1, max: 4}}}

            />
        ),
        fieldName: 'soreOrDryMouthRanking'
    },
    {
        columnName: 'physicalProblems',
        content: (
            <Input
                name='soreOrDryMouth'
                component={Checkbox}

            />
        ),
        fieldName: 'soreOrDryMouth'
    },
];
const nauseaOrVomitingRow: IStaticGridCellProps[] = [
    {
        columnName: 'ranking',
        content: (
            <Input
                name='nauseaOrVomitingRanking'
                component={Text}
                InputProps={{ type: "number", inputProps: {min: 1, max: 4}}}

            />
        ),
        fieldName: 'nauseaOrVomitingRanking'
    },
    {
        columnName: 'physicalProblems',
        content: (
            <Input
                name='nauseaOrVomiting'
                component={Checkbox}

            />
        ),
        fieldName: 'nauseaOrVomiting'
    },
];
const painRow: IStaticGridCellProps[] = [
    {
        columnName: 'ranking',
        content: (
            <Input
                name='painRanking'
                component={Text}
                InputProps={{ type: "number", inputProps: {min: 1, max: 4}}}

            />
        ),
        fieldName: 'painRanking'
    },
    {
        columnName: 'physicalProblems',
        content: (
            <Input
                name='pain'
                component={Checkbox}

            />
        ),
        fieldName: 'pain'
    },
];
const dryItchyOrSoreSkinRow: IStaticGridCellProps[] = [
    {
        columnName: 'ranking',
        content: (
            <Input
                name='dryItchyOrSoreSkinRanking'
                component={Text}
                InputProps={{ type: "number", inputProps: {min: 1, max:4}}}

            />
        ),
        fieldName: 'dryItchyOrSoreSkinRanking'
    },
    {
        columnName: 'physicalProblems',
        content: (
            <Input
                name='dryItchyOrSoreSkin'
                component={Checkbox}

            />
        ),
        fieldName: 'dryItchyOrSoreSkin'
    },
];
const sleepingProblemsRow: IStaticGridCellProps[] = [
    {
        columnName: 'ranking',
        content: (
            <Input
                name='sleepingProblemsRanking'
                component={Text}
                InputProps={{ type: "number", inputProps: {min: 1, max: 4}}}

            />
        ),
        fieldName: 'sleepingProblemsRanking'
    },
    {
        columnName: 'physicalProblems',
        content: (
            <Input
                name='sleepingProblems'
                component={Checkbox}

            />
        ),
        fieldName: 'sleepingProblems'
    },
];
const tinglingInHandsOrFeetRow: IStaticGridCellProps[] = [
    {
        columnName: 'ranking',
        content: (
            <Input
                name='tinglingInHandsOrFeetRanking'
                component={Text}
                InputProps={{ type: "number", inputProps: {min: 1, max: 4}}}

            />
        ),
        fieldName: 'tinglingInHandsOrFeetRanking'
    },
    {
        columnName: 'physicalProblems',
        content: (
            <Input
                name='tinglingInHandsOrFeet'
                component={Checkbox}

            />
        ),
        fieldName: 'tinglingInHandsOrFeet'
    },
];
const changeInTasteRow: IStaticGridCellProps[] = [
    {
        columnName: 'ranking',
        content: (
            <Input
                name='changeInTasteRanking'
                component={Text}
                InputProps={{ type: "number", inputProps: {min: 1, max: 4}}}

            />
        ),
        fieldName: 'changeInTasteRanking'
    },
    {
        columnName: 'physicalProblems',
        content: (
            <Input
                name='changeInTaste'
                component={Checkbox}

            />
        ),
        fieldName: 'changeInTaste'
    },
];
const hotFlushesRow: IStaticGridCellProps[] = [
    {
        columnName: 'ranking',
        content: (
            <Input
                name='hotFlushesRanking'
                component={Text}
                InputProps={{ type: "number", inputProps: {min: 1, max: 4}}}

            />
        ),
        fieldName: 'hotFlushesRanking'
    },
    {
        columnName: 'physicalProblems',
        content: (
            <Input
                name='hotFlushes'
                component={Checkbox}

            />
        ),
        fieldName: 'hotFlushes'
    },
];
const memoryOrConcentrationRow: IStaticGridCellProps[] = [
    {
        columnName: 'ranking',
        content: (
            <Input
                name='memoryOrConcentrationRanking'
                component={Text}
                InputProps={{ type: "number", inputProps: {min: 1, max: 4}}}

            />
        ),
        fieldName: 'memoryOrConcentrationRanking'
    },
    {
        columnName: 'physicalProblems',
        content: (
            <Input
                name='memoryOrConcentration'
                component={Checkbox}

            />
        ),
        fieldName: 'memoryOrConcentration'
    },
];
const speechProblemsRow: IStaticGridCellProps[] = [
    {
        columnName: 'ranking',
        content: (
            <Input
                name='speechProblemsRanking'
                component={Text}
                InputProps={{ type: "number", inputProps: {min: 1, max: 4}}}

            />
        ),
        fieldName: 'speechProblemsRanking'
    },
    {
        columnName: 'physicalProblems',
        content: (
            <Input
                name='speechProblems'
                component={Checkbox}

            />
        ),
        fieldName: 'speechProblems'
    },
];
const woundCareAfterSurgeryRow: IStaticGridCellProps[] = [
    {
        columnName: 'ranking',
        content: (
            <Input
                name='woundCareAfterSurgeryRanking'
                component={Text}
                InputProps={{ type: "number", inputProps: {min: 1, max: 4}}}

            />
        ),
        fieldName: 'woundCareAfterSurgeryRanking'
    },
    {
        columnName: 'physicalProblems',
        content: (
            <Input
                name='woundCareAfterSurgery'
                component={Checkbox}

            />
        ),
        fieldName: 'woundCareAfterSurgery'
    },
];
const caringResponsibilitiesRow: IStaticGridCellProps[] = [
    {
        columnName: 'ranking',
        content: (
            <Input
                name='caringResponsibilitiesRanking'
                component={Text}
                InputProps={{ type: "number", inputProps: {min: 1, max: 4}}}

            />
        ),
        fieldName: 'caringResponsibilitiesRanking'
    },
    {
        columnName: 'physicalProblems',
        content: (
            <Input
                name='caringResponsibilities'
                component={Checkbox}

            />
        ),
        fieldName: 'caringResponsibilities'
    },
];
const financeWorkOrHousingRow: IStaticGridCellProps[] = [
    {
        columnName: 'ranking',
        content: (
            <Input
                name='financeWorkOrHousingRanking'
                component={Text}
                InputProps={{ type: "number", inputProps: {min: 1, max: 4}}}

            />
        ),
        fieldName: 'financeWorkOrHousingRanking'
    },
    {
        columnName: 'physicalProblems',
        content: (
            <Input
                name='financeWorkOrHousing'
                component={Checkbox}

            />
        ),
        fieldName: 'financeWorkOrHousing'
    },
];
const transportOrParkingRow: IStaticGridCellProps[] = [
    {
        columnName: 'ranking',
        content: (
            <Input
                name='transportOrParkingRanking'
                component={Text}
                InputProps={{ type: "number", inputProps: {min: 1, max: 4}}}

            />
        ),
        fieldName: 'transportOrParkingRanking'
    },
    {
        columnName: 'physicalProblems',
        content: (
            <Input
                name='transportOrParking'
                component={Checkbox}

            />
        ),
        fieldName: 'transportOrParking'
    },
];
const questionsAboutIllnessRow: IStaticGridCellProps[] = [
    {
        columnName: 'ranking',
        content: (
            <Input
                name='questionsAboutIllnessRanking'
                component={Text}
                InputProps={{ type: "number", inputProps: {min: 1, max: 4}}}

            />
        ),
        fieldName: 'questionsAboutIllnessRanking'
    },
    {
        columnName: 'physicalProblems',
        content: (
            <Input
                name='questionsAboutIllness'
                component={Checkbox}

            />
        ),
        fieldName: 'questionsAboutIllness'
    },
];
const communicationWithMedicalStaffRow: IStaticGridCellProps[] = [
    {
        columnName: 'ranking',
        content: (
            <Input
                name='communicationWithMedicalStaffRanking'
                component={Text}
                InputProps={{ type: "number", inputProps: {min: 1, max: 4}}}

            />
        ),
        fieldName: 'communicationWithMedicalStaffRanking'
    },
    {
        columnName: 'physicalProblems',
        content: (
            <Input
                name='communicationWithMedicalStaff'
                component={Checkbox}

            />
        ),
        fieldName: 'communicationWithMedicalStaff'
    },
];
const childrenRow: IStaticGridCellProps[] = [
    {
        columnName: 'ranking',
        content: (
            <Input
                name='childrenRanking'
                component={Text}
                InputProps={{ type: "number", inputProps: {min: 1, max: 4}}}

            />
        ),
        fieldName: 'childrenRanking'
    },
    {
        columnName: 'physicalProblems',
        content: (
            <Input
                name='children'
                component={Checkbox}

            />
        ),
        fieldName: 'children'
    },
];
const partnerRow: IStaticGridCellProps[] = [
    {
        columnName: 'ranking',
        content: (
            <Input
                name='partnerRanking'
                component={Text}
                InputProps={{ type: "number", inputProps: {min: 1, max: 4}}}

            />
        ),
        fieldName: 'partnerRanking'
    },
    {
        columnName: 'physicalProblems',
        content: (
            <Input
                name='partner'
                component={Checkbox}

            />
        ),
        fieldName: 'partner'
    },
];
const relativesOrFriendsRow: IStaticGridCellProps[] = [
    {
        columnName: 'ranking',
        content: (
            <Input
                name='relativesOrFriendsRanking'
                component={Text}
                InputProps={{ type: "number", inputProps: {min: 1, max: 4}}}

            />
        ),
        fieldName: 'relativesOrFriendsRanking'
    },
    {
        columnName: 'physicalProblems',
        content: (
            <Input
                name='relativesOrFriends'
                component={Checkbox}

            />
        ),
        fieldName: 'relativesOrFriends'
    },
];
const lonelinessOrIsolationRow: IStaticGridCellProps[] = [
    {
        columnName: 'ranking',
        content: (
            <Input
                name='lonelinessOrIsolationRanking'
                component={Text}
                InputProps={{ type: "number", inputProps: {min: 1, max: 4}}}

            />
        ),
        fieldName: 'lonelinessOrIsolationRanking'
    },
    {
        columnName: 'physicalProblems',
        content: (
            <Input
                name='lonelinessOrIsolation'
                component={Checkbox}

            />
        ),
        fieldName: 'lonelinessOrIsolation'
    },
];
const sadnessOrDepressionRow: IStaticGridCellProps[] = [
    {
        columnName: 'ranking',
        content: (
            <Input
                name='sadnessOrDepressionRanking'
                component={Text}
                InputProps={{ type: "number", inputProps: {min: 1, max: 4}}}

            />
        ),
        fieldName: 'sadnessOrDepressionRanking'
    },
    {
        columnName: 'physicalProblems',
        content: (
            <Input
                name='sadnessOrDepression'
                component={Checkbox}

            />
        ),
        fieldName: 'sadnessOrDepression'
    },
];
const worryFearOrAnxietyRow: IStaticGridCellProps[] = [
    {
        columnName: 'ranking',
        content: (
            <Input
                name='worryFearOrAnxietyRanking'
                component={Text}
                InputProps={{ type: "number", inputProps: {min: 1, max: 4}}}

            />
        ),
        fieldName: 'worryFearOrAnxietyRanking'
    },
    {
        columnName: 'physicalProblems',
        content: (
            <Input
                name='worryFearOrAnxiety'
                component={Checkbox}

            />
        ),
        fieldName: 'worryFearOrAnxiety'
    },
];
const angerOrFrustrationRow: IStaticGridCellProps[] = [
    {
        columnName: 'ranking',
        content: (
            <Input
                name='angerOrFrustrationRanking'
                component={Text}
                InputProps={{ type: "number", inputProps: {min: 1, max: 4}}}

            />
        ),
        fieldName: 'angerOrFrustrationRanking'
    },
    {
        columnName: 'physicalProblems',
        content: (
            <Input
                name='angerOrFrustration'
                component={Checkbox}

            />
        ),
        fieldName: 'angerOrFrustration'
    },
];
const difficultyMakingPlansRow: IStaticGridCellProps[] = [
    {
        columnName: 'ranking',
        content: (
            <Input
                name='difficultyMakingPlansRanking'
                component={Text}
                InputProps={{ type: "number", inputProps: {min: 1, max: 4}}}

            />
        ),
        fieldName: 'difficultyMakingPlansRanking'
    },
    {
        columnName: 'physicalProblems',
        content: (
            <Input
                name='difficultyMakingPlans'
                component={Checkbox}

            />
        ),
        fieldName: 'difficultyMakingPlans'
    },
];
const guiltRow: IStaticGridCellProps[] = [
    {
        columnName: 'ranking',
        content: (
            <Input
                name='guiltRanking'
                component={Text}
                InputProps={{ type: "number", inputProps: {min: 1, max: 4}}}

            />
        ),
        fieldName: 'guiltRanking'
    },
    {
        columnName: 'physicalProblems',
        content: (
            <Input
                name='guilt'
                component={Checkbox}

            />
        ),
        fieldName: 'guilt'
    },
];
const hopelessnessRow: IStaticGridCellProps[] = [
    {
        columnName: 'ranking',
        content: (
            <Input
                name='hopelessnessRanking'
                component={Text}
                InputProps={{ type: "number", inputProps: {min: 1, max: 4}}}

            />
        ),
        fieldName: 'hopelessnessRanking'
    },
    {
        columnName: 'physicalProblems',
        content: (
            <Input
                name='hopelessness'
                component={Checkbox}

            />
        ),
        fieldName: 'hopelessness'
    },
];
const sexualConcernRow: IStaticGridCellProps[] = [
    {
        columnName: 'ranking',
        content: (
            <Input
                name='sexualConcernRanking'
                component={Text}
                InputProps={{ type: "number", inputProps: {min: 1, max: 4}}}

            />
        ),
        fieldName: 'sexualConcernRanking'
    },
    {
        columnName: 'physicalProblems',
        content: (
            <Input
                name='sexualConcern'
                component={Checkbox}

            />
        ),
        fieldName: 'sexualConcern'
    },
];
const lossOfFaithOrSpiritualConcernRow: IStaticGridCellProps[] = [
    {
        columnName: 'ranking',
        content: (
            <Input
                name='lossOfFaithOrSpiritualConcernRanking'
                component={Text}
                InputProps={{ type: "number", inputProps: {min: 1, max: 4}}}

            />
        ),
        fieldName: 'lossOfFaithOrSpiritualConcernRanking'
    },
    {
        columnName: 'physicalProblems',
        content: (
            <Input
                name='lossOfFaithOrSpiritualConcern'
                component={Checkbox}

            />
        ),
        fieldName: 'lossOfFaithOrSpiritualConcern'
    },
];
const lossOfMeaningOrPurposeRow: IStaticGridCellProps[] = [
    {
        columnName: 'ranking',
        content: (
            <Input
                name='lossOfMeaningOrPurposeRanking'
                component={Text}
                InputProps={{ type: "number", inputProps: {min: 1, max: 4}}}

            />
        ),
        fieldName: 'lossOfMeaningOrPurposeRanking'
    },
    {
        columnName: 'physicalProblems',
        content: (
            <Input
                name='lossOfMeaningOrPurpose'
                component={Checkbox}

            />
        ),
        fieldName: 'lossOfMeaningOrPurpose'
    },
];
const noPeaceOrPastRegretsRow: IStaticGridCellProps[] = [
    {
        columnName: 'ranking',
        content: (
            <Input
                name='noPeaceOrPastRegretsRanking'
                component={Text}
                InputProps={{ type: "number", inputProps: { min: 1, max: 4 } }}

            />
        ),
        fieldName: 'noPeaceOrPastRegretsRanking'
    },
    {
        columnName: 'physicalProblems',
        content: (
            <Input
                name='noPeaceOrPastRegrets'
                component={Checkbox}

            />
        ),
        fieldName: 'noPeaceOrPastRegrets'
    },
];

const yourHealthTodaySubscription = { value: true };

const YourHealthToday: React.FunctionComponent<IStaticFieldComponentProps> = ({
    fieldState: { value }
}) => {
    const classes = useYourHealthTodayStyles();

    const distressDescription: string = (value !== -1) ?
        value :
        '';

    return (
        <Paper
            className={classes.paper}
        >
            <FormLabel simpleLabel />
            <Typography
                variant="h3"
                variantMapping={{
                    h3: 'div'
                }}
            >
                {distressDescription}
            </Typography>
        </Paper>
    );
};

const DistressSetter: React.FunctionComponent = () => {
    const { getFieldValue, setFieldValue } = useFormActions();

    React.useEffect(() => {
        const distressRating = getFieldValue('distressRating');

        if (distressRating === null ||
            distressRating === undefined) {
            setFieldValue('distressRating', -1);
        }
    },
        [getFieldValue, setFieldValue]);

    return null;
}

const Distress: React.FunctionComponent<IDistressProps> = () => {
    const classes = useStyles();
    const sliderClasses = useSliderStyles();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const [user] = useAuthenticatedUser();

    const isPatient = React.useMemo(() => {
        return user?.type === UserType.Patient;
    }, [user]);

    const showSteps = isPatient || isMobile;

    const { masterGroup } = React.useContext(MasterGroupContext);
    const { collaboratingGroup } = React.useContext(CollaboratingGroupContext);
    const { institution } = React.useContext(InstitutionContext);
    const { patient } = React.useContext(PatientContext);
    const defaultStepsProps = useDefaultStepsProps();

    const [[canUpdatePro, canAdministerPro], permissionLoadState] = usePermissionsByIds(permissions, masterGroup?.id, collaboratingGroup?.id, institution?.id, patient?.id, true);

    const history = useHistory();
    const { form } = React.useContext(FormContext);

    if (form && (form as Dtos.IProForm).completed && isPatient) {
        history.push(`/`);
    }

    if (permissionLoadState.state === RequestState.None || permissionLoadState.state === RequestState.Pending) {
        return (
            <RouteLoading />
        );
    }

    const canEdit = canUpdatePro || canAdministerPro;

    return (
        <>
            {
                !isPatient && (
                    <FormBreadcrumbs />
                )
            }
            <ProForm
                formType={Dtos.Distress}
                validateOn="onChange"
                useSteps={showSteps}
                canEdit={canEdit}
                canAdministerProForm={canAdministerPro}
                footer={<Footer isPatient={isPatient} />}
                footerPosition={DefaultFooterPosition}
                stepsProps={defaultStepsProps}
                showEventInHeader={true}
            >
                {
                    !isPatient && (
                        <Field
                            hideLabel
                            name="ptDidNotComplete"
                            component={Checkbox}
                            xs={12}
                            sm={12}
                            md={showSteps ? 12 : 6}
                            lg={showSteps ? 12 : 5}
                            xl={showSteps ? 12 : 5}
                        />
                    )
                }
                <FieldGroup>
                    <Alert severity="info" icon={<FontAwesomeIcon fixedWidth icon={faInfoCircle} />} >
                        Please select a number that best describes in general how much distress you have been experiencing over the past week, including today.
                    </Alert>
                    <div
                        className={classes.container}
                    >
                        <Grid
                            container
                            alignItems="center"
                        >
                            <Grid
                                item
                                xs={8}
                            >
                                <div
                                    className={classes.flexityBoxMcGee}
                                >
                                    <Paper>
                                        <DistressSetter />
                                        <Field
                                            name="distressRating"
                                            component={Slider}
                                            orientation="vertical"
                                            length={300}
                                            marks={marks}
                                            track="normal"
                                            label={null}
                                            step={1}
                                            min={-1}
                                            max={10}
                                            classes={sliderClasses}
                                            xs={12}
                                            sm={12}
                                            md={12}
                                            lg={12}
                                            xl={12}
                                            defaultValue={-1}
                                            before={
                                                <div
                                                    className={classes.sliderExtra}
                                                >
                                                    High distress
                                                </div>
                                            }
                                            after={
                                                <div
                                                    className={classes.sliderExtra}
                                                >
                                                    No distress
                                                </div>
                                            }
                                        />
                                    </Paper>
                                </div>
                            </Grid>
                            <Grid
                                item
                                xs={4}
                            >
                                <StaticField name="distressRating" component={YourHealthToday} subscription={yourHealthTodaySubscription} />
                            </Grid>
                        </Grid>
                    </div>
                </FieldGroup>
                <Grid
                    container
                    alignItems="center"
                >
                    <Grid
                        item
                        xs={12}
                        style={{padding: 24}}
                    >
                        <Typography>If any of the following items have been a cause of this distress for you over the past week, including today, please tick the box next to it. Please leave it blank if it does not apply to you.</Typography>
                        <Typography>Then rank (1st, 2nd, 3rd, 4th) your top 4 difficulties (1 would be the biggest problem, 4 would be your fourth biggest concern) and put this number beside the item in the RANKING column. </Typography>
                    </Grid>
                </Grid> 
                <StaticGrid
                    headers={checkHeader}
                    stickyHeader={true}
                >
                    <StaticGridRow cells={appearanceRow} />
                    <StaticGridRow cells={bathingOrDressingRow} />
                    <StaticGridRow cells={breathingDifficultiesRow} />
                    <StaticGridRow cells={passingUrineRow} />
                    <StaticGridRow cells={constipationRow} />
                    <StaticGridRow cells={diarrhoeaRow} />
                    <StaticGridRow cells={eatingRow} />
                    <StaticGridRow cells={fatigueRow} />
                    <StaticGridRow cells={swollenRow} />
                    <StaticGridRow cells={gettingAroundRow}/>
                    <StaticGridRow cells={highTemperatureRow} />
                    <StaticGridRow cells={indigestionsRow} />
                    <StaticGridRow cells={soreOrDryMouthRow} />
                    <StaticGridRow cells={nauseaOrVomitingRow} />
                    <StaticGridRow cells={painRow} />
                    <StaticGridRow cells={dryItchyOrSoreSkinRow} />
                    <StaticGridRow cells={sleepingProblemsRow} />
                    <StaticGridRow cells={tinglingInHandsOrFeetRow} />
                    <StaticGridRow cells={changeInTasteRow} />
                    <StaticGridRow cells={hotFlushesRow} />
                    <StaticGridRow cells={memoryOrConcentrationRow} />
                    <StaticGridRow cells={speechProblemsRow} />
                    <StaticGridRow cells={woundCareAfterSurgeryRow} />
                </StaticGrid>
                <>
                    <StaticGrid
                        headers={pracHeader}
                        stickyHeader={true}
                    >
                        <StaticGridRow cells={caringResponsibilitiesRow} />
                        <StaticGridRow cells={financeWorkOrHousingRow} />
                        <StaticGridRow cells={transportOrParkingRow} />
                        <StaticGridRow cells={questionsAboutIllnessRow} />
                        <StaticGridRow cells={communicationWithMedicalStaffRow} />
                    </StaticGrid>
                    <StaticGrid
                        headers={famHeader}
                        stickyHeader={true}
                    >
                        <StaticGridRow cells={childrenRow} />
                        <StaticGridRow cells={partnerRow} />
                        <StaticGridRow cells={relativesOrFriendsRow} />
                    </StaticGrid>
                    <StaticGrid
                        headers={emoHeader}
                        stickyHeader={true}
                    >
                        <StaticGridRow cells={lonelinessOrIsolationRow} />
                        <StaticGridRow cells={sadnessOrDepressionRow} />
                        <StaticGridRow cells={worryFearOrAnxietyRow} />
                        <StaticGridRow cells={angerOrFrustrationRow} />
                        <StaticGridRow cells={difficultyMakingPlansRow} />
                        <StaticGridRow cells={guiltRow} />
                        <StaticGridRow cells={hopelessnessRow} />
                        <StaticGridRow cells={sexualConcernRow} />
                    </StaticGrid>
                    <StaticGrid
                        headers={spiHeader}
                        stickyHeader={true}
                    >
                        <StaticGridRow cells={lossOfFaithOrSpiritualConcernRow} />
                        <StaticGridRow cells={lossOfMeaningOrPurposeRow} />
                        <StaticGridRow cells={noPeaceOrPastRegretsRow} />
                    </StaticGrid>
                    <Field
                        name="otherConcerns"
                        component={TextArea}
                        rows={10}
                        rowsMax={20}
                        xs={12}
                        sm={12}
                        md={showSteps ? 12 : 6}
                        lg={showSteps ? 12 : 5}
                        xl={showSteps ? 12 : 5}
                        variant="outlined"
                    />
                </>
                {
                    !isPatient && (
                        <FieldGroup>
                            <Field
                                name="dateCompleted"
                                component={KeyboardDatePicker}
                                xs={12}
                                sm={12}
                                md={showSteps ? 12 : 6}
                                lg={showSteps ? 12 : 5}
                                xl={showSteps ? 12 : 5}
                            />
                        </FieldGroup>
                    )
                }
                {
                    canAdministerPro && (
                        <SourceDocuments />
                    )
                }
            </ProForm>
        </>
    );
}

/*
 * ----------------------------------------------------------------------------------
 * Default Export
 * ----------------------------------------------------------------------------------
 */

export default Distress;