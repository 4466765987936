/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */

/**
 * Required to use React components.
 */
import * as React from 'react';

import { Box, makeStyles, Typography } from "@material-ui/core";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/**
 * Used for the copyright icon.
 */
import { faCopyright } from '@fortawesome/pro-regular-svg-icons/faCopyright';

/*
 * ----------------------------------------------------------------------------------
 * Interface
 * ----------------------------------------------------------------------------------
 */

interface IFooter {
    isPatient: boolean;
    showEq5dBlurb?: boolean;
}

/*
 * ---------------------------------------------------------------------------------
 * Functions
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles(theme => ({
    footer: {
        padding: theme.spacing(1),
        textAlign: 'center',
        background: theme.palette.grey[100],
        borderBottom: `1px solid ${theme.palette.grey[300]}`,
        width: '100%'
    }
}));

const Footer: React.FC<IFooter> = ({
    isPatient,
    showEq5dBlurb
}) => {
    const classes = useStyles();
    const support = isPatient ?
        'If you experience any technical issues, please contact your Research Nurse/Study Coordinator for support.' :
        'If you experience a technical difficulty please contact the BCT OPTIMA team for support.';

    return (
        <>
            <Box
                className={classes.footer}
            >
                {
                    showEq5dBlurb && <Box>
                        <Typography variant="caption">
                            <FontAwesomeIcon icon={faCopyright} fixedWidth /> EuroQol Research Foundation. EQ-5D<sup>TM</sup> is a trade mark of the EuroQol Research Foundation. UK (English) v2.1
                        </Typography>
                    </Box>
                }
                <Box>
                    <Typography variant="caption">
                        {
                            support
                        }
                    </Typography>
                </Box>
            </Box>
        </>
    );
}

/*
 * ----------------------------------------------------------------------------------
 * Default Export
 * ----------------------------------------------------------------------------------
 */

export default Footer;