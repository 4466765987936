
/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * This file contains the component that provides context for the online patient
 * management system.
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */

/**
 * Required to use React components.
 */
import * as React from 'react';

/*
 * Used to get access to router context.
 */

/*
 * Used to style components
 */
import { Button, Dialog, DialogActions, DialogContent, Typography, makeStyles, Accordion, AccordionSummary, AccordionDetails, FormControl, MenuItem, Link } from '@material-ui/core';

/**
 * Used for the basic page layout.
 */
import {
    CrfCondition,
    Field,
    FieldGroup,
    Form,
    ICrfConditionParameters,
    IFormLabel,
    IStaticGridCellProps,
    IStaticGridHeaderProps,
    Input,
    InstitutionsContext,
    KeyboardDatePicker,
    OnlinePatientManagementContext,
    PatientsContext,
    StaticGrid,
    StaticGridRow,
    SubmitButton,
    Text,
    TextArea,
    ToggleButtonGroup,
    pascalToCameCasePropertyPath,
    useLookupsByFormDefinitionId,
    IFormContext,
    IValidationError,
    useFormState,
    ILookup,
    GetFieldLookupItem,
    FormGrid,
    IFormGridCell,
    Selector,
    useFormActions,
    useRelatedField,
} from '@ngt/opms';

import { faDownload } from '@fortawesome/pro-duotone-svg-icons/faDownload';
import { DateTime } from 'luxon';

/*
 * ----------------------------------------------------------------------------------
 * Imports - Internal
 * ----------------------------------------------------------------------------------
 */

import * as Dtos from '../../api/dtos';

/*
 * Used to type patient state.
 */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useConsentForm from '../../hooks/useConsentForm';
import { get } from 'lodash-es';
import { faChevronDown, faEye } from '@fortawesome/pro-duotone-svg-icons';

import { Document, Page, pdfjs } from 'react-pdf';

import type { PDFDocumentProxy } from 'pdfjs-dist';


pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;



const ConsentReviewDialogWrapper: React.FunctionComponent<Omit<IConsentReviewDialogProps, 'formDefinitionId'>> = (props) => {
    const { formMetadata } = React.useContext(OnlinePatientManagementContext);

    const formDefinition = React.useMemo( () => {

        return formMetadata?.find(x => x.formDefinitionCode == 'consent');
    }, [formMetadata]);

    if (!formDefinition?.formDefinitionId) {
        return null;
    }

    return (
        
        <ConsentReviewDialog 
            {...props}
            formDefinitionId={formDefinition.formDefinitionId}
        />
    )
}

export interface IConsentReviewDialogProps {
    onCancel?: () => void;
    //onFormSave?: (event?: React.MouseEvent<HTMLButtonElement, MouseEvent>, formActions?: IFormContext<TScreeningLog, IValidationError>) => void; 
    patient?: Dtos.Patient;
    consentForm?: Dtos.Consent;
    open: boolean;
    formDefinitionId: number;
}

const useStyles = makeStyles(theme => ({
    dialogTitle: {
        color: theme.palette.secondary.main,
        padding: theme.spacing(2.5, 3, 0),
        fontSize: '1.25rem !important',
        fontWeight: 500,
    },
    dialogContent: {
        padding: 0
    },
    historyDropDown: {
        padding: theme.spacing(2.5, 3, 0),
    },
}));


const hasNoToAnyAbove = ({ lookups, formState }: ICrfConditionParameters<Dtos.ReviewConsent>) => {
    return GetFieldLookupItem(lookups, 'currentPicfsApproved', formState?.values.currentPicfsApproved)?.type === Dtos.LookupYesNoType.No ||
        GetFieldLookupItem(lookups, 'patientSignedPicfs', formState?.values.patientSignedPicfs)?.type === Dtos.LookupYesNoType.No ||
        GetFieldLookupItem(lookups, 'allPagesPresent', formState?.values.allPagesPresent)?.type === Dtos.LookupYesNoType.No ||
        GetFieldLookupItem(lookups, 'investigatorSigned', formState?.values.investigatorSigned)?.type === Dtos.LookupYesNoType.No ||
        GetFieldLookupItem(lookups, 'saConsentFullyChecked', formState?.values.saConsentFullyChecked)?.type === Dtos.LookupYesNoType.No ||
        GetFieldLookupItem(lookups, 'personWhoSignedAuth', formState?.values.personWhoSignedAuth)?.type === Dtos.LookupYesNoType.No ||
        GetFieldLookupItem(lookups, 'mainConsentCurrentTMDVersion', formState?.values.mainConsentCurrentTMDVersion)?.type === Dtos.LookupYesNoType.No ||
        GetFieldLookupItem(lookups, 'servicesAustraliaConsentCurrentTMDVersion', formState?.values.servicesAustraliaConsentCurrentTMDVersion)?.type === Dtos.LookupYesNoType.No ||
        GetFieldLookupItem(lookups, 'optionalConsentCurrentTMDVersion', formState?.values.optionalConsentCurrentTMDVersion)?.type === Dtos.LookupYesNoType.No ||
        GetFieldLookupItem(lookups, 'optionalConsentCurrentSecondTMDVersion', formState?.values.optionalConsentCurrentSecondTMDVersion)?.type === Dtos.LookupYesNoType.No;
}

const DialogSubmitButton: React.FunctionComponent<{ lookups: ILookup[] }> = ({
    lookups

}) => {
    const formState = useFormState<Dtos.ReviewConsent, any>({ values: true });

    const noToAnyAbove = React.useMemo(() => {
        return GetFieldLookupItem(lookups, 'currentPicfsApproved', formState?.values.currentPicfsApproved)?.type === Dtos.LookupYesNoType.No ||
            GetFieldLookupItem(lookups, 'patientSignedPicfs', formState?.values.patientSignedPicfs)?.type === Dtos.LookupYesNoType.No ||
            GetFieldLookupItem(lookups, 'allPagesPresent', formState?.values.allPagesPresent)?.type === Dtos.LookupYesNoType.No ||
            GetFieldLookupItem(lookups, 'investigatorSigned', formState?.values.investigatorSigned)?.type === Dtos.LookupYesNoType.No ||
            GetFieldLookupItem(lookups, 'saConsentFullyChecked', formState?.values.saConsentFullyChecked)?.type === Dtos.LookupYesNoType.No ||
            GetFieldLookupItem(lookups, 'personWhoSignedAuth', formState?.values.personWhoSignedAuth)?.type === Dtos.LookupYesNoType.No ||
            GetFieldLookupItem(lookups, 'mainConsentCurrentTMDVersion', formState?.values.mainConsentCurrentTMDVersion)?.type === Dtos.LookupYesNoType.No ||
            GetFieldLookupItem(lookups, 'servicesAustraliaConsentCurrentTMDVersion', formState?.values.servicesAustraliaConsentCurrentTMDVersion)?.type === Dtos.LookupYesNoType.No ||
            GetFieldLookupItem(lookups, 'optionalConsentCurrentTMDVersion', formState?.values.optionalConsentCurrentTMDVersion)?.type === Dtos.LookupYesNoType.No ||
            GetFieldLookupItem(lookups, 'optionalConsentCurrentSecondTMDVersion', formState?.values.optionalConsentCurrentSecondTMDVersion)?.type === Dtos.LookupYesNoType.No;
    }, [formState, lookups]);

    return <SubmitButton color="primary" disabled={false}>
       {noToAnyAbove ? 'New SDV' : 'Confirm Consent Form and Delete'}
    </SubmitButton>
}

const DialogSaveButton: React.FunctionComponent = ({}) => {
    const formState = useFormState<Dtos.ReviewConsent, any>({ values: true });

    const subscription = { value: true }

    const {
        state: {
            value: saveButtonClicked
        },
        context: {
            setValue: setSaveButtonClicked
        }
    } = useRelatedField<boolean>('saveButtonClicked', subscription);

    const onSave = React.useCallback(() => {
        // set save field
        setSaveButtonClicked(true);
        // catch save field on backend
    }, [setSaveButtonClicked, saveButtonClicked]);


    return <SubmitButton
                color="primary"
                onClick={onSave}
            >
                Save and Return
            </SubmitButton>
}







const labels: IFormLabel[] = [
    {
        name: 'consentFormVersion',
        label: 'Version Number',
        detailedLabel: ''
    },
    {
        name: 'reviewedBy',
        label: 'Reviewed By',
        detailedLabel: ''
    },
    {
        name: 'reviewedDate',
        label: 'Date',
        detailedLabel: ''
    }
];

const filesHeader: IStaticGridHeaderProps[] = [
    {
        columnName: 'document',
        content: 'Document',
        width: '20%',
        minWidth: 0
    },
    {
        columnName: 'version',
        content: 'Version',
        width: '20%',
        minWidth: 0
    },
    {
        columnName: 'date',
        content: 'Date',
        width: '20%',
        minWidth: 0
    },
    {
        columnName: 'currentTMDVersion',
        content: 'This is the current version in TMD',
        width: '20%',
        minWidth: 0
    },
    {
        columnName: 'deleteConsent',
        content: 'This is form with the problem',
        width: '20%',
        minWidth: 0
    },
];

const ConsentReviewDialog: React.FunctionComponent<IConsentReviewDialogProps> = ({
    open,
    onCancel,
    //onFormSave,
    patient,
    consentForm,
    formDefinitionId
}) => {

    const classes = useStyles();

    const { institutions } = React.useContext(InstitutionsContext);

    const institution = React.useMemo(() => {
        return institutions?.find(i => i.id === patient?.institutionId);
    }, [institutions, patient]);

    const [ lookups ] = useLookupsByFormDefinitionId(formDefinitionId, true);
    
    const processedLookups = React.useMemo(() => {
        return lookups?.map(lookup => ({ ...lookup, propertyName: pascalToCameCasePropertyPath(lookup.propertyName) }))
    }, [lookups]);

    const {
        formInitial,
        handleSubmit,
        onFormSubmitFailure,
        onFormSubmitValidationFailure,
        validate,
        allowSubmit,
        hasUserSubmittedAustralianConsent,
        hasUserSubmittedMainConsent,
        hasUserSubmittedOptionalConsent,
        hasUserSubmittedMoreOptionalConsent,
        optionalConsentFormId,
        consentFormId,
        servicesAustraliaConsentFormId,
        handleDifferentSubmit
    } = useConsentForm({ consentForm: consentForm, institution: institution, onCancel: onCancel, });


    const { patients } = React.useContext(PatientsContext);

    const dialogOpen = React.useMemo(() => {
        return !!patient && !!consentForm && open && patients?.find(p => p.id === patient?.id) != null;
    }, [patient, consentForm, open, patients]);

    // thecurrentform holds either consent form or undefined
    // set sets either
    const [theCurrentForm, setTheCurrentForm] = React.useState<Dtos.Consent | undefined>()
    
    // converts to boolean
    const disabled = (!!theCurrentForm || formInitial.consentStatus === Dtos.ConsentStatusType.NewConsentFormRequired || formInitial.consentStatus === Dtos.ConsentStatusType.Complete);

    // State hook remebers information (data input into a form)
    const [consentHistory, setConsentHistory] = React.useState<Dtos.ConsentHistorySDV[] | undefined>(undefined);

    // Context hook grabs higher up components
    const { serviceStackClient: client } = React.useContext(OnlinePatientManagementContext);

    // effect hook connects from external 
    React.useEffect(() => {
        const load = async () => {

            if (!patient?.id || !dialogOpen) { //if id is null dont try, dialog stops it from running 3 times
                return;
            }

            try {
                const response = await client
                    .get(
                        new Dtos.GetConsentHistoryListById({ // api call
                            patientId: patient?.id // input
                        })
                    );

                setConsentHistory(response as any);
            }
            catch (error: any) {
                setConsentHistory(undefined);
            }
        }

        load();
    }, [setConsentHistory, client, patient, dialogOpen]); // these are the things that can be called from within the hook


    const mainConsentRow: IStaticGridCellProps[] = [
        {
            columnName: 'document',
            content: (

                    <Link
                    style={{ cursor: 'pointer' }}
                    href= {`/document/${consentForm?.sourceDocuments?.find(x => x.fileTypeId === 3)?.file.id}`}
                    target= "_blank"
                >
                    {
                        !disabled && <Typography>
                                        {consentForm?.sourceDocuments?.find(x => x.fileTypeId === 3)?.name}
                                        <FontAwesomeIcon icon={faEye} fixedWidth style={{ marginLeft: '0.5rem' }} />
                                    </Typography>
                    }
                                    
               </Link>
                
            ),
            fieldName: 'mainConsentFile'
        },
        {
            columnName: 'version',
            content: (
                <Input
                    name='consentFormVersion'
                    component={Text}
    
                />
            ),
            fieldName: 'consentFormVersion'
        },
        {
            columnName: 'date',
            content: (
                <Input
                    name='mainConsentDate'
                    component={KeyboardDatePicker}
    
                />
            ),
            fieldName: 'mainConsentDate'
        },
        {
            columnName: 'currentTMDVersion',
            content: (
                <Input
                    component={ToggleButtonGroup}
                />
            ),
            fieldName: 'mainConsentCurrentTMDVersion'
        },
        {
            columnName: 'deleteConsent',
            content: (
                <CrfCondition
                    type={Dtos.ReviewConsent}
                    condition={hasNoToAnyAbove}
                    mode="Enable"
                    subscription={{ values: true }}
                >
                    <Input
                        component={ToggleButtonGroup}
                    />
                </CrfCondition>
            ),
            fieldName: 'deleteMainConsent'
        }
    ];

    const optionalConsentRow: IStaticGridCellProps[] = [
        {
            columnName: 'document',
            content: (
                <Link
                    style={{ cursor: 'pointer' }}
                    href={`/document/${consentForm?.sourceDocuments?.find(x => x.fileTypeId === 5)?.file.id}`}
                    target= "_blank"
                >
                    {
                        !disabled && <Typography>
                                        {consentForm?.sourceDocuments?.find(x => x.fileTypeId === 5)?.name}
                                        <FontAwesomeIcon icon={faEye} fixedWidth style={{ marginLeft: '0.5rem' }} />
                                    </Typography>
                    } 
                </Link>
            ),
            fieldName: 'optionalConsentFile'
        },
        {
            columnName: 'version',
            content: (
                <Input
                    name='optionalConsentVersion'
                    component={Text}
    
                />
            ),
            fieldName: 'optionalConsentVersion'
        },
        {
            columnName: 'date',
            content: (
                <Input
                    name='optionalConsentDate'
                    component={KeyboardDatePicker}
    
                />
            ),
            fieldName: 'optionalConsentDate'
        },
        {
            columnName: 'currentTMDVersion',
            content: (
                <Input
                    component={ToggleButtonGroup}
                />
            ),
            fieldName: 'optionalConsentCurrentTMDVersion'
        },
        {
            columnName: 'deleteConsent',
            content: (

                <CrfCondition
                    type={Dtos.ReviewConsent}
                    condition={hasNoToAnyAbove}
                    mode="Enable"
                    subscription={{ values: true }}
                >
                    <Input
                        component={ToggleButtonGroup}
                        />
                </CrfCondition>
            ),
            fieldName: 'deleteOptionalConsent'
        }
    ];

    const optionalConsentSecondRow: IStaticGridCellProps[] = [
        {
            columnName: 'document',
            content: (
                <Link
                    style={{ cursor: 'pointer' }}
                    href={`/document/${consentForm?.sourceDocuments?.filter(x => x.fileTypeId === 5)[1]?.file.id}`}
                    target= "_blank"
                >
                    {
                        !disabled && <Typography>
                                        {consentForm?.sourceDocuments?.filter(x => x.fileTypeId === 5)[1]?.name}
                                        <FontAwesomeIcon icon={faEye} fixedWidth style={{ marginLeft: '0.5rem' }} />
                                    </Typography>
                    } 
                </Link>
            ),
            fieldName: 'optionalConsentSecondFile'
        },
        {
            columnName: 'version',
            content: (
                <Input
                    name='optionalConsentSecondVersion'
                    component={Text}
    
                />
            ),
            fieldName: 'optionalConsentSecondVersion'
        },
        {
            columnName: 'date',
            content: (
                <Input
                    name='optionalConsentSecondDate'
                    component={KeyboardDatePicker}
    
                />
            ),
            fieldName: 'optionalConsentSecondDate'
        },
        {
            columnName: 'currentTMDVersion',
            content: (
                <Input
                    component={ToggleButtonGroup}
                />
            ),
            fieldName: 'optionalConsentCurrentSecondTMDVersion'
        },
        {
            columnName: 'deleteConsent',
            content: (

                <CrfCondition
                    type={Dtos.ReviewConsent}
                    condition={hasNoToAnyAbove}
                    mode="Enable"
                    subscription={{ values: true }}
                >
                    <Input
                        component={ToggleButtonGroup}
                        />
                </CrfCondition>
            ),
            fieldName: 'deleteOptionalSecondConsent'
        }
    ];

    const servicesAustraliaConsentRow: IStaticGridCellProps[] = [
       
        {
            columnName: 'document',
            content: (

                <Link
                    style={{ cursor: 'pointer' }}
                    href={`/document/${consentForm?.sourceDocuments?.find(x => x.fileTypeId === 6)?.file.id}`}
                    target= "_blank"
                >
                    {
                        !disabled && <Typography>
                                        {consentForm?.sourceDocuments?.find(x => x.fileTypeId === 6)?.name}
                                        <FontAwesomeIcon icon={faEye} fixedWidth style={{ marginLeft: '0.5rem' }} />
                                    </Typography>
                    }

                </Link>

            ),
            fieldName: 'servicesAustraliaConsentFile'
        },
        {
            columnName: 'version',
            content: (
                <Input
                    name='servicesAustraliaConsentVersion'
                    component={Text}
    
                />
            ),
            fieldName: 'servicesAustraliaConsentVersion'
        },
        {
            columnName: 'date',
            content: (
                <Input
                    name='servicesAustraliaConsentDate'
                    component={KeyboardDatePicker}
    
                />
            ),
            fieldName: 'servicesAustraliaConsentDate'
        },
        {
            columnName: 'currentTMDVersion',
            content: (
                <Input
                    component={ToggleButtonGroup}
                />
            ),
            fieldName: 'servicesAustraliaConsentCurrentTMDVersion'
        },
        {
            columnName: 'deleteConsent',
            content: (
                <CrfCondition
                    type={Dtos.ReviewConsent}
                    condition={hasNoToAnyAbove}
                    mode="Enable"
                    subscription={{ values: true }}
                >
                    <Input

                        component={ToggleButtonGroup}
                        />
                </CrfCondition>
            ),
            fieldName: 'deleteSAConsent'
        }
    ];
    //const [australianConsentQuestionRequired, setAustralianConsentQuestionRequired] = React.useState(false);

    // these are for whether to show the row in the table or not
    const showAustralianConsent = React.useMemo(() => {
        //setAustralianConsentQuestionRequired((theCurrentForm?.servicesAustraliaConsentCurrentTMDVersion != null && disabled || hasUserSubmittedAustralianConsent && !disabled || !theCurrentForm && disabled && formInitial?.servicesAustraliaConsentCurrentTMDVersion != null))
                      // History.. what to display?                                           ||       // Fill out stage.. what to display?     ||       // New consent form required so its disabled but it also pulls from different area
        return (theCurrentForm?.servicesAustraliaConsentCurrentTMDVersion != null && disabled || hasUserSubmittedAustralianConsent && !disabled || !theCurrentForm && disabled && formInitial?.servicesAustraliaConsentCurrentTMDVersion != null);
    }, [theCurrentForm, disabled, hasUserSubmittedAustralianConsent, formInitial]);

    const showOptionalConsent = React.useMemo(() => {
        return (theCurrentForm?.optionalConsentCurrentTMDVersion != null && disabled || hasUserSubmittedOptionalConsent && !disabled || !theCurrentForm && disabled && formInitial?.optionalConsentCurrentTMDVersion != null);
    }, [theCurrentForm, disabled, hasUserSubmittedOptionalConsent, formInitial]);

    const showSecondOptionalConsent = React.useMemo(() => {
        return (theCurrentForm?.optionalConsentCurrentSecondTMDVersion != null && disabled || showOptionalConsent && hasUserSubmittedMoreOptionalConsent && !disabled || !theCurrentForm && disabled && formInitial?.optionalConsentCurrentSecondTMDVersion != null);
    }, [theCurrentForm, showOptionalConsent]);

    const showMainConsent = React.useMemo(() => {

        return (theCurrentForm?.mainConsentCurrentTMDVersion != null && disabled || hasUserSubmittedMainConsent && !disabled || !theCurrentForm && disabled && formInitial?.mainConsentCurrentTMDVersion != null);
    }, [theCurrentForm, disabled, hasUserSubmittedMainConsent, formInitial]);


    // useMemo is like caching. only runs if consent history changes

    // this is creating a new state for the json input which will remove the old data where the form has not been completed.
    // it will also remove anything irrelivant like data double entered.
    const reducedConsentHistory = React.useMemo(() => {
        if (!consentHistory || consentHistory.length === 0) {
            return [];
        }

        return consentHistory.reduce<Dtos.ConsentHistorySDV[]>((state, currentItem) => {
            let previous = state.length !== 0 ? state[state.length - 1] : undefined;

            // if fields I care about aren't all undefined. (as old saves before this is implemented have null)
            if (currentItem.mainConsentCurrentTMDVersion !== undefined) { // need to and an && maybe OR DOC VERSION IS DIFFERENT AND ALL OF THE YES  NOS ARE THE SAME
                state.push(currentItem);
            }

            //state.reverse();
            // if different from last one

            return state;

        }, []);
    }, [consentHistory]);

    // This loops through the JSON array
    // d is the data as you loop and i is the index
    const dropDownRow = reducedConsentHistory?.map((d, i) => {
        return (
            // returns a number of menu items dependant on the ammount of objects in the array
            // the value links back to the code so it knows what is selected

            <MenuItem key={i} value={i}>{DateTime.fromISO(d.modifiedDate!).toFormat('ff')}</MenuItem>

            //<MenuItem key={i} value={i}>{DateTime.fromISO(d.modifiedDate).toFormat('ff')}</MenuItem>
        );
    });

    return (
        <>
            {
                <Dialog open={dialogOpen} onClose={onCancel} aria-labelledby="form-dialog-title" fullWidth={true} scroll="body" maxWidth="lg" disableBackdropClick>
                    <Typography
                        variant="h4"
                        color="primary"
                        className={classes.dialogTitle}
                    >
                        Confirm and delete consent form for patient {patient?.studyNumber}?
                    </Typography>
                    <Form
                        initialValues={theCurrentForm ?? formInitial}
                        onValidate={validate}
                        validateOn="onChange"
                        allowSubmit={allowSubmit}
                        fieldErrors={'default'}
                        onSubmit={handleSubmit}
                        labels={labels}
                        lookups={processedLookups ?? []}
                        onSubmitFailed={onFormSubmitFailure}
                        onSubmitValidationFailed={onFormSubmitValidationFailure}
                        readOnly={disabled}
                    >
                       

                        <DialogContent className={classes.dialogContent}>

                            {/*CSS*/}
                            <div className={classes.historyDropDown}>
                                {/*Dropdown Box*/}
                                {/* (Selection) if current form exists get index of it, if not then identifier is "new"*/}
                                <Selector
                                    label="History"           
                                    selection={!!theCurrentForm ? reducedConsentHistory.indexOf(theCurrentForm).toString() : 'New'}
                                    inputId={!!theCurrentForm ? reducedConsentHistory.indexOf(theCurrentForm).toString() : 'New'}

                                    onSelectionChanged={(code) => {
                                        //if there is nothing in the selection OR it == New then set form to not a history
                                        if (code === '' || code === null || code === undefined || code === 'New') {
                                            setTheCurrentForm(undefined)
                                        }
                                        // else set the form to the history array of whichever dropdown is selected
                                        else {
                                            setTheCurrentForm(reducedConsentHistory[+code])
                                        }
                                    }}
                                >
                                    {/*base menu item*/}

                                    {
                                        disabled && formInitial.reviewedDate !== undefined ? 
                                            <MenuItem key={formInitial.reviewedDate} value={'New'}>{DateTime.fromISO(formInitial.reviewedDate).toFormat('ff')}</MenuItem> :
                                            <MenuItem key={'0'} value={'New'}>New</MenuItem> 
                                    }
                                    

                                    {/*Menu items made dependant on history*/}
                                    {dropDownRow}
                                </Selector>
                            </div>


                            <FieldGroup paddingBottom={0}>
                                <StaticGrid
                                    headers={filesHeader}
                                    stickyHeader={true}
                                    >
                                        {
                                            showMainConsent ?
                                                <StaticGridRow cells={mainConsentRow} /> :
                                                null
                                        }
                                        {
                                            showOptionalConsent ?
                                                <StaticGridRow cells={optionalConsentRow} /> :
                                                null
                                        } 
                                        {
                                            showSecondOptionalConsent ?
                                                <StaticGridRow cells={optionalConsentSecondRow} /> :
                                                null
                                        }                                   
                                        {
                                            showAustralianConsent ?
                                                <StaticGridRow cells={servicesAustraliaConsentRow} /> :
                                                null
                                        }

                                        
                                </StaticGrid>
                            </FieldGroup>

                            <FieldGroup>
                            <div
                                style={{
                                    paddingTop: "12px",
                                    paddingBottom: "24px",
                                    paddingLeft: '24px',
                                    paddingRight: '24px'
                                }}
                            >
                                <Typography
                                    variant='body2'
                                >
                                    <i>Please ensure that the site has uploaded the Main Consent form and Optional Tissue Banking and FUR form. Additionally, if the patient has consented to the SA consent form, please check this has also been uploaded. If the patient has not consented to the SA consent, then this document is not required to be uploaded for SDV.</i>
                                </Typography>
                            </div>

                                <Field
                                    label='TMF has a copy of all current PICFs and approved by the HREC/RGO'
                                    name='currentPicfsApproved'
                                    component={ToggleButtonGroup}
                                    sm={12}
                                    md={7}
                                    xl={7}
                                    lg={7}
                                    
                                />

                            </FieldGroup>
                            
                            <Field
                                label='Patient signed the correct version of PICFs'
                                name='patientSignedPicfs'
                                component={ToggleButtonGroup}
                                sm={12}
                                md={7}
                                xl={7}
                                lg={7}
                                    
                            />

                            <Field
                                label='All pages are present (Cross check the no. of pages of consent uploaded in TMD)'
                                name='allPagesPresent'
                                component={ToggleButtonGroup}
                                sm={12}
                                md={7}
                                xl={7}
                                lg={7}
                                    
                            />

                            <Field
                                label='Investigator signed the PICF on the same day or discrepancy explained in a file note/documentation'
                                name='investigatorSigned'
                                component={ToggleButtonGroup}
                                sm={12}
                                md={7}
                                xl={7}
                                lg={7}
                                    
                            />

                            <CrfCondition
                                type={Dtos.ReviewConsent}
                                condition={() => showAustralianConsent}
                                mode="Enable"
                                subscription={{ values: true }}
                                >
                                <Field
                                    label='SA consent is fully completed (check Medicare number has 9 digits, initials match the name, DOB matches and address is complete)'
                                    name='saConsentFullyChecked'
                                    component={ToggleButtonGroup}
                                    sm={12}
                                    md={7}
                                    xl={7}
                                    lg={7}
                                        
                                />
                            </CrfCondition>

                            <Field
                                label='The site personnel who signed the consent form is authorized and signature matches signature on file'
                                name='personWhoSignedAuth'
                                component={ToggleButtonGroup}
                                sm={12}
                                md={7}
                                xl={7}
                                lg={7}
                                    
                            />

                            <Field
                                label="Name of the site personnel who signed the consent form"
                                name='nameOfPersonWhoSigned'
                                component={Text}
                                sm={12}
                                md={7}
                                xl={7}
                                lg={7}
                                simpleLabel
                            />

                            <CrfCondition
                                type={Dtos.ReviewConsent}
                                condition={hasNoToAnyAbove}
                                mode="Enable"
                                subscription={{ values: true }}
                            >
                                <Field
                                    label='If no to any of the above, please comment'
                                    name="ifNoToAnyAboveComment"
                                    component={TextArea}
                                    sm={12}
                                    md={7}
                                    xl={7}
                                    lg={7}
                                    variant="outlined"
                                />
                            </CrfCondition>

                            <Field
                                label='Additional Comments'
                                name="comments"
                                component={TextArea}
                                sm={12}
                                md={7}
                                xl={7}
                                lg={7}
                                variant="outlined"
                            />

                            <Field
                                name="reviewedBy"
                                component={Text}
                                sm={12}
                                md={7}
                                xl={7}
                                lg={7}
                                simpleLabel
                            />
                            <Field
                                name="reviewedDate"
                                component={KeyboardDatePicker}
                                sm={12}
                                md={7}
                                xl={7}
                                lg={7}
                                simpleLabel
                            />

                        </DialogContent>

                        <DialogActions>
                            <Button onClick={onCancel} color="secondary" disabled={false}>
                                Cancel
                            </Button>
                            
                            {
                                !disabled && <DialogSaveButton />
                            }

                            {/*This stops button from appearing when disabled*/}
                            {
                                !disabled && <DialogSubmitButton lookups={processedLookups as any} />
                            }

                        </DialogActions>

                    </Form>
                </Dialog>
            }
        </>
    );
}

export default ConsentReviewDialogWrapper;