import { AssayResultPdfError } from "../api/dtos";

const parseAssayResultPdfError = (assayResultPdfError: AssayResultPdfError):string => {
    const errors: string[] = [];

    if ((assayResultPdfError & AssayResultPdfError.SampleIdDoesNotMatch) === AssayResultPdfError.SampleIdDoesNotMatch) {
        errors.push(`the uploaded pdf didn't have a sample id that matches the current patient`);
    }

    if ((assayResultPdfError & AssayResultPdfError.NoReportDate) === AssayResultPdfError.NoReportDate) {
        errors.push(`the report date couldn't be read`);
    }

    if ((assayResultPdfError & AssayResultPdfError.NoAssayResult) === AssayResultPdfError.NoAssayResult) {
        errors.push(`the assay result couldn't be read`);
    }

    if ((assayResultPdfError & AssayResultPdfError.NoSubtype) === AssayResultPdfError.NoSubtype) {
        errors.push(`the subtype couldn't be read`);
    }

    if ((assayResultPdfError & AssayResultPdfError.NoRiskCategory) === AssayResultPdfError.NoRiskCategory) {
        errors.push(`the risk category couldn't be read`);
    }

    if ((assayResultPdfError & AssayResultPdfError.NoRorScore) === AssayResultPdfError.NoRorScore) {
        errors.push(`the ROR score couldn't be read`);
    }

    if ((assayResultPdfError & AssayResultPdfError.BadFile) === AssayResultPdfError.BadFile) {
        errors.push('the uploaded pdf was not in the correct format');
    }

    return errors.join(', ');
}

export default parseAssayResultPdfError;