import { OnlinePatientManagement } from '@ngt/opms';
import { BctApiExtension } from '@ngt/opms-bctapi';
import { ScreeningLogExtension } from '@ngt/opms-screeninglog';
import { EConsentExtension } from '@ngt/opms-econsent';
import { RequestState } from '@ngt/request-utilities';

import * as Dtos from './api/dtos';

import bctLogo from './assets/img/bct.png';
import { responsiveFontSizes } from '@material-ui/core';
import { authenticatedUserActions, IAuthenticatedUserStore } from '@ngt/opms/dist/store/modules/authenticatedUser';
import { permissionsActions } from '@ngt/opms-bctapi/dist/store/modules/utility/permissions';

declare let global: any;

if (typeof global === 'undefined') {
    (window as any).global = window;
}

const user = global.AUTH as Dtos.AuthenticateResponse;
const formMetadata = global.FORM_METADATA as Dtos.GetFormMetadataResponse;
const eConsentSettings = global.ECONSENT_SETTINGS as Dtos.GetEConsentSettingsResponse;
const publicRoute = [eConsentSettings?.trialInfoPageUrl, eConsentSettings?.trialConsentPageUrl].includes(global.location.href.substr(0, global.location.href.lastIndexOf("/") + 1));

if (!user) {
    if (publicRoute) {

    } else if (global.location.search) {
        global.location.href = '/auth/openiddict' + global.location.search;
    } else {
        global.location.href = '/auth/openiddict';
    }
}

const onlinePatientManagement = new OnlinePatientManagement({
    trialOptions: {
        organisationLogo: bctLogo,
        organisationName: "Breast Cancer Trials",
        trialName: "OPTIMA Trial"
    },
    dtos: Dtos,
    storeOptions: {
        preloadState: {
            authenticatedUser: {
                user,
                loadState: {
                    state: RequestState.Success
                }
            }
        }
    },
    themeOptions: {
        beforeThemeCreated: (themeOptions: any) => {
            return {
                palette: {
                    primary: {
                        main: '#562c85'
                    },
                    secondary: {
                        main: '#e8078c'
                    }
                },
                typography: themeOptions.typography
            };
        },
        afterThemeCreated: (theme: any) => {
            return responsiveFontSizes(theme);
        }
    },
    formMetadata: formMetadata?.formMetadata,
    extensions: [
        new BctApiExtension({

        }),
        new EConsentExtension({
            trialInfoPageUrl: eConsentSettings?.trialInfoPageUrl,
            trialConsentPageUrl: eConsentSettings?.trialConsentPageUrl,
            trialConsentSignedPageUrl: eConsentSettings?.trialConsentSignedPageUrl,
            noInviteOption: true
        }),
        new ScreeningLogExtension({

        })
    ]
});

onlinePatientManagement.serviceStackClient.responseFilter = (res: Response) => {
    const state = onlinePatientManagement.store.getState() as IAuthenticatedUserStore;

    if (publicRoute) {

    }
    else if (res.headers.has('openiddict-user-status') &&
        res.headers.get('openiddict-user-status') === 'unauthenticated' &&
        state.authenticatedUser.user) {
        onlinePatientManagement.store.dispatch(authenticatedUserActions.signoutAuthenticatedUser())
    }
    else if (res.headers.has('openiddict-user-update') &&
        res.headers.get('openiddict-user-update') === 'true' &&
        state.authenticatedUser.loadState.state !== RequestState.Pending) {
        console.log('user updated');
        console.log(res);
        onlinePatientManagement.store.dispatch(authenticatedUserActions.checkAuthenticatedUser());
        onlinePatientManagement.store.dispatch(permissionsActions.refresh());
    }
}

onlinePatientManagement.serviceStackClient.exceptionFilter = (res: Response, error: any) => {
    if (res.status === 401 && !publicRoute) {
        global.location.href = `/auth/openiddict?redirect=${encodeURIComponent(global.location.pathname + global.location.search)}`;
    }
};

export default onlinePatientManagement;