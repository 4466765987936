/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * This file contains the component that provides context for the online patient
 * management system.
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */

/**
 * Required to use React components.
 */
import * as React from 'react';

/*
 * Used to style components
 */
import { Grid, InputAdornment, makeStyles, Typography, useMediaQuery, useTheme } from '@material-ui/core';

/**
 * Used for the basic page layout.
 */
import {
    CollaboratingGroupContext, CrfCondition, Field, FieldGroup, FormBreadcrumbs,
    FormContext, FormGrid, getParentPropertyPath, ICrfConditionParameters, IFormGridCell,
    Input, InstitutionContext, MasterGroupContext, Numeric, PatientContext, ProForm, RouteLoading, Select, Text, TextArea, ToggleButtonGroup, useAuthenticatedUser, UserType,
    GetFieldLookupItem,
    ICrfConditionProps,
    GetFieldLookup,
    FormLookupsContext,
    ConditionContext,
    ILookup,
    KeyboardDatePicker,
    IFormSubscription,
    Checkbox
} from '@ngt/opms';

import { usePermissionsByIds, Permission } from '@ngt/opms-bctapi';

import { RequestState } from '@ngt/request-utilities';

/*
 * ----------------------------------------------------------------------------------
 * Imports - Internal
 * ----------------------------------------------------------------------------------
 */

/*
 * Used to type patient state.
 */
import * as Dtos from '../../api/dtos';

import { get } from 'lodash-es';
import { useHistory } from 'react-router-dom';
import Footer from './Footer';
import { DefaultFooterPosition } from '../../constants/proForms';
import { useDefaultStepsProps } from '../../hooks/useDefaultStepsProps';
import SourceDocuments from '../../components/SourceDocuments';

/*
 * ----------------------------------------------------------------------------------
 * Interface
 * ----------------------------------------------------------------------------------
 */

interface IAdditionalQuestions {
}

/*
 * ---------------------------------------------------------------------------------
 * Functions
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles(theme => ({
    container: {
        padding: theme.spacing(3)
    },
    subHeading: {
        padding: theme.spacing(3, 3, 3, 3)
    },
    subDescription: {
        padding: theme.spacing(0, 3, 3, 3)
    },
    instruction: {
        padding: theme.spacing(0, 3, 0),
        fontSize: '1rem'
    }
}));

/*
 * ----------------------------------------------------------------------------------
 * Constants
 * ----------------------------------------------------------------------------------
 */

const outpatientTypeOther = ({ lookups, formState, parentFieldName }: ICrfConditionParameters<Dtos.OutpatientService>) => {
    // parent field name will be the cell in the grid, we want to get the row, so get the parent of the parent.
    const parentPropertyPath = getParentPropertyPath(parentFieldName);

    if (!parentPropertyPath) {
        return false;
    }

    const row: Dtos.OutpatientService = get(formState?.values, parentPropertyPath);

    if (!row) {
        return false;
    }

    return row.type === 9;
};

const communityTypeOther = ({ formState, parentFieldName }: ICrfConditionParameters<Dtos.CommunityService>) => {
    // parent field name will be the cell in the grid, we want to get the row, so get the parent of the parent.
    const parentPropertyPath = getParentPropertyPath(parentFieldName);

    if (!parentPropertyPath) {
        return false;
    }

    const row: Dtos.CommunityService = get(formState?.values, parentPropertyPath);

    if (!row) {
        return false;
    }

    return row.type === 9;
};

const testTypeOther = ({ formState, parentFieldName }: ICrfConditionParameters<Dtos.Test>) => {
    // parent field name will be the cell in the grid, we want to get the row, so get the parent of the parent.
    const parentPropertyPath = getParentPropertyPath(parentFieldName);

    if (!parentPropertyPath) {
        return false;
    }

    const row: Dtos.Test = get(formState?.values, parentPropertyPath);

    if (!row) {
        return false;
    }

    return row.description === 9;
};

const hospitalServicesUsedIn3MonthsCondition = ({ lookups, formState, parentFieldName }: ICrfConditionParameters<Dtos.HospitalService>) => {
    // parent field name will be the cell in the grid, we want to get the row, so get the parent of the parent.
    const parentPropertyPath = getParentPropertyPath(parentFieldName);

    if (!parentPropertyPath) {
        return false;
    }

    const row: Dtos.HospitalService = get(formState?.values, parentPropertyPath);

    if (!row) {
        return false;
    }

    return GetFieldLookupItem(lookups, 'hospitalServices.usedIn3Months', row.usedIn3Months)?.type === Dtos.LookupYesNoType.Yes;
};

const outpatientServicesUsedIn3MonthsCondition = ({ lookups, formState, parentFieldName }: ICrfConditionParameters<Dtos.OutpatientService>) => {
    // parent field name will be the cell in the grid, we want to get the row, so get the parent of the parent.
    const parentPropertyPath = getParentPropertyPath(parentFieldName);

    if (!parentPropertyPath) {
        return false;
    }

    const row: Dtos.OutpatientService = get(formState?.values, parentPropertyPath);

    if (!row) {
        return false;
    }

    return GetFieldLookupItem(lookups, 'outpatientServices.usedIn3Months', row.usedIn3Months)?.type === Dtos.LookupYesNoType.Yes;
};

const communityServicesUsedIn3MonthsCondition = ({ lookups, formState, parentFieldName }: ICrfConditionParameters<Dtos.OutpatientService>) => {
    // parent field name will be the cell in the grid, we want to get the row, so get the parent of the parent.
    const parentPropertyPath = getParentPropertyPath(parentFieldName);

    if (!parentPropertyPath) {
        return false;
    }

    const row: Dtos.OutpatientService = get(formState?.values, parentPropertyPath);

    if (!row) {
        return false;
    }

    return GetFieldLookupItem(lookups, 'communityServices.usedIn3Months', row.usedIn3Months)?.type === Dtos.LookupYesNoType.Yes;
};

const valuesConditionSubscription: Partial<IFormSubscription> = { values: true };

const ClearMeFieldsBoi: React.FC<{ name: string }> = ({ name }) => {
    const { addName, removeName } = React.useContext(ConditionContext) ?? {};

    React.useEffect(() => {
        addName?.(name);

        return () => {
            removeName?.(name);
        }
    }, [addName, removeName, name]);


    return null;
}

const hospitalServicesColumns: Array<IFormGridCell<Dtos.HospitalService>> = [
    {
        name: 'type',
        minWidth: 250,
        maxWidth: 250,
        width: 250,
        content: (
            <Input
                component={Text}
                inputProps={{ readOnly: true }}
            />
        )
    },
    {
        name: 'usedIn3Months',
        minWidth: 200,
        maxWidth: 200,
        width: 200,
        content: (
            <Input
                component={ToggleButtonGroup}
            />
        )
    },
    {
        name: 'numberOfDays',
        minWidth: 200,
        maxWidth: 200,
        width: 200,
        content: (
            <CrfCondition
                type={Dtos.HospitalService}
                condition={hospitalServicesUsedIn3MonthsCondition}
                mode="Enable"
                subscription={valuesConditionSubscription}
            >
                <ClearMeFieldsBoi name="hospitalServices[0].numberOfDays" />
                <Input
                    component={Text}
                />
            </CrfCondition>
        )
    }
];

const outpatientServicesColumns: Array<IFormGridCell<Dtos.OutpatientService>> = [
    {
        name: 'type',
        minWidth: 250,
        maxWidth: 250,
        width: 250,
        content: (
            <Input
                component={Select}
            />
        )
    },
    {
        name: 'specifyType',
        minWidth: 250,
        maxWidth: 250,
        width: 250,
        content: (
            <CrfCondition
                type={Dtos.OutpatientService}
                condition={outpatientTypeOther}
                mode="Enable"
                subscription={valuesConditionSubscription}
            >
                <Input
                    component={Text}
                />
            </CrfCondition>
        )
    },
    {
        name: 'usedIn3Months',
        minWidth: 200,
        maxWidth: 200,
        width: 200,
        content: (
            <Input
                component={ToggleButtonGroup}
            />
        )
    },
    {
        name: 'numberOfVisits',
        minWidth: 200,
        maxWidth: 200,
        width: 200,
        content: (
            <CrfCondition
                type={Dtos.OutpatientService}
                condition={outpatientServicesUsedIn3MonthsCondition}
                mode="Enable"
                subscription={valuesConditionSubscription}
            >
                <Input
                    component={Numeric}
                />
            </CrfCondition>
        )
    },
    {
        name: 'numberOfCalls',
        minWidth: 200,
        maxWidth: 200,
        width: 200,
        content: (
            <CrfCondition
                type={Dtos.OutpatientService}
                condition={outpatientServicesUsedIn3MonthsCondition}
                mode="Enable"
                subscription={valuesConditionSubscription}
            >
                <Input
                    component={Numeric}
                />
            </CrfCondition>
        )
    }
];

const testsColumns: Array<IFormGridCell<Dtos.Test>> = [
    {
        name: 'description',
        minWidth: 250,
        maxWidth: 250,
        width: 250,
        content: (
            <Input
                component={Select}
            />
        )
    },
    {
        name: 'specifyDescription',
        minWidth: 250,
        maxWidth: 250,
        width: 250,
        content: (
            <CrfCondition
                type={Dtos.Test}
                condition={testTypeOther}
                mode="Enable"
                subscription={valuesConditionSubscription}
            >
                <Input
                    component={Text}
                />
            </CrfCondition>
        )
    },
    {
        name: 'number',
        minWidth: 200,
        maxWidth: 200,
        width: 200,
        content: (
            <Input
                component={Numeric}
            />
        )
    }
];

const communityServicesColumns: Array<IFormGridCell<Dtos.CommunityService>> = [
    {
        name: 'type',
        minWidth: 250,
        maxWidth: 250,
        width: 250,
        content: (
            <Input
                component={Select}
            />
        )
    },
    {
        name: 'specifyType',
        minWidth: 250,
        maxWidth: 250,
        width: 250,
        content: (
            <CrfCondition
                type={Dtos.CommunityService}
                condition={communityTypeOther}
                mode="Enable"
                subscription={valuesConditionSubscription}
            >
                <Input
                    component={Text}
                />
            </CrfCondition>
        )
    },
    {
        name: 'usedIn3Months',
        minWidth: 200,
        maxWidth: 200,
        width: 200,
        content: (
            <Input
                defaultValue={1}
                component={ToggleButtonGroup}
            />
        )
    },
    {
        name: 'numberOfClinicalVisits',
        minWidth: 200,
        maxWidth: 200,
        width: 200,
        content: (
            <CrfCondition
                type={Dtos.CommunityService}
                condition={communityServicesUsedIn3MonthsCondition}
                mode="Enable"
                subscription={valuesConditionSubscription}
            >
                <Input
                    component={Numeric}
                />
            </CrfCondition>
        )
    },
    {
        name: 'numberOfHomeVisits',
        minWidth: 200,
        maxWidth: 200,
        width: 200,
        content: (
            <CrfCondition
                type={Dtos.CommunityService}
                condition={communityServicesUsedIn3MonthsCondition}
                mode="Enable"
                subscription={valuesConditionSubscription}
            >
                <Input
                    component={Numeric}
                />
            </CrfCondition>
        )
    },
    {
        name: 'numberOfCalls',
        minWidth: 200,
        maxWidth: 200,
        width: 200,
        content: (
            <CrfCondition
                type={Dtos.CommunityService}
                condition={communityServicesUsedIn3MonthsCondition}
                mode="Enable"
                subscription={valuesConditionSubscription}
            >
                <Input
                    component={Numeric}
                />
            </CrfCondition>
        )
    }
];

const medicationsColumns: Array<IFormGridCell<Dtos.Medication>> = [
    {
        name: 'drugName',
        minWidth: 250,
        maxWidth: 250,
        width: 250,
        content: (
            <Input
                component={Text}
                placeholder="Example: Atenolol"
            />
        )
    },
    {
        name: 'dose',
        minWidth: 200,
        maxWidth: 200,
        width: 200,
        content: (
            <Input
                component={Text}
                placeholder="50 mg"
            />
        )
    },
    {
        name: 'frequency',
        minWidth: 200,
        maxWidth: 200,
        width: 200,
        content: (
            <Input
                component={Text}
                placeholder="Once day"
            />
        )
    },
    {
        name: 'prescription',
        minWidth: 200,
        maxWidth: 200,
        width: 200,
        content: (
            <Input
                component={ToggleButtonGroup}
            />
        )
    }
];

const otherExpensesColumns: Array<IFormGridCell<Dtos.OtherExpense>> = [
    {
        name: 'description',
        minWidth: 500,
        maxWidth: 500,
        width: 500,
        content: (
            <Input
                component={Text}
            />
        )
    },
    {
        name: 'totalCost',
        minWidth: 200,
        maxWidth: 200,
        width: 200,
        content: (
            <Input
                component={Numeric}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <Typography color="textSecondary">$&nbsp;</Typography>
                        </InputAdornment>
                    )
                }}
            />
        )
    }
];

const canNotDelete = () => false;

/*
 * ----------------------------------------------------------------------------------
 * Components
 * ----------------------------------------------------------------------------------
 */

const permissions: Permission[] = [
    Permission.OpmsProUpdate,
    Permission.OpmsProAdminister
];

const onAddHospitalService = (values?: Dtos.HospitalService[] | null) => {
    const newDocument = {
        type: 'Hospital inpatient stay (24 hours)'
    } as Dtos.HospitalService

    return [...(values ?? []), newDocument];
};

const HospitalServicesTable: React.FunctionComponent = () => {
    const classes = useStyles();

    return (
        <>
            <Typography
                variant="h3"
                color="secondary"
                className={classes.subHeading}
            >
                HOSPITAL SERVICES
            </Typography>
            <Typography className={classes.subDescription}>
                This refers to any contacts you make with the hospital over the last 3 months including those due to any health problems, not just your cancer and its treatment. This includes overnight stays in hospital, outpatient visits, telephone calls to hospital-based health professionals and physiotherapy for example. (You do not need to include chemotherapy or radiotherapy treatment visits.)
            </Typography>
            <Typography className={classes.subDescription}>Please specify if you have had a hospital inpatient stay in the past 3 months:</Typography>
            <FormGrid
                type={Dtos.HospitalService}
                name="hospitalServices"
                columns={hospitalServicesColumns}
                minRow={1}
                allowAdd={false}
                allowDelete={canNotDelete}
                onAdd={onAddHospitalService}
            />
        </>
    );
}

const OutpatientServicesTable: React.FunctionComponent = () => {
    const classes = useStyles();
    const lookups = React.useContext(FormLookupsContext);

    const outpatientServicesTypeLookup = React.useMemo(() => {
        const lookup = GetFieldLookup(lookups, 'outpatientServices.type');

        //copy the lookup but remove the other type
        //we want to prefill everything except for other, but still let people add it if they want
        return {
            ...lookup,
            items: lookup?.items.filter(i => i.type !== Dtos.OutpatientServiceType.Other)
        } as ILookup;
    }, [lookups]);

    const onAddCallBack = React.useCallback((values?: Dtos.OutpatientService[] | null) => {
        const newDocument = {
            type: outpatientServicesTypeLookup?.items[values?.length ?? 0]?.id
        } as Dtos.OutpatientService;

        return [...(values ?? []), newDocument];
    }, [outpatientServicesTypeLookup]);

    return (
        <>
            <Typography
                variant="h3"
                color="secondary"
                className={classes.subHeading}
            >
                OUTPATIENT SERVICES
            </Typography>
            <Typography className={classes.subDescription}>
                Please specify all outpatient services you have used over the last 3 months including those due to any health problems, not just your cancer and its treatment.
            </Typography> 
            <FormGrid
                type={Dtos.OutpatientService}
                name="outpatientServices"
                columns={outpatientServicesColumns}
                onAdd={onAddCallBack}
                minRow={outpatientServicesTypeLookup?.items?.length}
            />
        </>
    );
}

const TestsTable: React.FunctionComponent = () => {
    const classes = useStyles();
    const lookups = React.useContext(FormLookupsContext);

    const testTypeLookup = React.useMemo(() => {
        const lookup = GetFieldLookup(lookups, 'tests.description');

        //copy the lookup but remove the other type
        //we want to prefill everything except for other, but still let people add it if they want
        return {
            ...lookup,
            items: lookup?.items.filter(i => i.type !== Dtos.TestType.Other)
        } as ILookup;
    }, [lookups]);

    const onAddCallBack = React.useCallback((values?: Dtos.Test[] | null) => {
        const newDocument = {
            description: testTypeLookup?.items[values?.length ?? 0]?.id
        } as Dtos.Test;

        return [...(values ?? []), newDocument];
    }, [testTypeLookup]);

    return (
        <>
            <Typography
                variant="h3"
                color="secondary"
                className={classes.subHeading}
            >
                TESTS
            </Typography>
            <Typography className={classes.subDescription}>
                Please specify any tests or scan performed in the hospital over the last 3 months including those due to any health problems, not just your cancer and its treatment. (e.g. x-ray, CT-scan but not blood tests).
            </Typography>
            <FormGrid
                type={Dtos.Test}
                name="tests"
                columns={testsColumns}
                onAdd={onAddCallBack}
                minRow={testTypeLookup?.items?.length}
            />
        </>
    );
}

const CommunityServicesTable: React.FunctionComponent = () => {
    const classes = useStyles();
    const lookups = React.useContext(FormLookupsContext);

    const communityServicesTypeLookup = React.useMemo(() => {
        const lookup = GetFieldLookup(lookups, 'communityServices.type');

        //copy the lookup but remove the other type
        //we want to prefill everything except for other, but still let people add it if they want
        return {
            ...lookup,
            items: lookup?.items.filter(i => i.type !== Dtos.CommunityServiceType.Other)
        } as ILookup;
    }, [lookups]);

    const onAddCallBack = React.useCallback((values?: Dtos.CommunityService[] | null) => {
        const newDocument = {
            type: communityServicesTypeLookup?.items[values?.length ?? 0]?.id
        } as Dtos.CommunityService;

        return [...(values ?? []), newDocument];
    }, [communityServicesTypeLookup]);

    return (
        <>
            <Typography
                variant="h3"
                color="secondary"
                className={classes.subHeading}
            >
                COMMUNITY SERVICES
            </Typography>
            <Typography className={classes.subDescription}>
                This refers to all health care and social care that is not based in the hospital over the last 3 months including those due to any health problems, not just your cancer and its treatment. This includes for example your GP, practice or community nurse, social worker, home help, or physiotherapist who is not based in the hospital.
            </Typography>
            <FormGrid
                type={Dtos.CommunityService}
                name="communityServices"
                columns={communityServicesColumns}
                onAdd={onAddCallBack}
                minRow={communityServicesTypeLookup?.items?.length}
            />
        </>
    );
}

const MedicationsTable: React.FunctionComponent = () => {
    const classes = useStyles();

    return (
        <>
            <Typography
                variant="h3"
                color="secondary"
                className={classes.subHeading}
            >
                MEDICATIONS
            </Typography>
            <Typography className={classes.subDescription}>
                What medications have you taken <b>this week</b> (last 7 days)? (not including chemotherapy)
            </Typography>
            <FormGrid
                type={Dtos.Medication}
                name="medications"
                columns={medicationsColumns}
            />
        </>
    );
}

const OtherExpensesTable: React.FunctionComponent = () => {
    const classes = useStyles();

    return (
        <>
            <Typography
                variant="h3"
                color="secondary"
                className={classes.subHeading}
            >
                OTHER EXPENSES
            </Typography>
            <Typography className={classes.subDescription}>
                Have you personally incurred any other expenses due to your health or treatment over <b>the last 3 months?</b> (e.g. home adaptations, extra laundry, cleaning services)
            </Typography>
            <FormGrid
                type={Dtos.OtherExpense}
                name="otherExpenses"
                columns={otherExpensesColumns}
            />
        </>
    );
}

const employedCondition: ICrfConditionProps<Dtos.Additional>['condition'] = ({ formState, lookups }) => {
    return GetFieldLookupItem(lookups, 'employed', formState?.values.employed)?.type === Dtos.LookupYesNoType.Yes;
};

const reductionInIncomeCondition: ICrfConditionProps<Dtos.Additional>['condition'] = ({ formState, lookups }) => {
    return GetFieldLookupItem(lookups, 'reductionInIncome', formState?.values.reductionInIncome)?.type === Dtos.LookupYesNoType.Yes;
};

const familyFriendsSupportCondition: ICrfConditionProps<Dtos.Additional>['condition'] = ({ formState, lookups }) => {
    return GetFieldLookupItem(lookups, 'familyFriendsSupport', formState?.values.familyFriendsSupport)?.type === Dtos.LookupYesNoType.Yes;
};

const timeOffWorkToSupportCondition: ICrfConditionProps<Dtos.Additional>['condition'] = ({ formState, lookups }) => {
    return GetFieldLookupItem(lookups, 'timeOffWorkToSupport', formState?.values.timeOffWorkToSupport)?.type === Dtos.LookupYesNoType.Yes;
};



const Additional: React.FunctionComponent<IAdditionalQuestions> = () => {
    const classes = useStyles();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isExtraSmall = useMediaQuery(theme.breakpoints.down('xs'));

    const [user] = useAuthenticatedUser();

    const isPatient = React.useMemo(() => {
        return user?.type === UserType.Patient;
    }, [user]);

    const showSteps = isPatient || isMobile;

    const { masterGroup } = React.useContext(MasterGroupContext);
    const { collaboratingGroup } = React.useContext(CollaboratingGroupContext);
    const { institution } = React.useContext(InstitutionContext);
    const { patient } = React.useContext(PatientContext);

    const [[canUpdatePro, canAdministerPro], permissionLoadState] = usePermissionsByIds(permissions, masterGroup?.id, collaboratingGroup?.id, institution?.id, patient?.id, true);

    const history = useHistory();
    const { form } = React.useContext(FormContext);
    const defaultStepsProps = useDefaultStepsProps();

    if (form && (form as Dtos.IProForm).completed && isPatient) {
        history.push(`/`);
    }

    if (permissionLoadState.state === RequestState.None || permissionLoadState.state === RequestState.Pending) {
        return (
            <RouteLoading />
        );
    }

    const canEdit = canUpdatePro || canAdministerPro;
    

    return (
        <>
            {
                !isPatient && (
                    <FormBreadcrumbs />
                )
            }
            <ProForm
                formType={Dtos.Additional}
                validateOn="onChange"
                useSteps={showSteps}
                canEdit={canEdit}
                canAdministerProForm={canAdministerPro}
                footer={<Footer isPatient={isPatient} />}
                footerPosition={DefaultFooterPosition}
                stepsProps={defaultStepsProps}
                showEventInHeader={true}
            >
                {
                    !isPatient && (
                        <Field
                            hideLabel
                            name="ptDidNotComplete"
                            component={Checkbox}
                            xs={12}
                            sm={12}
                            md={showSteps ? 12 : 6}
                            lg={showSteps ? 12 : 5}
                            xl={showSteps ? 12 : 5}
                        />
                    )
                }
                <FieldGroup>
                    <Typography
                        variant="h3"
                        color="secondary"
                        className={classes.subHeading}
                    >
                        COMMENTS
                    </Typography>
                    <Typography className={classes.subDescription}>We are very interested in patient reported experiences and would like to invite you to make any comments about your treatment or the study below, that you feel we or future patients should know about.</Typography>
                    <Grid
                        container
                        alignItems="center"
                    >
                        <Grid
                            item
                            xs={12}
                        >
                            <Field
                                name="comments"
                                component={TextArea}
                                rows={10}
                                rowsMax={20}
                                xs={12}
                                sm={12}
                                md={showSteps ? 12 : 6}
                                lg={showSteps ? 12 : 5}
                                xl={showSteps ? 12 : 5}
                                variant="outlined"
                            />
                        </Grid>
                    </Grid>
                </FieldGroup>
                <FieldGroup>
                    <Typography
                        variant="h3"
                        color="secondary"
                        className={classes.subHeading}
                    >
                        EMPLOYMENT AND SUPPORT
                    </Typography>
                    <Typography className={classes.subDescription}>
                        During the <b>last 3 months:</b>
                    </Typography>
                    {
                        isExtraSmall?
                            <Field
                                name= "employed"
                                component = { ToggleButtonGroup }
                                orientation = "vertical"
                                spacing={3}
                                xs = { 12 }
                                sm = { 12 }
                                md = { 12 }
                                lg = { 12 }
                                xl = { 12 }
                            /> :
                            <Field
                                name="employed"
                                component={ToggleButtonGroup}
                                xs={12}
                                sm={12}
                                md={6}
                                lg={5}
                                xl={5}
                            />
                    }
                    <CrfCondition
                        type={Dtos.Additional}
                        condition={employedCondition}
                        mode="Enable"
                        subscription={valuesConditionSubscription}
                    >
                        <Field
                            name="daysOffSickFromWork"
                            component={Text}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Typography color="textSecondary">day(s)&nbsp;</Typography>
                                    </InputAdornment>
                                ),
                                type: "number",
                                inputProps: {min: 0}
                            }}
                            xs={12}
                            sm={12}
                            md={showSteps ? 12 : 6}
                            lg={showSteps ? 12 : 5}
                            xl={showSteps ? 12 : 5}
                        />
                    </CrfCondition>
                    {
                        isExtraSmall ?
                            <Field
                                name="reductionInIncome"
                                component={ToggleButtonGroup}
                                orientation="vertical"
                                spacing={3}
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                            /> :
                            <Field
                                name="reductionInIncome"
                                component={ToggleButtonGroup}
                                xs={12}
                                sm={12}
                                md={6}
                                lg={5}
                                xl={5}
                            />
                    }
                    <CrfCondition
                        type={Dtos.Additional}
                        condition={reductionInIncomeCondition}
                        mode="Enable"
                        subscription={valuesConditionSubscription}
                    >
                        <Field
                            name="financialLossDueToTreatment"
                            component={Text}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="start">
                                        <Typography color="textSecondary">$&nbsp;</Typography>
                                    </InputAdornment>
                                ),
                                type: "number",
                                inputProps: {min: 0}
                            }}
                            xs={12}
                            sm={12}
                            md={showSteps ? 12 : 6}
                            lg={showSteps ? 12 : 5}
                            xl={showSteps ? 12 : 5}
                        />
                    </CrfCondition>
                    {
                        isExtraSmall ?
                            <Field
                                name="familyFriendsSupport"
                                component={ToggleButtonGroup}
                                orientation="vertical"
                                spacing={3}
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                            /> :
                            <Field
                                name="familyFriendsSupport"
                                component={ToggleButtonGroup}
                                xs={12}
                                sm={12}
                                md={6}
                                lg={5}
                                xl={5}
                            />
                    }
                    <CrfCondition
                        type={Dtos.Additional}
                        condition={familyFriendsSupportCondition}
                        mode="Enable"
                        subscription={valuesConditionSubscription}
                    >
                        <Field
                            name="hoursSpentHelping"
                            component={Text}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Typography color="textSecondary">hours per week&nbsp;</Typography>
                                    </InputAdornment>
                                ),
                                type: "number",
                                inputProps: {min: 0}
                            }}
                            xs={12}
                            sm={12}
                            md={showSteps ? 12 : 6}
                            lg={showSteps ? 12 : 5}
                            xl={showSteps ? 12 : 5}
                        />
                    </CrfCondition>
                    {
                        isExtraSmall ?
                            <Field
                                name="timeOffWorkToSupport"
                                component={ToggleButtonGroup}
                                orientation="vertical"
                                spacing={3}
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                            /> :
                            <Field
                                name="timeOffWorkToSupport"
                                component={ToggleButtonGroup}
                                xs={12}
                                sm={12}
                                md={6}
                                lg={5}
                                xl={5}
                            />
                    }
                    <CrfCondition
                        type={Dtos.Additional}
                        condition={timeOffWorkToSupportCondition}
                        mode="Enable"
                        subscription={valuesConditionSubscription}
                    >
                        <Field
                            name="totalTimeTakenOffWork"
                            component={Text}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Typography color="textSecondary">day(s)&nbsp;</Typography>
                                    </InputAdornment>
                                ),
                                type: "number",
                                inputProps: {min: 0}
                            }}
                            xs={12}
                            sm={12}
                            md={showSteps ? 12 : 6}
                            lg={showSteps ? 12 : 5}
                            xl={showSteps ? 12 : 5}
                        />
                    </CrfCondition>
                </FieldGroup>
                <FieldGroup>
                    <HospitalServicesTable />
                </FieldGroup>
                <FieldGroup>
                    <OutpatientServicesTable />
                </FieldGroup>
                <FieldGroup>
                    <TestsTable />
                </FieldGroup>
                <FieldGroup>
                    <CommunityServicesTable />
                </FieldGroup>
                <FieldGroup>
                    <Typography
                        variant="h3"
                        color="secondary"
                        className={classes.subHeading}
                    >
                        TRAVEL
                    </Typography>
                    <Typography
                        className={classes.subHeading}
                    >
                        This section refers to how much in the <b>last 3 months</b> you spent on travel to attend hospital or other health and
                        social care appointments, including any unplanned visits.
                    </Typography>
                    <Field
                        name="travelDistanceKm"
                        component={Text}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Typography color="textSecondary">km&nbsp;</Typography>
                                </InputAdornment>
                            ),
                            type: "number",
                            inputProps: {min: 0}
                        }}
                        xs={12}
                        sm={12}
                        md={showSteps ? 12 : 6}
                        lg={showSteps ? 12 : 5}
                        xl={showSteps ? 12 : 5}
                    />
                    <Field
                        name="spentOnParking"
                        component={Text}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Typography color="textSecondary">$&nbsp;</Typography>
                                </InputAdornment>
                            ),
                            type: "number",
                            inputProps: {min: 0}
                        }}
                        xs={12}
                        sm={12}
                        md={showSteps ? 12 : 6}
                        lg={showSteps ? 12 : 5}
                        xl={showSteps ? 12 : 5}
                    />
                    <Field
                        name="spentOnTransportFares"
                        component={Text}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Typography color="textSecondary">$&nbsp;</Typography>
                                </InputAdornment>
                            ),
                            type: "number",
                            inputProps: {min: 0}
                        }}
                        xs={12}
                        sm={12}
                        md={showSteps ? 12 : 6}
                        lg={showSteps ? 12 : 5}
                        xl={showSteps ? 12 : 5}
                    />
                    <Field
                        name="spentOnAccomodation"
                        component={Text}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Typography color="textSecondary">$&nbsp;</Typography>
                                </InputAdornment>
                            ),
                            type: "number",
                            inputProps: {min: 0}
                        }}
                        xs={12}
                        sm={12}
                        md={showSteps ? 12 : 6}
                        lg={showSteps ? 12 : 5}
                        xl={showSteps ? 12 : 5}
                    />
                </FieldGroup>
                <FieldGroup>
                    <MedicationsTable />
                    {
                        isExtraSmall ?
                            <Field
                                name="payForPrescription"
                                component={ToggleButtonGroup}
                                orientation="vertical"
                                spacing={3}
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                            /> :
                            <Field
                                name="payForPrescription"
                                component={ToggleButtonGroup}
                                xs={12}
                                sm={12}
                                md={6}
                                lg={5}
                                xl={5}
                            />
                    }
                </FieldGroup>
                <FieldGroup>
                    <OtherExpensesTable />
                </FieldGroup>
                <FieldGroup>
                    <Typography
                        className={classes.subHeading}
                    >
                        Please use the space provided below to make any further comments on your study treatment or the study itself that you would
                        like to tell us about:
                    </Typography>
                    <Field
                        name="additionalComments"
                        component={TextArea}
                        rows={10}
                        rowsMax={20}
                        xs={12}
                        sm={12}
                        md={showSteps ? 12 : 6}
                        lg={showSteps ? 12 : 5}
                        xl={showSteps ? 12 : 5}
                        variant="outlined"
                    />
                </FieldGroup>
                {
                    !isPatient && (
                        <FieldGroup>
                            <Field
                                name="dateCompleted"
                                component={KeyboardDatePicker}
                                xs={12}
                                sm={12}
                                md={showSteps ? 12 : 6}
                                lg={showSteps ? 12 : 5}
                                xl={showSteps ? 12 : 5}
                            />
                        </FieldGroup>
                    )
                }
                {
                    canAdministerPro && (
                        <SourceDocuments />
                    )
                }
            </ProForm>
        </>
    );
}


/*
 * ----------------------------------------------------------------------------------
 * Default Export
 * ----------------------------------------------------------------------------------
 */

export default Additional;
