/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * This file contains the trial consent page component
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */

/**
 * Required to use React components.
 */
import * as React from 'react';

import {
    Theme,
    makeStyles,
    Typography,
    List,
    ListItem,
    Link,
    ListItemIcon,
    Grid,
    Button,
    FormGroup,
    FormControlLabel,
    Checkbox,
    TextField,
    ListItemText,
    RadioGroup,
    Radio,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,

    Select,
    MenuItem,
    FormControl,
    InputLabel
} from '@material-ui/core';
import AlertTitle from '@material-ui/lab/AlertTitle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/pro-duotone-svg-icons/faCircleCheck';
import { faExclamationCircle } from '@fortawesome/pro-duotone-svg-icons/faExclamationCircle';
import { faCheckCircle } from '@fortawesome/pro-duotone-svg-icons/faCheckCircle';
import { useSnackbar, OnlinePatientManagementContext, ProgressButton, RouteLoading } from '@ngt/opms';
import { useParams, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { getCountries, getCountryCallingCode } from 'react-phone-number-input/input';
import en from 'react-phone-number-input/locale/en.json';
import { DateTime } from 'luxon';

/*
 * ----------------------------------------------------------------------------------
 * Imports - Internal
 * ----------------------------------------------------------------------------------
 */
import * as Dtos from '../../api/dtos';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

interface ITrialConsentPageProps {
    
}

interface ITrialConsentPageParams {
    guid: string
}

interface IPatientConsentFormProps {
    isInvestigator: boolean,
    patientConsent: Dtos.PatientConsent,
    picfVersion: string,
    picfTissueBankingVersion: string,
    picfs: Dtos.Picf[],
    setConsentSubmitted: React.Dispatch<React.SetStateAction<boolean>>
}

interface IPatientConsentFieldProps {
    fieldGroupLabel?: string,
    label?: string,
    inputComponent: React.ReactNode
}

interface IPatientConsentVerificationDialogProps {
    open: boolean,
    patientConsent?: Dtos.PatientConsent,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>,
    setConsentSubmitted: React.Dispatch<React.SetStateAction<boolean>>
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles<Theme>(theme => ({
    container: {
        padding: theme.spacing(3, 0),
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(2),
        }
    },
    title: {
        color: theme.palette.secondary.main,
        fontSize: '2rem',
        fontWeight: 'bold',
        marginBottom: '1.25rem',
        textAlign: 'center'
    },
    subtitle: {
        color: theme.palette.primary.main,
        fontSize: '1.15rem',
        fontWeight: 'bold',
        marginTop: '2rem',
        marginBottom: '1rem',
    },
    p: {
        marginBottom: '1rem'
    },
    picfLink: {
        color: theme.palette.secondary.main,
        fontSize: '1.25rem',
        cursor: 'pointer',
    },
    link: {
        color: theme.palette.secondary.main,
    },
    list: {
        padding: theme.spacing(0)
    },
    listItem: {
        alignItems: 'flex-start'
    },
    listIcon: {
        color: theme.palette.secondary.main,
        minWidth: '30px',
        paddingTop: '8px',
        fontSize: '1rem'
    },
    formContainer: {
        display: "flex",
        justifyContent: "center",
    },
    formSection: {
        backgroundColor: "#ece7f1",
        margin: theme.spacing(3, 0),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(3),
    },
    formGrid: {
        '& .MuiGrid-item': {
            padding: theme.spacing(0, 2)
        },
        display: "flex",
        justifyContent: "center"
    },
    fieldGroupLabel: {
        fontSize: '1.050rem',
        fontWeight: 'bold',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1)
    },
    label: {
        fontSize: '1rem',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        justifyContent: 'center',
    },
    submitBtn: {
        borderRadius: 15,
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
            color: 'white'
        }
    },
    errorMessage: {
        color: 'red',
        padding: '0.15rem',
        paddingTop: '0.25rem',
        display: 'flex'
    },
    disableMessage: {
        color: theme.palette.secondary.main,
    },
    submitSuccess: {
        backgroundColor: "#ece7f1",
        color: theme.palette.primary.main,
        margin: theme.spacing(3, 0),
        padding: theme.spacing(5),
    },
    submitSuccessMessage: {
        fontSize: '2.75rem',
        fontWeight: 'bold',
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center'
        }
    },
    submitSuccessIcon: {
        color: theme.palette.secondary.main,
        fontSize: '5rem'
    },
    investigatorSection: {
        backgroundColor: "#ece7f1",
        margin: theme.spacing(3, 0),
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
    },
    dialogTitle: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText
    },
    dialogContent: {
        padding: theme.spacing(2, 3)
    },
    my1: {
        margin: theme.spacing(1,0)
    },
    tryAgainButton: {
        textTransform: 'none',
        padding: 0,
        fontSize: '0.725rem'
    }
}));

/*
 * ---------------------------------------------------------------------------------
 * Constants
 * ---------------------------------------------------------------------------------
 */


/*
 * ---------------------------------------------------------------------------------
 * Components
 * ---------------------------------------------------------------------------------
 */

const ErrorMessage: React.FunctionComponent<{message: React.ReactNode}> = ({ message }) => {
    const classes = useStyles();

    return (
        <div className={classes.errorMessage}>
            <FontAwesomeIcon icon={faExclamationCircle} style={{ marginTop: "0.15rem" }}/>
            <span style={{ marginLeft: "0.5rem" }}>{message}</span>
        </div>
    );
}

const PatientConsentVerificationDialog: React.FunctionComponent<IPatientConsentVerificationDialogProps> = ({
    open,
    patientConsent,
    setOpen,
    setConsentSubmitted
}) => {
    const classes = useStyles();

    const { enqueueSnackbar } = useSnackbar();

    const onlinePatientManagement = React.useContext(OnlinePatientManagementContext);

    const client = onlinePatientManagement.serviceStackClient;

    const [verificationCode, setVerificationCode] = React.useState<number | undefined>(undefined);

    const [loading, setLoading] = React.useState(false);

    const [internationalMobileNumber, setInternationalMobileNumer] = React.useState("");

    const [codeSent, setCodeSent] = React.useState(false);

    const [codeVerified, setCodeVerified] = React.useState(false);


    const handleMobileNumberSend = React.useCallback((mobileNumber) => {
        setLoading(true);

        //setLoading(false);
        //setCodeSent(true);
        //setVerificationCode(1234);

        //send code to mobile number

        client
            .post(new Dtos.PatientConsentSendVerificationCode({
                phoneNumber: mobileNumber
            }))
            .then(response => {
                enqueueSnackbar(
                    <>
                        <AlertTitle>
                            Verification Code Sent
                        </AlertTitle>
                        The verification code was sent successfully.
                    </>,
                    { variant: 'success' }
                );
                setLoading(false);
                setCodeSent(true);
                setVerificationCode(response.verificationCode);
                
            })
            .catch((e) => {
                enqueueSnackbar(
                    <>
                        <AlertTitle>
                            Verification Code Not Sent
                        </AlertTitle>
                        An error occurred while sending the verification code.
                    </>,
                    { variant: 'critical' }
                );
                setLoading(false);
                setOpen(false);
            })

    }, [client, enqueueSnackbar, setCodeSent, setLoading, setVerificationCode, setOpen]);

    const handleCodeVerify = React.useCallback((code) => {
        setLoading(true);

        //check if the code matches the verification sent to patient
        if (!code || +code !== verificationCode) {
            enqueueSnackbar(
                <>
                    <AlertTitle>
                        Code Not Valid
                    </AlertTitle>
                    The code must match the code sent to your mobile number.
                </>,
                { variant: 'critical' }
            );
        } else {
            //code verified
            enqueueSnackbar(
                <>
                    <AlertTitle>
                        Code Verified
                    </AlertTitle>
                    The code vas verified successfully.
                </>,
                { variant: 'success' }
            );
            setCodeVerified(true);
        }

        setLoading(false);
    }, [enqueueSnackbar, verificationCode, setCodeVerified, setLoading]);

    const handleConsentSubmit = React.useCallback((mobileNumber) => {
        setLoading(true);

        const updatedPatientConsent = new Dtos.PatientConsent(patientConsent);

        updatedPatientConsent.mobileNumber = mobileNumber?.replaceAll(" ", "");

        client
            .post(new Dtos.PatientConsentPostSave({
                patientConsent: updatedPatientConsent,
                emailRequired: true
            }))
            .then(response => {
                enqueueSnackbar(
                    <>
                        <AlertTitle>
                            Consent Submitted
                        </AlertTitle>
                        The consent was submitted successfully.
                    </>,
                    { variant: 'success' }
                );
                setLoading(false);
                setOpen(false);
                setConsentSubmitted(true);
            })
            .catch((e) => {
                enqueueSnackbar(
                    <>
                        <AlertTitle>
                            Consent Not Submitted
                        </AlertTitle>
                        An error occurred while trying to submit the consent. Please contact your system administrator.
                    </>,
                    { variant: 'critical' }
                );
                setLoading(false);
                setOpen(false);
            });
    }, [client, enqueueSnackbar, patientConsent, setConsentSubmitted, setOpen, setLoading]);

    const { register, handleSubmit, setValue, watch, trigger, formState: { errors, isSubmitted } } = useForm();

    const [country, setCountry] = React.useState<string | undefined | null>("");

    const countryOnChange = React.useCallback((event: any) => {
        setCountry(event.target.value);
    }, [setCountry]);

    const countries = React.useMemo(() => {
        return getCountries().filter(country => ["AU", "NZ"].find(x => x === country));
    }, []);

    React.useEffect(() => {
        if (patientConsent?.eoi?.invitation?.institutionCode) {
            client
                .get(new Dtos.InstitutionGetSingleByCode({
                    code: patientConsent?.eoi?.invitation?.institutionCode
                }))
                .then(response => {
                    if (response.institution.countryId === 1) {
                        setCountry("AU");
                    } else if(response.institution.countryId === 2) {
                        setCountry("NZ");
                    }
                })
                .catch((e) => {

                });
        }
    }, [patientConsent, setCountry, client]);

    const mobileNumber = watch("mobileNumber");

    const onMobileNumberChanged = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const numbersOnly = (event.target as HTMLInputElement).value.replace(/[^0-9]/g, '');
        setValue("mobileNumber", numbersOnly);
        trigger();
    }, [setValue, trigger]);

    const onTryAgainClicked = React.useCallback(() => {
        setInternationalMobileNumer("");
        setValue('mobileNumber', '');
        setValue('code', '');
        setCodeSent(false);
    }, [setInternationalMobileNumer, setValue, setCodeSent]);

    const onSubmit = React.useCallback((data) => {
        if (!codeSent) {
            if (!internationalMobileNumber) {
                const mobile: string = mobileNumber ?? "";

                setInternationalMobileNumer(mobile.replace('0', `+${getCountryCallingCode(country as any)}`));
            } else {
                handleMobileNumberSend(internationalMobileNumber);
            }
        } else if (codeSent && !codeVerified) {
            handleCodeVerify(data.code);
        } else if (codeVerified) {
            handleConsentSubmit(data.mobileNumber)
        }
    }, [codeSent, codeVerified, country, internationalMobileNumber, setInternationalMobileNumer, mobileNumber, handleCodeVerify, handleMobileNumberSend, handleConsentSubmit]);

    return (
        <>
            <Dialog open={open} onClose={() => setOpen(false)} aria-labelledby="dialog" maxWidth="xs" fullWidth={true}>
                <form onSubmit={handleSubmit(onSubmit)}>
                <DialogTitle
                    id="dialog-title"
                    className={classes.dialogTitle}
                >
                    {!codeVerified ? "Verify your identity" : "Verification Successful"}
                </DialogTitle>
                <DialogContent
                    className={classes.dialogContent}
                >
                    {
                        !codeSent && !internationalMobileNumber && <>
                            <Typography variant="h5" className={classes.my1}>
                                Please enter your mobile number
                            </Typography>
                            <div>
                                <FormControl
                                    className={classes.my1}
                                    fullWidth
                                >
                                    <InputLabel id="country-select-label">Country Code</InputLabel>
                                    <Select
                                        labelId="country-select-label"
                                        id="country-select"
                                        label="Country Code"
                                        value={country}
                                        onChange={countryOnChange}
                                        fullWidth
                                    >
                                        {
                                            countries.map((country) => (
                                                <MenuItem key={country} value={country}>
                                                    {en[country]} +{getCountryCallingCode(country)}
                                                </MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>

                                <FormControl
                                    className={classes.my1}
                                    fullWidth
                                >
                                    <TextField
                                        label="Mobile Number"
                                        fullWidth
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        {
                                            ...register(
                                                "mobileNumber",
                                                {
                                                    required: !codeSent,
                                                    pattern: /^0\d{5,13}$/i,
                                                    onChange: onMobileNumberChanged
                                                })
                                        }
                                    />
                                    </FormControl>

                                {errors.mobileNumber?.type === "required" && <ErrorMessage message={"This field is required"} />}
                                {errors.mobileNumber?.type === "pattern" && isSubmitted && <ErrorMessage message={"This field must be a valid mobile number"} />}
                            </div>
                        </>
                    }

                    {
                        !codeSent && internationalMobileNumber && <>
                            <Typography variant="h5" className={classes.my1}>
                                Send verification code to {internationalMobileNumber}?
                            </Typography>
                        </>
                    }

                    {
                        !!codeSent && !codeVerified && <>
                            <Typography variant="h5" className={classes.my1}>
                                Please enter the verification code
                            </Typography>
                            <small>
                                Did not receive it?
                                <Button
                                    size="small"
                                    onClick={onTryAgainClicked}
                                    className={classes.tryAgainButton}
                                >
                                    Try again
                                </Button>
                            </small>
                            <div style={{ marginTop: '1rem' }}>
                                <TextField
                                    variant={"standard"}
                                    label={"Verification Code"}
                                    fullWidth
                                        {...register("code", { required: codeSent, pattern: /^\d{4}$/i })}
                                />
                                {errors.code?.type === "required" && <ErrorMessage message={"This field is required"} />}
                                {errors.code?.type === "pattern" && <ErrorMessage message={"This field must have 4 digits"} />}
                            </div>
                        </>
                    }

                    {
                        !!codeVerified && <Typography variant="h5" className={classes.my1}>
                            Verification was successful. Select 'Submit' to confirm that you {patientConsent?.patientSigned ? 'agree' : 'do not agree'} to participate in the study. {patientConsent?.patientSigned}
                        </Typography>
                    }
                </DialogContent>
                    <DialogActions>
                    {
                        ((!codeSent && !internationalMobileNumber) || !!codeSent) && <ProgressButton
                            loading={loading}
                            color="secondary"
                            onClick={() => setOpen(false)}
                        >
                            Cancel
                        </ProgressButton>
                    }
                    {
                        (!codeSent && internationalMobileNumber) && <ProgressButton
                            loading={loading}
                            color="secondary"
                            onClick={() => { setInternationalMobileNumer("") }}
                        >
                            Back
                        </ProgressButton>
                    }
                    {
                        !codeSent && !internationalMobileNumber && <ProgressButton
                            type="submit"
                            loading={loading}
                            color="primary"
                            disabled={false}
                        >
                            Continue 
                        </ProgressButton>
                    }
                    {
                        !codeSent && internationalMobileNumber && <ProgressButton
                            type="submit"
                            loading={loading}
                            color="primary"
                            disabled={false}
                        >
                            Send 
                        </ProgressButton>
                    }
                    {
                        !!codeSent && !codeVerified && <ProgressButton
                            type={"submit"}
                            loading={loading}
                            color="primary"
                            disabled={false}
                        >
                            Verify
                        </ProgressButton>
                    }
                    {
                        codeVerified && <ProgressButton
                            type={"submit"}
                            loading={loading}
                            color="primary"
                            disabled={false}
                        >
                            Submit
                        </ProgressButton>
                    }
                </DialogActions>
                </form>
            </Dialog>
        </>
    );
}

const PatientConsentField: React.FunctionComponent<IPatientConsentFieldProps> = ({
    fieldGroupLabel,
    label,
    inputComponent
}) => {
        const classes = useStyles();

        return (
            <>
                {
                    !!fieldGroupLabel && (
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={11}
                            lg={11}
                            xl={11}
                        >
                            <Typography
                                variant="h2"
                                className={classes.fieldGroupLabel}
                            >
                                {fieldGroupLabel}
                            </Typography>
                        </Grid>    
                    )
                }
                {
                    !!label && (
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={3}
                            lg={2}
                            xl={2}
                            className={classes.label}
                        >
                            {label}
                        </Grid>    
                    )
                }
                <Grid
                    item
                    xs={12}
                    sm={12}
                    md={!!label ? 8 : 11}
                    lg={!!label ? 9 : 11}
                    xl={!!label ? 9 : 11}
                >
                    {inputComponent}
                </Grid>
            </>
        );
}

const PatientConsentForm: React.FunctionComponent<IPatientConsentFormProps> = ({
    isInvestigator,
    patientConsent,
    picfs,
    setConsentSubmitted
}) => {
    const classes = useStyles();

    const { enqueueSnackbar } = useSnackbar();

    const onlinePatientManagement = React.useContext(OnlinePatientManagementContext);

    const client = onlinePatientManagement.serviceStackClient;

    const [verificationDialogOpen, setVerificationDialogOpen] = React.useState(false);

    const [updatedPatientConsent, setUpdatedPatientConsent] = React.useState<Dtos.PatientConsent>();

    const convertStrToBoolean = React.useCallback((str?: string) => {
        switch (str) {
            case "1": return true;
            case "0": return false;
            default: return undefined;
        }
    }, []);

    const convertBooleanToStr = React.useCallback((bool?: boolean) => {
        switch (bool) {
            case true: return "1";
            case false: return "0";
            default: return null;
        }
    }, []);

    const defaultValues = React.useMemo(() => {
        if (isInvestigator) {
            return {
                patientSigned: convertBooleanToStr(patientConsent?.patientSigned),
                storageOfTissueSamples: convertBooleanToStr(patientConsent?.storageOfTissueSamples),
            }
        }

        return {
            patientSigned: null as any,
            storageOfTissueSamples: null as any
        }
    }, [convertBooleanToStr, isInvestigator, patientConsent])

    const { register, handleSubmit, watch, setValue, trigger, formState: { errors, isSubmitting } } = useForm<Dtos.PatientConsent>({
        defaultValues: defaultValues
    });

    const onPatientSignedChanged = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        if ((event.target as HTMLInputElement).value === '0') {
            setValue("storageOfTissueSamples", null as any);
        }

        if (Object.keys(errors).length > 0) {
            trigger();
        }
    }, [errors, setValue, trigger]);

    const patientSigned = watch('patientSigned');
    const storageOfTissueSamples = watch('storageOfTissueSamples');
    //i would like it if patientSigned didn't get set as a string, but I'm not sure it can be avoided with the way this react-hook-form thing and the radio input work
    const patientNotParticipating = (patientSigned as any) === '0';

    const onSubmit = React.useCallback((data) => {
        const updatedPatientConsent = new Dtos.PatientConsent(patientConsent);

        if (isInvestigator) {
            if (!patientConsent || patientConsent.statusId !== Dtos.StatusTypeEnum.InvestigatorSigning) {
                enqueueSnackbar(
                    <>
                        <AlertTitle>
                            Consent Not Submitted
                        </AlertTitle>
                        Consent does not have a valid status.
                    </>,
                    { variant: 'critical' }
                );

                return;
            }

            updatedPatientConsent.investigatorSigned = data.investigatorSigned;
            updatedPatientConsent.dateInvestigatorSigned = DateTime.local().toUTC().toString();
            updatedPatientConsent.statusId = Dtos.StatusTypeEnum.Completed;

            //not change the version which the participant has signed to
            //updatedPatientConsent.version = picfVersion;
            //updatedPatientConsent.optionalTissueBankingVersion = picfTissueBankingVersion;

            client
                .post(new Dtos.PatientConsentPostSave({
                    patientConsent: updatedPatientConsent,
                    emailRequired: true
                }))
                .then(response => {
                    enqueueSnackbar(
                        <>
                            <AlertTitle>
                                Consent Submitted
                            </AlertTitle>
                            The consent was submitted successfully.
                        </>,
                        { variant: 'success' }
                    );
                    setConsentSubmitted(true);
                })
                .catch((e) => {
                    enqueueSnackbar(
                        <>
                            <AlertTitle>
                                Consent Not Submitted
                            </AlertTitle>
                            An error occurred while trying to submit the consent. Please contact your system administrator.
                        </>,
                        { variant: 'critical' }
                    );
                })
        } else {
            if (!patientConsent || patientConsent.statusId !== Dtos.StatusTypeEnum.PatientSigning) {
                enqueueSnackbar(
                    <>
                        <AlertTitle>
                            Consent Not Submitted
                        </AlertTitle>
                        Consent does not have a valid status.
                    </>,
                    { variant: 'critical' }
                );

                return;
            }

            updatedPatientConsent.patientSigned = convertStrToBoolean(data.patientSigned);
            updatedPatientConsent.datePatientSigned = DateTime.local().toUTC().toString();
            updatedPatientConsent.dateNotificationSent = DateTime.local().toUTC().toString();
            updatedPatientConsent.storageOfTissueSamples = convertStrToBoolean(data.storageOfTissueSamples);
            updatedPatientConsent.statusId = updatedPatientConsent.patientSigned ? Dtos.StatusTypeEnum.InvestigatorSigning : Dtos.StatusTypeEnum.Refused;
            updatedPatientConsent.picfs = picfs.map(picf => {
                var consentPicf = new Dtos.ConsentPicf();

                consentPicf.picfTypeId = picf.type.id;
                consentPicf.picfId = picf.picfId;

                return consentPicf;
            });

            if (updatedPatientConsent.typeId === Dtos.ConsentTypeEnum.FaceToFace) {
                setUpdatedPatientConsent(updatedPatientConsent);
                setVerificationDialogOpen(true);
            } else {
                client
                    .post(new Dtos.PatientConsentPostSave({
                        patientConsent: updatedPatientConsent,
                        emailRequired: true
                    }))
                    .then(response => {
                        enqueueSnackbar(
                            <>
                                <AlertTitle>
                                    Consent Submitted
                                </AlertTitle>
                                The consent was submitted successfully.
                            </>,
                            { variant: 'success' }
                        );
                        setConsentSubmitted(true);
                    })
                    .catch((e) => {
                        enqueueSnackbar(
                            <>
                                <AlertTitle>
                                    Consent Not Submitted
                                </AlertTitle>
                                An error occurred while submitting the consent.
                            </>,
                            { variant: 'critical' }
                        );
                    })
            }
        }
    }, [client, convertStrToBoolean, enqueueSnackbar, isInvestigator, patientConsent, picfs, setConsentSubmitted, setUpdatedPatientConsent, setVerificationDialogOpen]);

    //set radiogroup value
    const patientSignedValue = React.useMemo(() => {
        return isInvestigator ? defaultValues?.patientSigned : (patientSigned ?? null);
    }, [defaultValues, isInvestigator, patientSigned]);

    const storeTissueAndBloodSamplesValue = React.useMemo(() => {
        return isInvestigator ? defaultValues?.storageOfTissueSamples : (storageOfTissueSamples ?? null);
    }, [defaultValues, isInvestigator, storageOfTissueSamples]);

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Typography
                    variant="h2"
                    className={classes.subtitle}
                >
                    Main Study Consent
                </Typography>
                <List className={classes.list}>
                    <ListItem className={classes.listItem}>
                        <ListItemIcon className={classes.listIcon}>
                            <FontAwesomeIcon icon={faCircleCheck} />
                        </ListItemIcon>
                        <ListItemText>
                            I have read the attached Participant Information Sheet or someone has read it to me in a language that I understand.
                        </ListItemText>
                    </ListItem>
                    <ListItem className={classes.listItem}>
                        <ListItemIcon className={classes.listIcon}>
                            <FontAwesomeIcon icon={faCircleCheck} />
                        </ListItemIcon>
                        <ListItemText>
                            I understand the nature and purpose of the research study and I understand what I am being asked to do.
                        </ListItemText>
                    </ListItem>
                    <ListItem className={classes.listItem}>
                        <ListItemIcon className={classes.listIcon}>
                            <FontAwesomeIcon icon={faCircleCheck} />
                        </ListItemIcon>
                        <ListItemText>
                            I have discussed my participation in this study with the member of the study team named below.
                            I have had the opportunity to ask questions and I am satisfied with the answers I have received.
                        </ListItemText>
                    </ListItem>
                    <ListItem className={classes.listItem}>
                        <ListItemIcon className={classes.listIcon}>
                            <FontAwesomeIcon icon={faCircleCheck} />
                        </ListItemIcon>
                        <ListItemText>
                            I have been informed about the possible risks of taking part in this study.
                        </ListItemText>
                    </ListItem>
                    <ListItem className={classes.listItem}>
                        <ListItemIcon className={classes.listIcon}>
                            <FontAwesomeIcon icon={faCircleCheck} />
                        </ListItemIcon>
                        <ListItemText>
                            I consent to my doctors, other health professionals, hospitals or laboratories outside this institution releasing personal and health information
                            concerning my condition and treatment which is needed for this study and understand that such information will remain confidential.
                            I understand that my usual doctor (GP) will be informed of my participation in this study.
                        </ListItemText>
                    </ListItem>
                    <ListItem className={classes.listItem}>
                        <ListItemIcon className={classes.listIcon}>
                            <FontAwesomeIcon icon={faCircleCheck} />
                        </ListItemIcon>
                        <ListItemText>
                            I freely consent to participate in the research study as described in the attached Participant Information Sheet.
                        </ListItemText>
                    </ListItem>
                    <ListItem className={classes.listItem}>
                        <ListItemIcon className={classes.listIcon}>
                            <FontAwesomeIcon icon={faCircleCheck} />
                        </ListItemIcon>
                        <ListItemText>
                            I understand that my participation is voluntary and that I am free to withdraw at any time during the study without affecting
                            my future health care.
                        </ListItemText>
                    </ListItem>
                    <ListItem className={classes.listItem}>
                        <ListItemIcon className={classes.listIcon}>
                            <FontAwesomeIcon icon={faCircleCheck} />
                        </ListItemIcon>
                        <ListItemText>
                            I understand that if I decide to discontinue the study, I may be asked to attend follow-up visits to allow collection
                            of personal and health information regarding my health status. Alternatively, the investigator/sponsor will request my permission to access my
                            medical records for collection of follow-up personal and health information for research and analysis.
                        </ListItemText>
                    </ListItem>
                    <ListItem className={classes.listItem}>
                        <ListItemIcon className={classes.listIcon}>
                            <FontAwesomeIcon icon={faCircleCheck} />
                        </ListItemIcon>
                        <ListItemText>
                            I consent to the use and storage of tissue samples taken from me for use as described in the relevant section of the Participant Information Sheet.
                        </ListItemText>
                    </ListItem>
                    <ListItem className={classes.listItem}>
                        <ListItemIcon className={classes.listIcon}>
                            <FontAwesomeIcon icon={faCircleCheck} />
                        </ListItemIcon>
                        <ListItemText>
                            I understand that BCT may in future request specific details about me from this institution (such as name, gender,
                            date of birth, address) for use in data linkage. I understand that requests for data linkage will only occur after specific
                            approvals are obtained, BCT will only give my identifiable information to the data custodian(s) for data linkage, and the data custodian(s)
                            will de-identify my information before giving it to researchers.
                        </ListItemText>
                    </ListItem>
                    <ListItem className={classes.listItem}>
                        <ListItemIcon className={classes.listIcon}>
                            <FontAwesomeIcon icon={faCircleCheck} />
                        </ListItemIcon>
                        <ListItemText>
                            I understand that I will be given a signed copy of this document to keep.
                        </ListItemText>
                    </ListItem>
                </List>
                <div className={classes.formSection}>
                    <Grid
                        container
                        spacing={2}
                        className={classes.formGrid}
                    >
                        <PatientConsentField
                            fieldGroupLabel={"Declaration by Participant"}
                            inputComponent={
                                <>
                                    <RadioGroup value={patientSignedValue}>
                                        <FormControlLabel
                                            label={<><strong>I AGREE</strong> to participate in the clinical trial</>}
                                            value="1"
                                            control={
                                                <Radio
                                                    disabled={isInvestigator}
                                                    {...register("patientSigned", { required: !isInvestigator, onChange: onPatientSignedChanged })}
                                                />
                                            }
                                        />
                                        <FormControlLabel
                                            label={<><strong>I DO NOT agree</strong> to participate in the clinical trial</>}
                                            value="0"
                                            control={
                                                <Radio
                                                    disabled={isInvestigator}
                                                    {...register("patientSigned", { required: !isInvestigator, onChange: onPatientSignedChanged })}
                                                />
                                            }
                                        />
                                        {errors.patientSigned && <ErrorMessage message={"This field is required"} />}
                                        {patientNotParticipating && <span className={classes.disableMessage}>YOU HAVE CHOSEN NOT TO PARTICIPATE IN THE CLINICAL TRIAL</span>}
                                    </RadioGroup>
                                </>
                            }
                        />
                    </Grid>
                </div>

                <Typography
                    variant="h2"
                    className={classes.subtitle}
                >
                    Optional Tissue Banking and Future Unspecified Research (FUR) Consent
                </Typography>
                <List className={classes.list}>
                    <ListItem className={classes.listItem}>
                        <ListItemIcon className={classes.listIcon}>
                            <FontAwesomeIcon icon={faCircleCheck} />
                        </ListItemIcon>
                        <ListItemText>
                            I have read the attached Participant Information Sheet or someone has read it to me in a language that I understand.
                        </ListItemText>
                    </ListItem>
                    <ListItem className={classes.listItem}>
                        <ListItemIcon className={classes.listIcon}>
                            <FontAwesomeIcon icon={faCircleCheck} />
                        </ListItemIcon>
                        <ListItemText>
                            I understand the purposes, procedures and risks of tissue banking for future unspecified research.
                        </ListItemText>
                    </ListItem>
                    <ListItem className={classes.listItem}>
                        <ListItemIcon className={classes.listIcon}>
                            <FontAwesomeIcon icon={faCircleCheck} />
                        </ListItemIcon>
                        <ListItemText>
                            I have discussed my participation in this optional component of the OPTIMA study with the member of the study team named below.
                            I have had the opportunity to ask questions and I am satisfied with the answers I have received.
                        </ListItemText>
                    </ListItem>
                    <ListItem className={classes.listItem}>
                        <ListItemIcon className={classes.listIcon}>
                            <FontAwesomeIcon icon={faCircleCheck} />
                        </ListItemIcon>
                        <ListItemText>
                            I have been informed about the possible risks of taking part in optional tissue banking.
                        </ListItemText>
                    </ListItem>
                    <ListItem className={classes.listItem}>
                        <ListItemIcon className={classes.listIcon}>
                            <FontAwesomeIcon icon={faCircleCheck} />
                        </ListItemIcon>
                        <ListItemText>
                            I completed the selection options below regarding participation in this optional component of the OPTIMA study, as described in the attached Participant Information Sheet.
                        </ListItemText>
                    </ListItem>
                    <ListItem className={classes.listItem}>
                        <ListItemIcon className={classes.listIcon}>
                            <FontAwesomeIcon icon={faCircleCheck} />
                        </ListItemIcon>
                        <ListItemText>
                            I understand that my participation is voluntary and that I am free to withdraw at any time during the study without affecting my future health care.
                        </ListItemText>
                    </ListItem>
                </List>
                <div className={classes.formSection}>
                    <Grid
                        container
                        spacing={2}
                        className={classes.formGrid}
                    >
                        <PatientConsentField
                            fieldGroupLabel={"Please select one of the following regarding the storage and use of all of your tissue samples collected as part of OPTIMA for future unspecified research:"}
                            inputComponent={
                                <>
                                    <RadioGroup value={storeTissueAndBloodSamplesValue}>
                                        <FormControlLabel
                                            label={<><strong>I AGREE</strong> to the storage of my tissue samples collected as part of OPTIMA for future unspecified research</>}
                                            value="1"
                                            control={
                                                <Radio
                                                    disabled={patientNotParticipating || isInvestigator}
                                                    {...register("storageOfTissueSamples", { required: !patientNotParticipating && !isInvestigator})}
                                                />
                                            }
                                        />
                                        <FormControlLabel
                                            label={<><strong>I DO NOT agree</strong> to the storage of my tissue samples collected as part of OPTIMA for future unspecified research</>}
                                            value="0"
                                            control={
                                                <Radio
                                                    disabled={patientNotParticipating || isInvestigator}
                                                    {...register("storageOfTissueSamples", { required: !patientNotParticipating && !isInvestigator})}
                                                />
                                            }
                                        />
                                        {errors.storageOfTissueSamples && <ErrorMessage message={"This field is required"} />}
                                    </RadioGroup>
                                </>
                            }
                        />
                    </Grid>
                </div>

                {
                    !!isInvestigator && (
                        <>
                            <Typography
                                variant="h2"
                                className={classes.subtitle}
                            >
                                        Declaration by Study Doctor/Senior Researcher
                            </Typography>
                            <div className={classes.investigatorSection}>
                                <Grid
                                    container
                                    spacing={2}
                                    className={classes.formGrid}
                                >
                                    <PatientConsentField
                                        inputComponent={
                                            <>
                                                <FormGroup>
                                                    <div>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    {...register("investigatorSigned", { required: !!isInvestigator })}
                                                                />
                                                            }
                                                            label="I have given a verbal explanation of the study, its procedures and risks and I believe that the participant has understood that explanation."
                                                        />
                                                        {errors.investigatorSigned && <ErrorMessage message={"This field is required"} />}
                                                    </div>
                                                </FormGroup>
                                            </>
                                        }
                                    />
                                </Grid>
                            </div>
                        </>
                    )
                }

                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '1.5rem'}}>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        className={classes.submitBtn}
                        disabled={isSubmitting}
                    >
                            Submit
                    </Button>
                </div>
            </form>

            {
                verificationDialogOpen && <PatientConsentVerificationDialog
                    open={verificationDialogOpen}
                    patientConsent={updatedPatientConsent}
                    setOpen={setVerificationDialogOpen}
                    setConsentSubmitted={setConsentSubmitted}
                />
            }
        </>
    );
}

const TrialConsentPage: React.FunctionComponent<ITrialConsentPageProps> = () => {
    const classes = useStyles();

    const history = useHistory();

    const onlinePatientManagement = React.useContext(OnlinePatientManagementContext);

    const client = onlinePatientManagement.serviceStackClient;

    const isInvestigator = React.useMemo(() => {
        if (history?.location?.pathname?.startsWith("/public/trial-consent-signed/")) {
            return true;
        }

        return false;
    }, [history.location.pathname]);

    const params = useParams<ITrialConsentPageParams>();

    const consentGuid = params?.guid;

    const [consentSubmitted, setConsentSubmitted] = React.useState(false);

    const [picfVersion] = React.useState<string>("");

    const [picfTissueBankingVersion] = React.useState<string>("");

    const [patientConsent, setPatientConsent] = React.useState<Dtos.PatientConsent | undefined>(undefined);

    const { enqueueSnackbar } = useSnackbar();

    const [picfs, setPicfs] = React.useState<Dtos.Picf[] | undefined>();

    //load the patient consent using the guid in the url
    React.useEffect(() => {
        client
            .get(new Dtos.PatientConsentGetSingleByGuid({
                guid: consentGuid
            }))
            .then(response => {
                setPatientConsent(response.patientConsent);
            })
            .catch((e) => {

            });
    }, [client, consentGuid, setPatientConsent]);

    React.useEffect(() => {
        if (patientConsent?.eoi?.invitation?.institutionCode) {
            client
                .get(new Dtos.GetPicfs({
                    instCode: patientConsent?.eoi?.invitation?.institutionCode,
                    patientConsentId: patientConsent.statusId === Dtos.StatusTypeEnum.PatientSigning ? undefined : patientConsent.id
                }))
                .then(response => {
                    setPicfs(response.picfs)
                })
                .catch((e) => {
                    enqueueSnackbar(
                        <>
                            <AlertTitle>
                                Error
                            </AlertTitle>
                            There is a problem with the site PICFs. Please contact your system administrator.
                        </>,
                        { variant: 'critical' }
                    );
                });
        }
    }, [client, enqueueSnackbar, patientConsent, setPicfs]);

    if (!patientConsent) {
        return (
            <RouteLoading />
        )
    }

    return (
        <>
            <div className={classes.container}>
                <Typography
                    variant="h1"
                    className={classes.title}
                >
                    <strong>Participant Information and Consent Form</strong>
                </Typography>
                <Typography
                    variant="body1"
                    variantMapping={{
                        body1: 'p'
                    }}
                    className={classes.p}
                >
                    You are invited to take part in the OPTIMA study because you have breast cancer that is hormone sensitive (oestrogen (ER) positive) and HER2 negative, and:
                    <ul>
                        <li>Has spread to the lymph nodes, OR</li>
                        <li>Is a larger size cancer without spread to the lymph nodes within or surrounding the breast.</li>
                    </ul>
                </Typography>
                <Typography
                    variant="body1"
                    variantMapping={{
                        body1: 'p'
                    }}
                    className={classes.p}
                >
                    Before you agree to take part in this study, you need to know the risks and benefits so you can make an informed decision. The Participant Information and Consent Form tells you about the research study. It explains the tests and treatments involved. Knowing what is involved will help you decide if you want to take part in the study. Please read the documents carefully.
                </Typography>
                <Typography
                    variant="body1"
                    variantMapping={{
                        body1: 'p'
                    }}
                    className={classes.p}
                >
                    Please ask questions about anything that you don’t understand or want to know more about. Before deciding whether or not to take part in this study, you might want to talk about it with a relative, friend or your usual doctor (GP).
                </Typography>
                <Typography
                    variant="body1"
                    variantMapping={{
                        body1: 'p'
                    }}
                    className={classes.p}
                >
                    If you don’t wish to take part in this study, you don’t have to. You will receive the best possible care whether or not you take part. If you decide to take part, you can stop at any time if you change your mind later.
                </Typography>
                <Typography
                    variant="body1"
                    variantMapping={{
                        body1: 'p'
                    }}
                    className={classes.p}
                >
                    If you want to take part in this study, you will need to complete the Consent Form at the end of this page. Completing it tells us that you:
                    <ul>
                        <li>Understand what you have read</li>
                        <li>Consent (agree) to take part in the research study</li>
                        <li>Consent (agree) to the use of your personal and health information as described.</li>
                    </ul>
                </Typography>
                <Typography
                    variant="body1"
                    variantMapping={{
                        body1: 'p'
                    }}
                    className={classes.p}
                >
                    Please click the following links to view the full Participant Information Sheet and Consent Form.
                </Typography>

                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <List className={classes.list}>
                        {
                            picfs?.map((picf, index) => {
                                return (
                                    <ListItem key={index} className={classes.listItem} style={{ paddingTop: 0, paddingBottom: 0, textAlign: 'center', width: '100%' }}>
                                        <ListItemText>
                                            <Button
                                                href={`/opms/picf/download/${patientConsent?.eoi?.invitation.institutionCode}/${picf.picfId}/${picf.type.id}`}
                                                target="_blank"
                                                rel="noopener"
                                                //className={classes.link}
                                                variant="contained"
                                                color="secondary"
                                                fullWidth
                                            >
                                                {picf.type.label} ({picf.version})
                                            </Button>
                                        </ListItemText>
                                    </ListItem>
                                );
                            })
                        }
                    </List>
                </div>
                    
                {
                    !consentSubmitted && (
                        <PatientConsentForm
                            isInvestigator={isInvestigator}
                            patientConsent={patientConsent}
                            picfVersion={picfVersion}
                            picfTissueBankingVersion={picfTissueBankingVersion}
                            picfs={picfs ?? []}
                            setConsentSubmitted={setConsentSubmitted}
                        />
                    ) 
                }

                {
                    !!consentSubmitted && (
                        <div className={classes.submitSuccess}>
                            <Grid
                                container
                                spacing={2}
                            >
                                <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={2}
                                    lg={1}
                                    xl={1}
                                    style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
                                >
                                    <FontAwesomeIcon icon={faCheckCircle} className={classes.submitSuccessIcon} />
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={10}
                                    lg={11}
                                    xl={11}
                                >
                                    <div className={classes.submitSuccessMessage}>
                                        <span>Thank you for completing <br /> the consent form</span>
                                    </div>
                                    {
                                        (!!isInvestigator || patientConsent?.typeId === Dtos.ConsentTypeEnum.FaceToFace) && (
                                            <span>
                                                Check and manage the consents <Link href={"/econsent"} className={classes.link}>here</Link>.
                                            </span>
                                        )
                                    }
                                </Grid>
                            </Grid>
                        </div>
                    )
                }
            </div>
        </>  
    );
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */
export default TrialConsentPage;