/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * This file contains the invitation page component
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */

/**
 * Required to use React components.
 */
import * as React from 'react';

import {
    makeStyles,
    Typography,
    Container,
    AppBar,
    Toolbar,
    Paper,
    Grid
} from '@material-ui/core';

import { useIsMobile, OnlinePatientManagementContext } from '@ngt/opms';
import * as classNames from 'classnames';

/*
 * ----------------------------------------------------------------------------------
 * Imports - Internal
 * ----------------------------------------------------------------------------------
 */


/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

interface IHeaderProps {
    title?: string;
    organisationName?: string;
    organisationLogo?: string | null;
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useMobileStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    title: {
        flexGrow: 1,
    },
    appBar: {
        backgroundColor: "#f3c4d8"
    }
}));

const useDesktopStyles = makeStyles(theme => ({
    container: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3)
    },
    logo: {
        maxWidth: '100%',
        height: 'auto'
    },
    rightColumn: {
        textAlign: 'right',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        justifyContent: 'flex-end'
    },
    title: {
        color: theme.palette.secondary.main,
        fontWeight: 'bold',
        fontSize: '2.5rem'
    },
    border: {
        //borderBottomColor: theme.palette.primary.light,
        borderBottomColor: "#f3c4d8",
        borderBottomWidth: theme.spacing(1),
        borderBottomStyle: 'solid'
    }
}));


/*
 * ---------------------------------------------------------------------------------
 * Constants
 * ---------------------------------------------------------------------------------
 */


/*
 * ---------------------------------------------------------------------------------
 * Components
 * ---------------------------------------------------------------------------------
 */

const MobileHeader: React.FunctionComponent<IHeaderProps> = ({
    organisationLogo,
    organisationName,
    title,
    children
}) => {
    const classes = useMobileStyles();

    return (
        <AppBar position="static" className={classes.appBar}>
            <Toolbar>
                <Typography
                    variant="h6"
                    className={classes.title}
                    variantMapping={{
                        h6: 'span'
                    }}
                >
                    {title}
                </Typography>
            </Toolbar>
        </AppBar>
    );
}

const DesktopHeader: React.FunctionComponent<IHeaderProps> = ({
    organisationLogo,
    organisationName,
    title,
    children
}) => {
    const classes = useDesktopStyles();

    return (
        <AppBar
            position="static"
        >
            <Paper
                elevation={0}
                square
                className={classNames({ [classes.border]: true })}
            >
                <Container
                    className={classes.container}
                >
                    <Grid
                        container
                        spacing={3}
                    >
                        <Grid
                            item
                            xs={12}
                            md={4}
                        >
                            {
                                !!organisationLogo ?
                                    <img
                                        className={classes.logo}
                                        src={organisationLogo}
                                        alt={organisationName}
                                    /> :
                                    <Typography
                                        variant="h1"
                                        className={classes.title}
                                        variantMapping={{
                                            h1: 'span'
                                        }}
                                    >
                                        {organisationName}
                                    </Typography>
                            }
                        </Grid>
                        <Grid
                            className={classes.rightColumn}
                            item
                            xs={12}
                            md={8}
                        >
                            <Typography
                                variant="h1"
                                className={classes.title}
                                variantMapping={{
                                    h1: 'span'
                                }}
                            >
                                {title}
                            </Typography>
                        </Grid>
                    </Grid>
                </Container>
            </Paper>
        </AppBar>
    );
}

const Header: React.FunctionComponent<IHeaderProps> = ({
    title
}) => {
    const { trialName, organisationName, organisationLogo } = React.useContext(OnlinePatientManagementContext)

    const isMobile = useIsMobile();

    if (isMobile) {
        return (
            <MobileHeader
                organisationLogo={organisationLogo}
                organisationName={organisationName}
                title={title ?? trialName}
            />
        );
    }

    return (
        <DesktopHeader
            organisationLogo={organisationLogo}
            organisationName={organisationName}
            title={title ?? trialName}
        />
    );
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */
export default Header;