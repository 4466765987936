/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * This file contains the component that provides context for the online patient
 * management system.
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */

/**
 * Required to use React components.
 */
import * as React from 'react';


/**
 * Used for the basic page layout.
 */
import {
    Text,
    Select,
    FormGrid,
    Input,
    IFormGridCell,
    IFileUpload,
    FieldGroup,
    PatientContext,
    FormDefinitionContext,
    EventDefinitionContext,
} from '@ngt/opms';

/*
 * ----------------------------------------------------------------------------------
 * Imports - Internal
 * ----------------------------------------------------------------------------------
 */

/*
 * Used to type patient state.
 */
import * as Dtos from '../api/dtos';
import { makeStyles, Typography } from '@material-ui/core';
import SourceDocumentFileUpload from './SourceDocumentFileUpload';

/*
 * ----------------------------------------------------------------------------------
 * Interface
 * ----------------------------------------------------------------------------------
 */

interface ISourceDocumentsProps {
    instructions?: string;
    disabled?: boolean;
}


/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles(theme => ({
    subHeading: {
        padding: theme.spacing(3, 3, 1, 2)
    },
    instructions: {
        padding: theme.spacing(0, 0, 2, 2)
    }
}));

/*
 * ---------------------------------------------------------------------------------
 * constant
 * ---------------------------------------------------------------------------------
 */
const existingLink = (value?: IFileUpload) => {
    const file = value as Dtos.File;
    if (file != null)
    {
        if (file.extension == "pdf" || file.extension == ".pdf")
        {
            return `/document/${file?.id}`;
        }

    }
    return `/opms/file/view/${file?.id}`;
}

const existingDownloadLink = (value?: IFileUpload) => {
    const file = value as Dtos.File;

    return `/opms/file/download/${file?.id}`;
}

const columns: Array<IFormGridCell<Dtos.SourceDocument>> = [
    {
        name: 'name',
        content: (
            <Input component={Text} />
        )
    },
    {
        name: 'fileTypeId',
        content: (
            <Input component={Select} />
        )
    },
    {
        name: 'file',
        content: (
            <Input
                component={SourceDocumentFileUpload}
                existingLink={existingLink}
                existingDownloadLink={existingDownloadLink}
            />
        )
    }
]

/*
 * ----------------------------------------------------------------------------------
 * Components
 * ----------------------------------------------------------------------------------
 */

const SourceDocuments: React.FunctionComponent<ISourceDocumentsProps> = ({
    instructions,
    disabled
}) => {
    const classes = useStyles();

    const { patient } = React.useContext(PatientContext);
    const { formDefinition } = React.useContext(FormDefinitionContext);
    const { eventDefinition } = React.useContext(EventDefinitionContext);

    const gridDisabled = disabled ?? false;
    
    const columns: Array<IFormGridCell<Dtos.SourceDocument>> = React.useMemo(() => {
        const redactable: boolean = eventDefinition?.formDefinitions.find(edfd => edfd.formDefinitionId === formDefinition?.id)?.states.find(s => s.patientStateId === patient?.patientStateId)?.redactable ?? true;

        return [
            {
                name: 'name',
                content: (
                    <Input component={Text} disabled={gridDisabled} />
                )
            },
            {
                name: 'fileTypeId',
                content: (
                    <Input component={Select} disabled={gridDisabled} />
                )
            },
            {
                name: 'file',
                content: (
                    <Input
                        component={SourceDocumentFileUpload}
                        existingLink={existingLink}
                        existingDownloadLink={existingDownloadLink}
                        redactable={redactable}
                        targetUrl={(window as any).REDACT_URL}
                        disabled={gridDisabled}
                    />
                )
            }
        ]
    }, [patient, formDefinition, eventDefinition]);


    return (
        <>
            <FieldGroup>
                <Typography
                    variant="h2"
                    color="primary"
                    className={classes.subHeading}
                >
                    Source Documents
                </Typography>
                {
                    instructions && <Typography
                        className={classes.instructions}
                    >
                        {instructions}
                    </Typography>
                }
                <FormGrid
                    name="sourceDocuments"
                    columns={columns}
                    type={Dtos.SourceDocument}
                />
            </FieldGroup>
        </>
    );
}


/*
 * ----------------------------------------------------------------------------------
 * Default Export
 * ----------------------------------------------------------------------------------
 */

export default SourceDocuments;
